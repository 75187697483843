import PropTypes from "prop-types";
import React from "react";
import { Error, ErrorText } from "./styles/InputError.styles";

const InputError = ({ children, ...props }) => (
  <Error active={!!children} {...props}>
    <ErrorText>
      {children}
    </ErrorText>
  </Error>
);

InputError.propTypes = {
  children: PropTypes.any,
};

InputError.defaultProps = {
  children: undefined,
};

export default InputError;
