import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import throttle from "lodash/throttle";
import { checkElementsInViewport } from "react-lazy";

import { width, fullWidth, widthWithBorder } from "../helper/domHelper";
import { isDesktop } from "../layout/MediaQueries";
import ArrowLeftIcon from "../icons/ArrowLeftIcon";
import ArrowRightIcon from "../icons/ArrowRightIcon";

import styles from "./styles/Slider.sass";

const cx = classNames.bind(styles);

class Slider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAtStart: true,
      isAtEnd: true,
      showControls: false,
    };

    this.toggleControls = throttle(this.toggleControls.bind(this), 100);
    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
  }

  componentDidMount() {
    if (typeof window !== "undefined" && window !== null) {
      window.addEventListener("resize", this.toggleControls);
    }
    this.contentElement.addEventListener("scroll", this.toggleControls, { passive: true });
    this.toggleControls();
    if (document.fonts !== undefined) document.fonts.ready.then(() => this.toggleControls());
  }

  componentDidUpdate() {
    let event;
    if (typeof (Event) === "function") {
      event = new Event("scroll");
    } else {
      event = document.createEvent("Event");
      event.initEvent("scroll", false, false);
    }

    this.contentElement.dispatchEvent(event);
  }

  toggleControls(event) {
    if (event) event.stopPropagation();

    const contentFullWidth = Math.floor(fullWidth(this.contentElement));
    const contentVisibleWidth = Math.floor(width(this.contentElement));

    // remove controls if content is smaller than wrapper
    const showControls = contentFullWidth > contentVisibleWidth;

    // disable prev and back controls if end of list is reached
    const currentScroll = this.contentElement.scrollLeft;
    const isAtStart = currentScroll <= 0;
    const isAtEnd = currentScroll + contentVisibleWidth >= contentFullWidth;

    this.setState({ isAtStart, isAtEnd, showControls });
  }

  prevSlide() {
    const contentVisibleWidth = width(this.contentElement);
    const children = [].slice.call(this.contentElement.children);
    const firstChild = children[0];
    const firstChildLeftWithBorder = firstChild.offsetLeft - this.contentElement.scrollLeft;
    const firstChildRightWithBorder = firstChildLeftWithBorder + widthWithBorder(firstChild);
    const offset = isDesktop() && !this.props.fullwidth ? 0 : 12;
    let newScrollStep = firstChild.offsetLeft - offset - this.contentElement.scrollLeft;

    // get elements that touch the left edge
    const edgeElementsRights = [];
    let closestEdgeElementRight = firstChildRightWithBorder;
    children.some((child) => {
      const leftWithBorder = child.offsetLeft - this.contentElement.scrollLeft;
      const rightWithBorder = leftWithBorder + widthWithBorder(child);

      if (leftWithBorder < 0) {
        if (rightWithBorder > 0 && rightWithBorder < contentVisibleWidth) {
          edgeElementsRights.push(rightWithBorder);
        } else if (rightWithBorder > closestEdgeElementRight) {
          closestEdgeElementRight = rightWithBorder;
        }
        return false;
      }
      return true;
    });

    // set new scroll step to contentVisibleWidth without the right part of the rightest reaching edge element
    // this aligns the elements to the right egde
    edgeElementsRights.concat([closestEdgeElementRight]).forEach((rightWithBorder) => {
      const scrollStep = -contentVisibleWidth + rightWithBorder;
      if (scrollStep < 0 && scrollStep > newScrollStep) {
        newScrollStep = scrollStep;
      }
    });

    // get elements that would be visible after scrolling
    const visibleElementsLefts = [];
    children.some((child) => {
      const leftWithBorder = child.offsetLeft - this.contentElement.scrollLeft;
      const rightWithBorder = leftWithBorder + widthWithBorder(child);

      if (leftWithBorder <= newScrollStep + contentVisibleWidth) {
        if (rightWithBorder >= newScrollStep) {
          visibleElementsLefts.push(leftWithBorder);
        }
        return false;
      }
      return true;
    });

    let oldScrollStep = 0;

    // prevent 0 scrolling
    if (visibleElementsLefts.length) {
      oldScrollStep = newScrollStep;
      newScrollStep = 0;
    }

    // set new scroll step to the left edge of the leftest reaching completely visible element
    visibleElementsLefts.forEach((leftWithBorder) => {
      if (leftWithBorder >= oldScrollStep && leftWithBorder < newScrollStep) {
        newScrollStep = leftWithBorder;
      }
    });

    this.scroll(this.props.fullwidth ? newScrollStep - offset : newScrollStep);
  }

  nextSlide() {
    const contentVisibleWidth = width(this.contentElement);
    const children = [].slice.call(this.contentElement.children);
    const lastChild = children.slice(-1)[0];
    const lastChildLeftWithBorder = lastChild.offsetLeft - this.contentElement.scrollLeft;
    const lastChildRightWithBorder = lastChildLeftWithBorder + widthWithBorder(lastChild);
    const offset = isDesktop() && !this.props.fullwidth ? 0 : 12;
    let newScrollStep = (lastChild.offsetLeft + widthWithBorder(lastChild) + offset) - contentVisibleWidth - this.contentElement.scrollLeft;

    // get elements that touch the right edge
    const edgeElements = [];
    let closestEdgeElement = {
      leftWithBorder: lastChildLeftWithBorder,
      rightWithBorder: lastChildRightWithBorder,
    };
    children.reverse().some((child) => {
      const leftWithBorder = child.offsetLeft - this.contentElement.scrollLeft;
      const rightWithBorder = leftWithBorder + widthWithBorder(child);

      if (rightWithBorder > contentVisibleWidth) {
        if (leftWithBorder < contentVisibleWidth && leftWithBorder > 0) {
          edgeElements.push({ leftWithBorder, rightWithBorder });
        } else if (leftWithBorder < closestEdgeElement.leftWithBorder) {
          closestEdgeElement = { leftWithBorder, rightWithBorder };
        }
        return false;
      }
      return true;
    });

    edgeElements.concat([closestEdgeElement]).forEach((edgeElement) => {
      if (edgeElement.leftWithBorder < newScrollStep) {
        if (edgeElement.rightWithBorder > contentVisibleWidth) {
          newScrollStep = edgeElement.leftWithBorder - offset;
        } else {
          newScrollStep = edgeElement.rightWithBorder - offset;
        }
      }
    });

    this.scroll(newScrollStep);
  }

  scroll(scrollStep) {
    const fromValue = this.contentElement.scrollLeft;
    let startTime = (new Date()).getTime();
    let diffTime;
    let animationId;

    const easeOutQuad = (t, b, c, d) => {
      const timing = t / d;
      return (-c * timing * (timing - 2)) + b;
    };

    const setCurrentScrollPosition = (scrollPosition) => {
      this.contentElement.scrollLeft = scrollPosition;
    };

    const scrollPanel = () => {
      const pos = easeOutQuad(diffTime, fromValue, scrollStep, this.props.duration);
      setCurrentScrollPosition(pos);
    };

    const stopScrollAnimation = () => {
      if (typeof animationId !== "undefined" && animationId !== null) {
        cancelAnimationFrame(animationId);
        animationId = undefined;
        startTime = undefined;
      }
    };

    const scrollAnimation = () => {
      const currTime = (new Date()).getTime();
      diffTime = currTime - startTime;

      if (diffTime <= this.props.duration) {
        animationId = requestAnimationFrame(scrollAnimation);
        scrollPanel();
      } else {
        stopScrollAnimation();
        setCurrentScrollPosition(fromValue + scrollStep);
        checkElementsInViewport();
      }
    };

    scrollAnimation();
  }

  render() {
    const hasFadeOut = this.props.hasFadeOut && this.state.showControls && !this.state.isAtEnd;

    return (
      <div className={cx("outerWrapper", this.props.className, { fullwidth: this.props.fullwidth })} >
        <div className={cx("innerWrapper", { hasFadeOut })}>
          <div className={cx("content", this.props.contentClassName, { center: this.props.center })} ref={(content) => { this.contentElement = content; }}>
            {this.props.children}
          </div>
        </div>
        {!this.state.isAtStart &&
          <button className={styles.arrowLeft} onClick={this.prevSlide} style={{ top: `calc(50% - ${this.props.arrowOffsetBottom})` }}>
            <ArrowLeftIcon />
          </button>
        }
        {!this.state.isAtEnd &&
          <button className={styles.arrowRight} onClick={this.nextSlide} style={{ top: `calc(50% - ${this.props.arrowOffsetBottom})` }}>
            <ArrowRightIcon />
          </button>
        }
      </div>
    );
  }
}

Slider.propTypes = {
  children: PropTypes.any,
  arrowOffsetBottom: PropTypes.string,
  duration: PropTypes.number,
  className: PropTypes.string,
  fullwidth: PropTypes.bool,
  hasFadeOut: PropTypes.bool,
  contentClassName: PropTypes.string,
  center: PropTypes.bool,
};

Slider.defaultProps = {
  children: null,
  arrowOffsetBottom: "0px",
  duration: 300,
  className: null,
  fullwidth: false,
  hasFadeOut: false,
  contentClassName: null,
  center: false,
};

export default Slider;
