
import React from "react";
import { Header, Wrapper } from "./styles/SignupSuccess.styles";

const SignupSuccess = () => (
  <Wrapper>
    <Header>Vielen Dank für deine Anmeldung</Header>
    <p>Wir haben dir gerade eine E-Mail geschickt.</p>
    <p>Bitte aktiviere dein Profil mit dem Link in dieser E-Mail.</p>
  </Wrapper>
);

export default SignupSuccess;
