import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Lazy } from "react-lazy";

import { assetUrl, getHeight, getSrcSet } from "./assetsHelper";
import styles from "./styles/lazyImage.sass";

const cx = classNames.bind(styles);

class ResponsiveImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      started: false,
    };
  }

  onError = () =>
    this.setState({ loaded: true, error: true });

  onLoad = () => {
    this.setState({ loaded: true, error: false });
  }

  render() {
    const { id, filename, aspectRatio, lazy, sizes, widths, width = widths[0], className,
      placeholder, ...attributes } = this.props;
    const height = getHeight(width, aspectRatio);

    if (!attributes.src) {
      attributes.src = assetUrl(id, filename, ["fill", width, height]);
      attributes.srcSet = getSrcSet(widths, aspectRatio, id, filename);
    }

    const image = this.state.error ?
      placeholder
      :
      (<img
        sizes={sizes}
        onError={this.onError}
        onLoad={this.onLoad}
        className={cx(className, (lazy && { image: this.state.started, loaded: this.state.loaded }))}
        alt=""
        {...attributes}
      />);

    return lazy ?
      <Lazy component="span" cushion={200} onViewport={() => this.setState({ started: true })}>
        {image}
      </Lazy>
      :
      image;
  }
}

ResponsiveImage.propTypes = {
  id: PropTypes.string,
  filename: PropTypes.string,
  aspectRatio: PropTypes.number,
  lazy: PropTypes.bool,
  sizes: PropTypes.string,
  widths: PropTypes.array,
  width: PropTypes.number,
  className: PropTypes.any,
  placeholder: PropTypes.any,
};

ResponsiveImage.defaultProps = {
  id: "",
  filename: undefined,
  lazy: false,
  aspectRatio: 1,
  sizes: null,
  widths: [],
  width: undefined,
  className: null,
  placeholder: null,
};

export default ResponsiveImage;
