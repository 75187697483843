import styled, { css } from "styled-components";

import { desktop, tablet, mobile } from "../../styles/mediaQueries";

const backgroundColor = "white";

export const PreviewWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100vw;
  padding: 12px 0 19px;
  background-color: ${backgroundColor};

  ${desktop(css`
    width: calc(100% + 50px);
    top: 74px;
    box-shadow: 0 0 3px 0 rgba(20,20,20,.3);
  `)};

  &:before {
    content: "";
    position: absolute;
    z-index: 30;
    top: -8px;
    left: 63px;
    width: 0;
    height: 0;
    border-right: 9px solid transparent; /* right arrow slant */
    border-bottom: 9px solid ${backgroundColor}; /* bottom, add background color here */
    border-left: 9px solid transparent;  /* left arrow slant */
    font-size: 0;
    line-height: 0;

    ${desktop(css`
      left: 16px;
    `)}
  }
`;

export const ScrollableWrapper = styled.div`
  position: relative;
  height: calc(100vh - 81px);
  margin-bottom: 19px;
  overflow-y: auto;


  ${desktop(css`
    position: initial;
    max-height: calc(100vh - 105px);
    height: initial;
    margin-bottom: 0;
  `)}
`;

export const SearchResults = styled.div`
  ${tablet(css`
    margin-bottom: 19px;
  `)}
`;

export const CtaWrapper = styled.div`
  ${mobile(css`
    margin-bottom: 60px;
  `)}
  ${desktop(css`
    width: 100%;
  `)}
`;
