import { showedModal, hidModal } from "./modalActionCreators";

export const showModal = (modalType, modalProps) => (
  dispatch => dispatch(showedModal(modalType, modalProps))
);

export const hideModal = () => (
  dispatch => dispatch(hidModal())
);

export const toggleModal = (modalType, modalProps) => (
  (dispatch, getState) =>
    (getState().modal.modalType === modalType ? dispatch(hidModal()) : dispatch(showedModal(modalType, modalProps)))
);
