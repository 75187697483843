import React from "react";
import PropTypes from "prop-types";

import {
  Wrapper,
  OpenDropdownButton,
  Input,
  DropdownArea,
  RowItem,
  ScrollArea,
  CreateButton,
  StyledArrow,
  CloseDropdownButton,
  InputWrapper,
  StyledAddIcon,
  StyledCheckIcon,
  InputClearButton,
  StyledClearIcon,
} from "./styles/Dropdown.styles";

const caseInsensitiveSearch = (str, searchString) => (
  str && str.search(new RegExp(searchString, "i")) >= 0
);

const filterItemList = (itemList, filterString) => (
  itemList.filter(item => (caseInsensitiveSearch(item.label, filterString)))
);

class Dropdown extends React.Component {
  state = {
    dropdownVisible: false,
    textInputValue: "",
    selectedListName: "",
  }

  setInputRef = (element) => {
    this.inputElement = element;
  };

  openDropdown = () => {
    this.setState({ dropdownVisible: true });
  }

  closeDropdown = () => {
    this.setState({
      dropdownVisible: false,
      textInputValue: "",
    });
  }

  handleCreateButton = () => {
    if (!this.state.textInputValue.length) {
      this.focusInput();
    } else {
      this.handleItemClick({
        isNew: true,
        label: this.state.textInputValue,
        value: this.state.textInputValue,
      });
    }
  }

  focusInput() {
    this.inputElement.focus();
  }

  handleTextInputChange = (event) => {
    let textInputValue = "";
    if (event) textInputValue = event.target.value;

    this.setState({ textInputValue });
  }

  clearInput = () => {
    this.setState({ textInputValue: "" });
    this.focusInput();
  }

  handleItemClick = (item) => {
    this.props.onChange(item);
    this.setState({ selectedListName: item.label });
    this.closeDropdown();
  }

  render() {
    const { selectedListName, textInputValue, dropdownVisible } = { ...this.state };
    const itemList = filterItemList(this.props.options, textInputValue);

    const dropdownTriggerLabel = !selectedListName.length
      ? "Liste auswählen oder erstellen"
      : selectedListName;

    const createButtonLabel = !textInputValue.length
      ? "Neue Liste erstellen"
      : `„${textInputValue}“ erstellen`;

    return (
      <Wrapper className={this.props.className}>
        {!dropdownVisible && (
          <OpenDropdownButton onClick={this.openDropdown}>
            {dropdownTriggerLabel}
            <StyledArrow down />
          </OpenDropdownButton>
        )}
        {dropdownVisible && (
          <DropdownArea>
            <InputWrapper>
              <Input
                type="text"
                placeholder="Tippe, um eine Liste zu suchen"
                innerRef={this.setInputRef}
                onChange={this.handleTextInputChange}
                value={textInputValue}
              />
              <InputClearButton
                onClick={this.clearInput}
                isVisible={!!textInputValue.length}
              >
                <StyledClearIcon />
              </InputClearButton>
              <CloseDropdownButton onClick={this.closeDropdown}>
                <StyledArrow />
              </CloseDropdownButton>
            </InputWrapper>
            <ScrollArea>
              <CreateButton onClick={this.handleCreateButton}>
                <StyledAddIcon />
                {createButtonLabel}
              </CreateButton>
              {itemList.map(item => (
                <RowItem
                  key={item.value}
                  disabled={item.disabled}
                  onClick={() => (!item.disabled && this.handleItemClick(item))}
                >
                  {item.label}
                  {item.disabled && (<StyledCheckIcon />)}
                </RowItem>
              ))}
            </ScrollArea>
          </DropdownArea>
        )}
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Dropdown.defaultProps = {
  options: [],
  className: "",
};

export default Dropdown;
