import { Lazy } from "react-lazy";
import styled, { css } from "styled-components";

import Chip from "../../buttons/Chip";
import DailyMotionPlayer from "../../player/DailymotionPlayer";
import { hero, brand } from "../../styles/colors";
import { oswaldFont } from "../../styles/typography";
import { tablet } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  position: relative;
  z-index: 5;
  padding: 24px 0;
  background: ${brand};
  ${tablet(css`
    padding: 36px 0;
  `)}
`;

export const Description = styled.div`
  ${oswaldFont({ weight: 500, spacing: 0.052 })}
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
  color: #fff;
  font-size: 22px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;
`;

export const DescriptionLink = styled.a`
  margin-top: 6px;
  color: ${hero};
`;

export const VideoWrapper = styled.div`
  max-width: 746px;
  margin: 0 auto;
  border-left: 6px solid ${hero};
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, .5);
`;

export const LazyWrapper = styled(Lazy)`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  background: #000;
`;

export const StyledPlayer = styled(DailyMotionPlayer)`
  top: 0;
`;

export const UnmuteButton = styled(Chip)`
  position: absolute;
  top: 12px;
  left: 12px;
`;
