/* eslint-disable no-nested-ternary */

const supportPageOffset = () => window.pageXOffset !== undefined;
const isCSS1Compat = () => ((document.compatMode || "") === "CSS1Compat");

export const scrollY = () =>
  (supportPageOffset() ? window.pageYOffset : (isCSS1Compat() ? document.documentElement.scrollTop : document.body.scrollTop));

export const scrollX = () =>
  (supportPageOffset() ? window.pageXOffset : (isCSS1Compat() ? document.documentElement.scrollLeft : document.body.scrollLeft));

export default () => ([scrollX(), scrollY()]);

export const scrollTo = (x, y) => {
  if (typeof window.scrollTo === "function") {
    window.scrollTo(x, y);
  } else if (typeof window.scroll === "function") {
    window.scroll(x, y);
  } else {
    document.body.scrollLeft = x;
    document.documentElement.scrollLeft = x;
    document.body.scrollTop = y;
    document.documentElement.scrollTop = y;
  }
};
