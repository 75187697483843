import styled, { css } from "styled-components";
import ArrowIcon from "../../icons/ArrowIcon";
import { desktop, tablet } from "../../styles/mediaQueries";
import { liReset, ulReset, transition } from "../../styles/mixins";
import { brand, dark, hero } from "../../styles/colors";
import { oswaldFont, notoSansFont } from "../../styles/typography";

export const Wrapper = styled.footer`
  position: relative;
  z-index: 5;
`;

export const LightArea = styled.div`
  padding: 36px 0 27px;
  background: #fff;
`;

export const DarkArea = styled.div`
  padding: 24px 0;
  background: ${brand};
  color: #fff;
`;

export const FooterHeader = styled.div`
  ${oswaldFont({ weight: 500, spacing: 0.075 })}
  display: flex;
  align-items: center;
  padding: 7px 0;
  color: ${brand};
  font-size: 15px;
  line-height: 1.46;
  text-transform: uppercase;
  cursor: pointer;

  ${props => props.social && desktop(css`
    margin-top: 27px;
  `)}

  ${desktop(css`
    cursor: default;
  `)}
`;

export const ArrowWrapper = styled.span`
  display: inline-block;
  width: 24px;
  line-height: 1;
  ${desktop(css`
    display: none;
  `)}
`;

export const Arrow = styled(ArrowIcon)`
  transform: rotate(${props => (props.open ? 180 : 90)}deg);
  transition: .2s transform;
`;

export const FooterItem = styled.li`
  ${liReset}
`;

export const FooterLink = styled.a`
  ${transition("color")}
  display: block;
  padding: 7px 0 7px 24px;
  overflow: hidden;
  color: ${dark};
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${desktop(css`
    padding-left: 0;
  `)}

  &:hover {
    color: ${hero};
    text-decoration: none;
  }
`;

export const FooterContent = styled.ul`
  ${ulReset}
  ${notoSansFont}
  display: ${props => (props.open ? "block" : "none")};
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 1.5;
  ${desktop(css`
    display: block;
  `)}

  ${props => props.social && css`
    display: ${props.open ? "flex" : "none"};
    flex-wrap: wrap;

    ${desktop(css`
      display: flex;
    `)}

    ${FooterItem} {
      width: 50%;
      ${tablet(css`
        width: 33%;
      `)}
      ${desktop(css`
        width: auto;
        margin-right: 48px;
      `)}
    }
  `}
`;

export const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export const GeneralItems = styled.ul`
  ${ulReset}
  ${notoSansFont}
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 14px;
  line-height: 1.5;

  ${desktop(css`
    flex-wrap: nowrap;
    padding: 0;
  `)}
`;

export const GeneralItem = styled.li`
  ${liReset}
  width: 50%;
  ${tablet(css`
    width: 25%;
  `)}
  ${desktop(css`
    width: auto;
  `)}

  a {
    ${transition("color")}
    display: block;
    padding: 7px 0;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: ${hero};
      text-decoration: none;
    }
  }
`;

export const LinkWrapper = styled.li`
  ${liReset}
  width: 100%;
  margin-top: 17px;
  line-height: 0;
  svg {
    width: 98px;
    margin-right: 38px;
  }
  ${desktop(css`
    width: auto;
    margin-top: 0;
  `)}
`;

export const LowerContent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 45px;
  svg {
    width: 330px;
  }
`;
