const scrollLock = (lock) => {
  document.documentElement.style.overflow = (lock ? "hidden" : "");
};

const isLocked = () => document.documentElement.style.overflow === "hidden";

export default (lock) => {
  if (typeof lock === "boolean") {
    scrollLock(lock);
  } else {
    scrollLock(!isLocked());
  }
};
