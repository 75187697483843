import { Map } from "immutable";
import {
  RECEIVED_CONSUMPTIONS, FETCHED_CONSUMPTIONS,
} from "../constants/consumptionConstants";

const initialState = Map();

const consumptionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case RECEIVED_CONSUMPTIONS:
    return state.setIn([action.consumableType, action.consumableId], action.consumptions);

  case FETCHED_CONSUMPTIONS:
    return state.setIn(["fetched", action.consumableType, action.consumableId], Date.now());

  default:
    return state;
  }
};

export default consumptionReducer;
