import { Map, fromJS, Set } from "immutable";
import {
  FETCHING_CINEMA_SHOWTIME, FETCHED_CINEMA_SHOWTIME, RECEIVED_CINEMA_SHOWTIME,
} from "../constants/cinemaShowtimeConstants";

const initialState = Map();

const cinemaShowtimesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case FETCHING_CINEMA_SHOWTIME:
    return state.update("fetching", Set(), fetchingIds => fetchingIds.add(action.id));

  case FETCHED_CINEMA_SHOWTIME:
    return state.update("fetching", Set(), fetchingIds => fetchingIds.remove(action.id));

  case RECEIVED_CINEMA_SHOWTIME:
    return state.set(action.id, fromJS(action.showtime));

  default:
    return state;
  }
};

export default cinemaShowtimesReducer;
