import { Map } from "immutable";
import {
  REGISTERED_AD_PLACEMENT, REMOVED_AD_PLACEMENT,
} from "../constants/advertisingConstants";

const initialState = Map();

const advertisingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case REGISTERED_AD_PLACEMENT:
    return state.setIn(["placements", action.id], action.slot);

  case REMOVED_AD_PLACEMENT:
    return state.deleteIn(["placements", action.id]);

  default:
    return state;
  }
};

export default advertisingReducer;
