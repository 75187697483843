/* eslint-disable import/first */

import { renderReact } from "hypernova-react";

import "../polyfills";
import { addDomLoadedCallback } from "../helper/domHelper";
import { initAxios } from "../helper/urlHelpers";

import FlashMessage from "../notifications/FlashMessage";
import renderBasicComponents from "../renderBasicComponents";
import LoginModule from "../session/LoginModule";
import SignupModule from "../session/SignupModule";
import withProvider from "../store/withProvider";

const renderReactComponents = () => {
  renderBasicComponents();

  renderReact("LoginModule", withProvider(LoginModule));
  renderReact("FlashMessage", FlashMessage);
  renderReact("SignupModule", SignupModule);
};

initAxios();
addDomLoadedCallback(renderReactComponents);
