import React from "react";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

const tabletWidth = 768;
const desktopWidth = 1024;

const propTypes = {
  children: PropTypes.any.isRequired,
};

export const Desktop = ({ children }) =>
  <Responsive minWidth={desktopWidth}>{children}</Responsive>;

Desktop.propTypes = propTypes;


export const TabletOrDesktop = ({ children }) =>
  <Responsive minWidth={tabletWidth}>{children}</Responsive>;

TabletOrDesktop.propTypes = propTypes;


export const Tablet = ({ children }) =>
  <Responsive maxWidth={desktopWidth - 1}>{children}</Responsive>;

Tablet.propTypes = propTypes;


export const Mobile = ({ children }) =>
  <Responsive maxWidth={tabletWidth - 1}>{children}</Responsive>;

Mobile.propTypes = propTypes;

export const isMobile = () =>
  typeof window === "object" && window.matchMedia && window.matchMedia(`(max-width: ${tabletWidth - 1}px)`).matches;

export const isMobileTablet = () =>
  typeof window === "object" && window.matchMedia && window.matchMedia(`(max-width: ${desktopWidth - 1}px)`).matches;

export const isTablet = () =>
  typeof window === "object" && window.matchMedia && window.matchMedia(`(min-width: ${tabletWidth}px)`).matches;

export const isDesktop = () =>
  typeof window === "object" && window.matchMedia && window.matchMedia(`(min-width: ${desktopWidth}px)`).matches;
