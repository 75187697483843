import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import styles from "./styles/Button.sass";

const cx = classNames.bind(styles);

export class Button extends React.PureComponent {
  render() {
    const { disabled, children, className, wrapper, small, active, primary, dark, fullwidth, outline, href, onClick, rel, target, title, attributes, black, center, fixedWidth, marginBefore, belowContent, hover } = this.props;
    const Tag = href ? "a" : "button";
    const button = (
      <Tag
        className={cx("button", { disabled, isSmall: small, isActive: active, isPrimary: primary, isDark: dark, isFullwidth: fullwidth, isOutline: outline, isBlack: black, isHovered: hover, hasMarginBefore: marginBefore, hasFixedWidth: fixedWidth }, className)}
        role="button"
        href={href}
        onClick={onClick}
        rel={rel}
        target={target}
        title={title}
        type={Tag === "button" ? "button" : undefined}
        disabled={Tag === "button" && disabled}
        {...attributes}
      >
        {children}
      </Tag>
    );

    if (wrapper) {
      return (
        <div className={classNames("button--wrapper", wrapper, { "is-small": small }, { "centered-content": center }, { "position-below-content": belowContent })}>
          {button}
        </div>
      );
    }

    return button;
  }
}

Button.propTypes = {
  active: PropTypes.bool,
  black: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  dark: PropTypes.bool,
  fullwidth: PropTypes.bool,
  marginBefore: PropTypes.bool,
  href: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  outline: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  wrapper: PropTypes.any,
  attributes: PropTypes.object,
  center: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  belowContent: PropTypes.bool,
  hover: PropTypes.bool,
};

Button.defaultProps = {
  active: false,
  black: false,
  children: null,
  className: undefined,
  disabled: false,
  primary: false,
  dark: false,
  fullwidth: false,
  marginBefore: false,
  href: undefined,
  rel: undefined,
  target: undefined,
  onClick: null,
  outline: false,
  small: false,
  title: undefined,
  wrapper: undefined,
  attributes: {},
  center: false,
  fixedWidth: false,
  belowContent: false,
  hover: false,
};

export default Button;
