import React from "react";
import classNames from "classnames/bind";
import Waypoint from "react-waypoint";
import PropTypes from "prop-types";
import Slider from "../slider/Slider";
import styles from "./styles/PageNavigation.sass";
import { autotrackParams } from "../tracking/tracking";

const cx = classNames.bind(styles);

class PageNavigation extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      outside: false,
      scrolledUp: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  previousScrollPosition = 0;

  handleScroll = () => {
    window.requestAnimationFrame(() => {
      const currentScrollPosition = window.scrollY || window.pageYOffset;
      if (currentScrollPosition !== this.previousScrollPosition) {
        const scrolledUp = this.previousScrollPosition > currentScrollPosition;
        if (this.state.scrolledUp !== scrolledUp) this.setState({ scrolledUp });
        this.previousScrollPosition = currentScrollPosition;
      }
    });
  }

  render() {
    const { items, hasUnderline, trackingCategory, trackingAction } = this.props;

    return (
      <div id="pageNavigation" className={cx("root", { hasUnderline })}>
        <div className={cx("layout", { isFixed: this.state.outside, isVisible: this.state.scrolledUp })}>
          <div className={cx("navigation")}>
            <Slider className={cx("navigationItems")} hasFadeOut>
              {items.map(item => (
                <a href={item.href} className={cx({ navigationItem: true, isActive: item.active })} key={item.text} title={item.title} {...autotrackParams({ category: trackingCategory, action: trackingAction, label: item.tracking_label })}>{item.text}</a>
              ))}
            </Slider>
          </div>
        </div>
        <Waypoint
          key="waypoint"
          scrollableAncestor="window"
          topOffset="50px"
          onPositionChange={({ currentPosition }) => this.setState({ outside: currentPosition === Waypoint.above })}
        />
      </div>
    );
  }
}

PageNavigation.propTypes = {
  items: PropTypes.array,
  hasUnderline: PropTypes.bool,
  trackingCategory: PropTypes.string,
  trackingAction: PropTypes.string,
};

PageNavigation.defaultProps = {
  items: [],
  hasUnderline: false,
  trackingCategory: null,
  trackingAction: "Subnavi Click",
};

export default PageNavigation;
