import { Map, List, fromJS } from "immutable";

import { keyIn } from "../../helper/immutableHelper";

import { RECEIVED_TRIVIAS, RECEIVED_MORE_TRIVIAS, FETCHING_TRIVIAS, FETCHING_MORE_TRIVIAS, CREATED_TRIVIA, UPDATED_TRIVIA, DELETED_TRIVIA, UPDATED_CATEGORIES, UPDATING_TRIVIA} from "../constants/triviasConstants";

const reducedItem = item =>
  fromJS(item).filterNot(keyIn("trivializableType", "trivializableId"));

const triviasReducer = (state = Map(), action = {}) => {
  switch (action.type) {
  case RECEIVED_TRIVIAS: {
    let nextState = state;
    // clear all items
    state.keySeq().forEach((trivializableType) => {
      state.get(trivializableType, Map()).keySeq().forEach((trivializableId) => {
        nextState = nextState.deleteIn([trivializableType, trivializableId, "items"]);
      });
    });

    action.items.forEach((item) => {
      nextState = nextState.updateIn([item.trivializableType, item.trivializableId, "items"], List(), items =>
        items.push(reducedItem(item)));
    });
    return nextState.setIn([action.trivializableType, action.trivializableId, "lastPage"], action.lastPage)
      .deleteIn([action.trivializableType, action.trivializableId, "fetching"]);
  }

  case RECEIVED_MORE_TRIVIAS: {
    let nextState = state;
    action.items.forEach((item) => {
      nextState = nextState.updateIn([item.trivializableType, item.trivializableId, "items"], List(), (items) => {
        const index = items.findIndex(trivia => trivia.get("id") === item.id);
        const trivia = reducedItem(item);
        if (index > -1) return items.set(index, trivia);
        return items.push(trivia);
      });
    });
    return nextState.setIn([action.trivializableType, action.trivializableId, "lastPage"], !!action.lastPage)
      .deleteIn([action.trivializableType, action.trivializableId, "fetchingMore"]);
  }

  case FETCHING_TRIVIAS:
    return state.setIn([action.trivializableType, action.trivializableId, "fetching"], true);

  case FETCHING_MORE_TRIVIAS:
    return state.setIn([action.trivializableType, action.trivializableId, "fetchingMore"], true);

  case CREATED_TRIVIA:
    return state.updateIn([action.trivia.trivializableType, action.trivia.trivializableId, "items"], List(), items =>
      items.unshift(reducedItem(action.trivia)));

  case UPDATED_TRIVIA: {
    return state.updateIn([action.trivia.trivializableType, action.trivia.trivializableId, "items"], List(), (items) => {
      const index = items.findIndex(item => item.get("id") === action.trivia.id);
      const trivia = reducedItem(action.trivia);
      if (index > -1) return items.set(index, trivia);
      return items;
    });
  }

  case DELETED_TRIVIA:
    return state.updateIn([action.trivializableType, action.trivializableId, "items"], List(), items =>
      items.filterNot(trivia => trivia.get("id") === action.id));

  case UPDATED_CATEGORIES:
    return state.setIn([action.trivializableType, action.trivializableId, "categories"], action.categories);

  case UPDATING_TRIVIA:
    return state.setIn([action.trivializableType, action.trivializableId, "isUpdating"], !!action.isUpdating);

  default:
    return state;
  }
};

export default triviasReducer;
