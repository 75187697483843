import { Map } from "immutable";
import {
  CREATED_WATCHING, DELETED_WATCHING, RECEIVED_WATCHING, FETCHED_WATCHING,
} from "../constants/watchingConstants";

const initialState = Map();

const watchingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case CREATED_WATCHING:
    return state.setIn([action.watchableType, action.watchableId], undefined);

  case RECEIVED_WATCHING:
    return typeof action.watching.id !== "undefined" ? state.setIn([action.watchableType, action.watchableId], action.watching.id) : state;

  case DELETED_WATCHING:
    return state.deleteIn([action.watchableType, action.watchableId]);

  case FETCHED_WATCHING:
    return state.setIn(["fetched", action.watchableType, action.watchableId], Date.now());

  default:
    return state;
  }
};

export default watchingReducer;
