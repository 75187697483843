import styled, { css } from "styled-components";
import Logo from "../../assets/Logo";
import { brand } from "../../styles/colors";
import { desktop, mobileOrTablet } from "../../styles/mediaQueries";
import MobileNavigation from "../MobileNavigation";
import DesktopNavigation from "../DesktopNavigation";

export const Wrapper = styled.nav`
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: ${brand};

  ${desktop(css`
    height: 74px;
  `)}
`;

export const HamburgerMenu = styled(MobileNavigation)`
  position: absolute;
  z-index: 10;
  ${desktop(css`
    display: none;
  `)}
`;

export const DesktopMenu = styled(DesktopNavigation)`
  ${mobileOrTablet(css`
    && {
      display: none;
    }
  `)}
`;

export const InnerWrapper = styled.div`
  ${desktop(css`
    display: flex;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 0 0 12px;
    align-items: center;
    height: 100%;
    justify-content: space-between;
  `)}
`;

export const HeaderLogo = styled(Logo)`
  display: inline-block;
  width: 134px;
  height: 26px;

  ${desktop(css`
    width: 176px;
    height: 34px;
  `)}
`;

export const LogoWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 0;
  text-align: center;

  ${desktop(css`
    position: static;
    transform: none;
    margin-right: 20px;
  `)}
`;

export const UserSearchWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  ${desktop(css`
    position: static;
    width: 350px;
  `)}
`;

export const Spacer = styled.div`
  flex: 1 1 0px;
`;
