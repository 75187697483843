/* eslint-disable jsx-a11y/media-has-caption */

import React from "react";
import classNames from "classnames/bind";
import PropTypes from "prop-types";
import once from "lodash/once";

import { trackEvent } from "../tracking/tracking";
import poster from "./assets/trivia_onboarding.png";
import styles from "./styles/TriviaOnboardingVideo.sass";

const cx = classNames.bind(styles);

const trackPlay = once(() => {
  trackEvent("Movie", "Video Play", "Explainer");
});

export const TriviaOnboardingVideo = ({ autoplay, isDesktop, preload }) => (
  <div className={cx("onboardingContainer")}>
    {isDesktop && <h2 className={cx("headline")}>Trivia schreiben: So einfach geht&apos;s</h2>}
    <video muted autoPlay={autoplay} poster={poster} preload={!preload && "none"} controls className={cx("video", { isDesktop })} onPlay={trackPlay}>
      <source src="/videos/trivia_onboarding.webm" type="video/webm" />
      <source src="/videos/trivia_onboarding.mp4" type="video/mp4" />
    </video>
  </div>
);

TriviaOnboardingVideo.propTypes = {
  autoplay: PropTypes.bool,
  isDesktop: PropTypes.bool,
  preload: PropTypes.bool,
};

TriviaOnboardingVideo.defaultProps = {
  autoplay: false,
  isDesktop: false,
  preload: false,
};

export default TriviaOnboardingVideo;
