import { load } from "hypernova";
import { Map } from "immutable";

import movieCinemaActionCreators from "../actions/movieCinemaActionCreators";
import cinemaReducer from "../reducers/movieCinemaReducer";

const { replacedFilters, receivedItems } = movieCinemaActionCreators;

const preloadMovieCinemaShowtimesStore = (store = {}) => {
  const instances = load("MovieCinemaShowtimesModule") || [];

  if (!instances.length) return store;
  const { items, nextPage, filters, permalink } = instances.pop().data || {};

  let movieCinemaShowtimesStore = Map({ permalink });
  movieCinemaShowtimesStore = cinemaReducer(movieCinemaShowtimesStore, replacedFilters(filters));
  movieCinemaShowtimesStore = cinemaReducer(movieCinemaShowtimesStore, receivedItems(items, nextPage));

  if (typeof store.cinema !== "object") store.cinema = {};
  store.cinema.movie = (store.cinema.movie || Map()).mergeDeep(movieCinemaShowtimesStore);
  return store;
};

export default preloadMovieCinemaShowtimesStore;
