import { Map, fromJS } from "immutable";
import {
  CHANGED_RATING, DELETED_RATING, FETCHED_RATING,
} from "../constants/ratingConstants";

const initialState = Map();

const ratingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case CHANGED_RATING:
    return state.mergeIn([action.item], fromJS(action.rating));

  case DELETED_RATING:
    return state.update(action.item, Map(), (rating) => {
      // TODO: Replace with 'deleteAll' as soon as Immutable v4 is released
      ["value", "top", "flop", "id"].forEach((id) => { rating = rating.delete(id); });
      return rating;
    });

  case FETCHED_RATING:
    return state.setIn(["fetched", action.itemType, action.itemId], Date.now());

  default:
    return state;
  }
};

export default ratingReducer;
