import React from "react";
import PropTypes from "prop-types";
import Co2Logo from "../assets/Co2Logo";
import { Arrow, ArrowWrapper, Content, DarkArea, FooterContent, FooterHeader, FooterItem,
  FooterLink, GeneralItem, GeneralItems, LightArea, Wrapper, LowerContent } from "./styles/Footer.styles";
import { externalLinkAttrsFollow, externalLinkAttrs } from "../helper/urlHelpers";

const fixedLinks = [
  ["Über uns", "/pages/about"],
  ["Sitemap", "/sitemaps"],
  ["FAQ", "/pages/faq"],
  ["Support", "/support"],
  ["Kontakt", "/pages/contact"],
  ["Tour", "/pages/tour"],
  ["Community Richtlinien", "/pages/richtlinien"],
];

const socialLinks = [
  ["Facebook", "https://www.facebook.com/moviepilot"],
  ["YouTube", "https://www.youtube.com/user/moviepiloten"],
  ["Twitter", "https://twitter.com/moviepilot"],
  ["Instagram", "https://instagram.com/moviepilot_de"],
  ["Pinterest", "https://www.pinterest.de/moviepilotde"],
  ["RSS Feed", "/rss/moviepilot-standard"],
];

const generalLinks = [
  ["Impressum", "/pages/imprint"],
  ["AGB", "/pages/terms"],
  ["Datenschutz", "/pages/privacy"],
  ["Jobs", "https://webedia-group.de/karriere", externalLinkAttrsFollow],
  ["GameStar", "https://www.gamestar.de", externalLinkAttrsFollow],
  ["GamePro", "https://www.gamepro.de", externalLinkAttrsFollow],
  ["Mein MMO", "https://mein-mmo.de", externalLinkAttrsFollow],
];

const headlines = ["Zu Moviepilot", "Aktuell im Kino & DVD", "Populäre Stars", "Kommende Filme"];

class Footer extends React.Component {
  state = {
    open: null,
  }
  handlers = {}

  handleToggle = (name) => {
    if (!this.handlers[name]) {
      this.handlers[name] = () => {
        this.setState(state => ({ open: (state.open === name ? null : name) }));
      };
    }

    return this.handlers[name];
  }

  render() {
    const { hotItems, starItems, upcomingItems, classicUrl } = this.props;
    const { open } = this.state;

    return (
      <Wrapper>
        <LightArea>
          <Content className="grid--row">
            {[fixedLinks, hotItems, starItems, upcomingItems].map((links, index) => {
              const isOpen = open === index;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="grid--col-lg-3" key={index}>
                  <FooterHeader open={isOpen} onClick={this.handleToggle(index)}>
                    <ArrowWrapper>
                      <Arrow size={16} open={isOpen} />
                    </ArrowWrapper>
                    {headlines[index]}
                  </FooterHeader>
                  <FooterContent open={isOpen}>
                    {links.map(([name, url, props = {}]) => (
                      <FooterItem key={url}>
                        <FooterLink href={url} {...props}>{name}</FooterLink>
                      </FooterItem>
                    ))}
                  </FooterContent>
                </div>
              );
            })}
            <div className="grid--col-sm-12">
              <FooterHeader open={open === "social"} onClick={this.handleToggle("social")} social>
                <ArrowWrapper>
                  <Arrow size={16} open={open === "social"} />
                </ArrowWrapper>
                Social Media
              </FooterHeader>
              <FooterContent open={open === "social"} social>
                {socialLinks.map(([name, url]) => (
                  <FooterItem key={url}>
                    <FooterLink href={url} {...externalLinkAttrsFollow}>{name}</FooterLink>
                  </FooterItem>
                ))}
              </FooterContent>
            </div>
            {classicUrl &&
              <div className="grid--col-sm-12">
                <FooterContent open css="margin: 10px 0 0;">
                  <FooterLink href={classicUrl}>Zur klassischen Ansicht</FooterLink>
                </FooterContent>
              </div>
            }
          </Content>
        </LightArea>
        <DarkArea>
          <Content className="grid--row">
            <div className="grid--col-sm-12">
              <GeneralItems>
                {generalLinks.map(([name, url, props = {}]) => (
                  <GeneralItem key={url}>
                    <a href={url} {...props}>{name}</a>
                  </GeneralItem>
                ))}
                <GeneralItem>
                  <a href="https://www.co2neutralwebsite.de/certificate/7033/de" title="CO2 Neutrale Website" {...externalLinkAttrs}>
                    <Co2Logo />
                  </a>
                </GeneralItem>
              </GeneralItems>
            </div>
          </Content>
          <LowerContent>
            <a href="https://webedia-group.de" {...externalLinkAttrsFollow}>
              <img src="/images/logos/boxoffice-webedia-logo.svg" alt="webedia. Movies With Passion" />
            </a>
          </LowerContent>
        </DarkArea>
      </Wrapper>
    );
  }
}

Footer.propTypes = {
  hotItems: PropTypes.array,
  starItems: PropTypes.array,
  upcomingItems: PropTypes.array,
  classicUrl: PropTypes.string,
};

Footer.defaultProps = {
  hotItems: [],
  starItems: [],
  upcomingItems: [],
  classicUrl: undefined,
};

export default Footer;
