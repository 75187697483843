import styled, { css } from "styled-components";

import SearchIcon from "../../icons/SearchIcon";
import SearchbarClearIcon from "../../icons/SearchbarClearIcon";
import { dark, brand } from "../../styles/colors";
import { desktop, mobileOrTablet } from "../../styles/mediaQueries";

import { buttonReset } from "../../styles/mixins";

export const Form = styled.form`
  position: absolute;
  z-index: ${props => (props.isHidden ? -10 : 15)};
  left: 0;
  width: 100vw;
  padding: 6px 50px;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  background-color: ${brand};

  ${desktop(css`
    opacity: 1;
    z-index: 15;
    position: relative;
    padding: 18px 0;
    width: 100%;
  `)};
`;

export const SubmitLabel = styled.label`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;

  ${mobileOrTablet(() => css`
    top: 0;
    width: 48px;
    height: 50px;
  `)}
`;

export const SubmitButton = styled.input`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  opacity: 0;
  cursor: pointer;
`;

export const Inputfield = styled.input`
  width: 100%;
  height: 38px;
  padding-right: 35px;
  padding-left: 39px;
  border: 1px solid ${props => props.color};
  border-radius: 3px;
  color: ${dark};
  font-size: 13px;
  line-height: 18px;

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: ${props => props.color};
  }

  &:focus {
    outline: none;
  }

  ${mobileOrTablet(() => css`
    padding-left: 48px;
    padding-right: 48px;
    &::placeholder {
      font-size: 12px;
    }
  `)}
`;

export const ClearButton = styled.button`
  ${buttonReset}
  display: flex;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  position: absolute;
  right: 50px;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  transition: opacity .2s ease-in-out;
  opacity: ${props => (props.visible ? 1 : 0)};
  cursor: pointer;

  ${mobileOrTablet(() => css`
    top: 0;
    width: 48px;
    height: 50px;
  `)}

  ${desktop(css`
    right: 0;
  `)};
`;

export const ReturnButton = styled.button`
  ${buttonReset}
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;

  ${desktop(css`
    display: none;
  `)};
`;

export const StyledSearchIcon = styled(SearchIcon)`
  ${desktop(css`
    width: 20px;
    height: 20px;
  `)}
`;

export const StyledSearchbarClearIcon = styled(SearchbarClearIcon)`
  ${desktop(css`
    width: 20px;
    height: 20px;
  `)}
`;
