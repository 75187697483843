import {
  RECEIVED_USER_LISTS, CREATED_USER_LIST, ADDED_TO_USER_LIST,
} from "../constants/userlistConstants";

export const receivedLists = (itemType, itemId, lists) => ({
  type: RECEIVED_USER_LISTS,
  itemType,
  itemId,
  lists,
});

export const createdList = (itemType, itemId, list) => ({
  type: CREATED_USER_LIST,
  itemType,
  itemId,
  list,
});

export const addedToList = (itemType, itemId, listId) => ({
  type: ADDED_TO_USER_LIST,
  itemType,
  itemId,
  listId,
});
