import PropTypes from "prop-types";
import React from "react";
import { dark } from "../styles/colors";
import { Chip as StyledChip, IconWrapper } from "./styles/Chip.styles";

const Chip = ({ href, children, onClick, icon: Icon, ...props }) => (
  <StyledChip
    tag={href ? "a" : "span"}
    href={href}
    onClick={onClick}
    hasIcon={!!Icon}
    {...props}
  >
    {Icon &&
      <IconWrapper>
        <Icon size={36} color={dark} />
      </IconWrapper>
    }
    {children}
  </StyledChip>
);

Chip.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  icon: PropTypes.func,
};

Chip.defaultProps = {
  href: undefined,
  children: null,
  onClick: undefined,
  icon: null,
};

export default Chip;
