import React from "react";
import PropTypes from "prop-types";
import scrollLock from "../helper/scrollLock";
import { isDesktop } from "../layout/MediaQueries";
import HamburgerIcon from "./HamburgerIcon";
import { linkTracking } from "./navigationHelpers";
import { Wrapper, Headline, Link, LinksWrapper, LinkWrapper, Overlay, Section, Triangle } from "./styles/MobileNavigation.styles";
import navItems from "./navigationItems";

const getNavSection = (items) => {
  const [[headlineCaption, headlineUrl, headlineTracking], ...links] = items;

  return [
    <Headline key="headline" href={headlineUrl} role="menuitem" {...linkTracking(headlineTracking)}>
      {headlineCaption}
    </Headline>,
    <LinksWrapper key="links" role="menu" aria-label={headlineCaption}>
      {links.map(([caption, url, tracking]) => (
        <LinkWrapper key={url}>
          <Link href={url} role="menuitem" {...linkTracking(tracking)}>
            {caption}
          </Link>
        </LinkWrapper>
      ))}
    </LinksWrapper>,
  ];
};

class HamburgerNavigation extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      scrollLock(!isDesktop() && this.props.open);
    }
  }

  render() {
    const { className, open, onToggle } = this.props;
    return (
      <Wrapper className={className}>
        <HamburgerIcon color="#fff" size={50} active={open} onClick={onToggle} aria-haspopup="true" aria-controls="navigationmobile" />
        <Overlay visible={open} id="navigationmobile" role="menu" aria-label="Hauptmenü">
          {navItems.map(items => (
            <Section key={items[0][1]} role="none">
              {getNavSection(items)}
            </Section>
          ))}
        </Overlay>
        <Triangle visible={open} />
      </Wrapper>
    );
  }
}

HamburgerNavigation.propTypes = {
  className: PropTypes.any,
  open: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

HamburgerNavigation.defaultProps = {
  className: null,
  open: false,
};

export default HamburgerNavigation;
