import React from "react";
import PropTypes from "prop-types";

import { Input, Label, Wrapper } from "./styles/CheckboxBig.styles";

export class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.disabled !== nextProps.disabled || this.state.checked !== nextState.checked;
  }

  handleCheck = (event) => {
    const { checked, name } = event.target;
    this.setState({ checked });
    this.props.onChange(checked, name);
  }

  render() {
    const { className, name, disabled, children, error } = this.props;
    const id = `checkbox-${name}`;

    return (
      <Wrapper
        className={className}
        role="checkbox"
        aria-checked={this.state.checked}
        tabIndex={0}
      >
        <Input
          name={name}
          id={id}
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleCheck}
          disabled={disabled}
        />
        <Label htmlFor={id} error={error}>{children}</Label>
      </Wrapper>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  className: null,
  children: null,
  disabled: false,
  error: false,
  onChange: () => {},
};

export default Checkbox;
