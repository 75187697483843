import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import debounce from "lodash/debounce";
import withProvider from "../store/withProvider";
import mpAnalyticsEvent from "../tracking/mpAnalyticsEvent";
import SearchbarFormOpener from "./SearchbarFormOpener";
import SearchbarForm from "./SearchbarForm";
import SearchbarPreview from "./SearchbarPreview";

import { callSearchAPI } from "./actions/searchActions";
import { SearchbarContainer } from "./styles/Searchbar.styles";

class Searchbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
      formHidden: true,
      focusedElement: 0, // 0 -> no focus // -1 -> CTA focused // greater than 0 -> search item focused
      blurred: false,
    };

    this.callSearchAPI = debounce(props.callSearchAPI, 200);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchResults !== this.props.searchResults) {
      this.setState({ focusedElement: 0 });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  setSearchbarRef = (element) => {
    this.searchbarRef = element;
  };

  toggleForm = () => {
    if (this.state.formHidden) {
      document.getElementById("searchbarInput").focus();
    } else {
      document.getElementById("searchbarInput").blur();
    }
    this.setState({
      formHidden: !this.state.formHidden,
    });
  }

  handleKeyDown = (event) => {
    // keyCodes: 38 = arrow up / 40 = arrow down
    const arrowKey = event.keyCode === 38 || event.keyCode === 40 ? event.keyCode : 0;
    const enterKey = event.keyCode === 13;

    if (arrowKey) {
      event.preventDefault();

      const amountOfSearchResults = this.props.searchResults.length;
      this.arrowDirectionHandler(amountOfSearchResults, arrowKey);
    }

    if (enterKey && this.state.focusedElement > 0) {
      event.preventDefault();
      this.goToFocusedItem();
    }
  }

  goToFocusedItem = () => {
    const focusedItem = this.props.searchResults[this.state.focusedElement - 1];
    const payload = {
      target: focusedItem.path,
      target_type: focusedItem.itemClass,
      target_id: focusedItem.id,
      query: this.state.searchString,
      position: this.state.focusedElement - 1,
    };

    mpAnalyticsEvent("search", "suggestions", payload, () => { window.location.href = focusedItem.path; });
  }

  arrowDirectionHandler = (amountOfSearchResults, direction = null) => {
    const { focusedElement } = this.state;
    let newFocusedElement = focusedElement;

    if (direction === 38) {
      newFocusedElement = focusedElement === -1 ? amountOfSearchResults : focusedElement - 1;
    } else if (direction === 40) {
      newFocusedElement = focusedElement === amountOfSearchResults ? -1 : focusedElement + 1;
    }

    this.setState({
      focusedElement: newFocusedElement,
    });
  }

  handleTextInputChange = (event) => {
    let searchString = "";
    if (event) searchString = event.target.value;

    this.setState({ searchString });
    this.callSearchAPI("searchbar", searchString);
  }

  handleClick = (e) => {
    if (!this.searchbarRef.contains(e.target)) {
      this.setState({ blurred: true });
    } else if (this.state.blurred) {
      this.setState({ blurred: false });
    }
  }

  render() {
    const { searchResults } = this.props;
    const { focusedElement, formHidden, searchString } = this.state;

    return (
      <SearchbarContainer
        innerRef={this.setSearchbarRef}
      >
        <SearchbarFormOpener
          isHidden={!formHidden}
          showForm={this.toggleForm}
        />
        <SearchbarForm
          searchString={searchString}
          handleTextInputChange={this.handleTextInputChange}
          isHidden={formHidden}
          handleKeyDown={this.handleKeyDown}
          hideForm={this.toggleForm}
        />

        {!this.state.blurred && searchResults.length ?
          <SearchbarPreview
            searchResults={searchResults}
            focusedElement={focusedElement}
            searchString={searchString}
          />
          : null
        }
      </SearchbarContainer>
    );
  }
}

Searchbar.propTypes = {
  searchResults: PropTypes.array,
  callSearchAPI: PropTypes.func.isRequired,
};

Searchbar.defaultProps = {
  searchResults: [],
};

const mapStateToProps = state => ({
  searchResults: state.search.get("searchbar"),
});

const mapDispatchToProps = dispatch => ({
  callSearchAPI: (initiator, searchString) => dispatch(callSearchAPI(initiator, searchString)),
});

export default withProvider(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Searchbar));
