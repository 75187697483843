import React from "react";
import PropTypes from "prop-types";
import { linkTracking } from "./navigationHelpers";
import { Overlay, OverlayItem, OverlayItemLink, TopItem, TopItemLink, Triangle, Wrapper } from "./styles/DesktopNavigation.styles";
import navItems from "./navigationItems";

const getNavSection = (items) => {
  const [[topCaption, topUrl, topTracking], ...links] = items;

  const content = [
    <TopItemLink key="topItem" href={topUrl} role="menuitem" {...linkTracking(topTracking)}>
      {topCaption}
    </TopItemLink>,
  ];

  if (links.length > 0) {
    content.push(
      <Overlay key="links" role="menu" aria-label={topCaption}>
        {links.map(([caption, url, tracking]) => (
          <OverlayItem key={url}>
            <OverlayItemLink href={url} role="menuitem" {...linkTracking(tracking)}>{caption}</OverlayItemLink>
          </OverlayItem>
        ))}
      </Overlay>,
      <Triangle key="triangle" />,
    );
  }

  return content;
};

class DesktopNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
  }

  toggleOpenState() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <Wrapper className={this.props.className}>
        {navItems.map(items => (
          <TopItem key={items[0][1]} role="none">
            {getNavSection(items)}
          </TopItem>
        ))}
        {/* <Overlay visible={this.state.open} role="menu" aria-label="Hauptmenü">
        </Overlay> */}
      </Wrapper>
    );
  }
}

DesktopNavigation.propTypes = {
  className: PropTypes.any,
};

DesktopNavigation.defaultProps = {
  className: null,
};

export default DesktopNavigation;
