import { Map } from "immutable";

import { RECEIVED_FRIENDS_OPINIONS } from "../constants/friendsOpinionsConstants";

const initialState = Map();

const friendsOpinionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case RECEIVED_FRIENDS_OPINIONS:
    return state.setIn([action.itemType, action.itemId], action.friendsOpinions);
  default:
    return state;
  }
};

export default friendsOpinionsReducer;
