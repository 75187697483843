export const trackEvent = (category, action, label, value, options = {}) =>
  new Promise((resolve) => {
    const timeoutId = setTimeout(() => resolve("Timeout"), 300);
    try {
      const trackParams = {
        hitType: "event",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: parseInt(value, 10),
        hitCallback: () => {
          clearTimeout(timeoutId);
          resolve("success");
        },
        ...options,
      };

      Object.keys(trackParams).forEach((key) => {
        if (trackParams[key] == null || Number.isNaN(trackParams[key])) delete trackParams[key];
      });

      if (!trackParams.eventCategory || !trackParams.eventAction) {
        resolve("Missing Parameters");
      } else if (typeof window.ga !== "function") {
        resolve("Google Analytics not loaded");
      } else {
        window.ga("send", trackParams);
      }
    } catch (e) {
      clearTimeout(timeoutId);
      resolve(`Exception: ${e}`);
    }
  });

export const autotrackParams = ({ category, action, label, value, on = "click,auxclick,contextmenu" }) => {
  if ((typeof category === "undefined" || category === null) || (typeof action === "undefined" || action === null)) return {};
  return {
    "data-ga-on": on,
    "data-ga-event-category": category,
    "data-ga-event-action": action,
    "data-ga-event-label": label,
    "data-ga-event-value": value,
  };
};

export const dataLayerPush = (category, action, itemType, additionalContent = {}) => {
  if (typeof window === "undefined" || window === null) return;

  const dataLayer = window.dataLayer || [];
  const data = dataLayer[0];

  if (typeof data === "object") {
    const eventData = {
      event: "eventGA",
      eventAction: action,
      eventCategory: category,
    };

    switch (itemType) {
    case "Series":
      eventData.clicked_series_id = data.series_id;
      eventData.series_distributor = data.series_distributors;
      break;
    case "Movie":
      eventData.clicked_movie_id = data.movie_id;
      eventData.movie_distributor = data.movie_distributors;
      break;
    case "Video":
      break;
    default:
      return;
    }

    dataLayer.push(Object.assign(eventData, additionalContent));
  }
};
