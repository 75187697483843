import {
  FETCHING_CINEMA_SHOWTIME, FETCHED_CINEMA_SHOWTIME, RECEIVED_CINEMA_SHOWTIME,
} from "../constants/cinemaShowtimeConstants";

export const fetchingCinemaShowtime = id => ({
  type: FETCHING_CINEMA_SHOWTIME,
  id: String(id),
});

export const fetchedCinemaShowtime = id => ({
  type: FETCHED_CINEMA_SHOWTIME,
  id: String(id),
});

export const receivedCinemaShowtime = (id, showtime) => ({
  type: RECEIVED_CINEMA_SHOWTIME,
  id: String(id),
  showtime,
});
