import React from "react";
import PropTypes from "prop-types";
import ImageWithFallback from "./ImageWithFallback";

const aspectRatioFor = (type) => {
  switch (type) {
  case "Cinema":
  case "Article":
  case "Video":
    return 1.5;
  case "ForumPost":
    return 1;
  default:
    return 0.6979;
  }
};

class Poster extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <ImageWithFallback aspectRatio={aspectRatioFor(this.props.type)} {...this.props} />;
  }
}

Poster.propTypes = {
  type: PropTypes.string,
};

Poster.defaultProps = {
  type: "Movie",
};

export default Poster;
