import styled, { css } from "styled-components";
import LogoutIconModule from "../../icons/LogoutIcon";
import { hero, dark, darkGray } from "../../styles/colors";
import { transition } from "../../styles/mixins";
import { notoSerifFont, oswaldFont, linkStyle, notoSansFont } from "../../styles/typography";

export const Wrapper = styled.div`
  padding: 12px 24px;
`;

export const Headline = styled.div`
  ${notoSerifFont}
  font-size: 15px;
  font-style: italic;
  line-height: 1.6;
`;

export const Meta = styled.div`
  display: flex;
  margin: 15px 0 27px;
`;

export const AvatarWrapper = styled.a`
  display: block;
  flex: 0 0 88px;
  width: 88px;
`;

export const MetaInfo = styled.div`
  flex: 1 1;
  padding-left: 12px;
`;

export const Username = styled.a`
  ${oswaldFont({ spacing: 0.016 })}
  ${transition("color")}
  margin-bottom: 6px;
  color: ${dark};
  font-size: 19px;
  line-height: 1.3;

  &:hover {
    color: ${hero};
  }
`;

export const Contributions = styled.div`
  ${notoSansFont}
  font-size: 12px;
  line-height: 1.5;
`;

export const Contribution = styled.a`
  ${linkStyle}
  white-space: nowrap;
`;

export const NavigationItem = styled.a`
  ${notoSansFont}
  ${transition("color")}
  display: flex;
  align-items: center;
  height: 42px;
  color: ${dark};
  font-size: 15px;
  line-height: 1.6;
  cursor: pointer;
  ${props => props.isLogout && css`
    color: ${darkGray};
    margin-top: 6px;
  `}

  &:hover {
    color: ${hero};
    text-decoration: none;
  }
`;

export const Badge = styled.div`
  ${oswaldFont({})}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 12px;
  border-radius: 50%;
  background: ${hero};
  color: #fff;
`;

export const LogoutIcon = styled(LogoutIconModule)`
  width: 24px;
  height: 24px;
  margin-left: 6px;
`;
