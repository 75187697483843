import PropTypes from "prop-types";
import React from "react";
import Avatar from "../assets/Avatar";
import { darkGray } from "../styles/colors";
import { AvatarWrapper, Badge, Contribution, Contributions, Headline, LogoutIcon, Meta, MetaInfo,
  NavigationItem, Username, Wrapper } from "./styles/UserMenu.styles";

const menuItems = [
  ["Mein Profil", "/myprofile"],
  ["Mein Blog", "/my_blog"],
  ["Meine Merkliste", "/my_watchlist"],
  ["Meine Listen", "/my_boards"],
  ["Meine Lieblingskinos", "/kino/kinoprogramm/lieblingskinos"],
  ["Posteingang", "/inbox"],
  ["Freunde", "/my_friendships"],
  ["Support", "/support"],
];

const UserMenu = ({ session, onLogout }) => (
  <Wrapper>
    <Headline>Du bist angemeldet als:</Headline>
    <Meta>
      <AvatarWrapper href="/myprofile">
        <Avatar
          filename={session.current_user_avatar.image_filename}
          id={session.current_user_avatar.image_id}
          width={88}
          username={session.username}
          isUser
        />
      </AvatarWrapper>
      <MetaInfo>
        <Username href="/myprofile">{session.username}</Username>
        <Contributions>
          <Contribution href={session.movie_ratings_path}>{session.movie_ratings} Filme</Contribution>
          {" | "}
          <Contribution href={session.series_ratings_path}>{session.series_ratings} Serien</Contribution>
          {" | "}
          <Contribution href={session.comments_path}>{session.comments} Kommentare</Contribution>
          {" | "}
          <Contribution href={session.lists_path}>{session.lists} Listen</Contribution>
        </Contributions>
      </MetaInfo>
    </Meta>
    {menuItems.map(([caption, path]) => (
      <NavigationItem key={path} href={path}>
        {caption}
        {caption === "Posteingang" && session.unread_message_count && <Badge>{session.unread_message_count}</Badge>}
        {caption === "Freunde" && session.pending_friend_request_count && <Badge>{session.pending_friend_request_count}</Badge>}
      </NavigationItem>
    ))}
    <NavigationItem isLogout onClick={onLogout}>
      Abmelden
      <LogoutIcon size={24} color={darkGray} />
    </NavigationItem>
  </Wrapper>
);

UserMenu.propTypes = {
  session: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default UserMenu;
