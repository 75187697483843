import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { displaySuccess } from "../notifications/actions/notificationActions";
import { trackEvent } from "../tracking/tracking";
import RecoverPassword from "./RecoverPassword";
import RecoverPasswordSuccess from "./RecoverPasswordSuccess";
import { createFacebookSession, createSession as createSessionAction } from "./actions/sessionActions";
import LoginForm from "./LoginForm";
import setCsrfMetaTags from "../../assets/javascripts/lib/set_csrf_meta_tags";

class LoginModule extends React.Component {
  state = {
    redirecting: false,
    page: "login",
  }

  afterLogin = (session) => {
    if (this.props.redirect) {
      this.setState({ redirecting: true });
      window.location.href = this.props.redirect;
    } else {
      setCsrfMetaTags();
      this.props.showSuccess(session.username);
      this.props.onLogin();
    }
  }

  handleLogin = (username, password) => {
    this.props.createSession(username, password).then(this.afterLogin);
  }

  handleFacebookLogin = () => {
    this.props.createFacebookSession().then(this.afterLogin);
    trackEvent("Sign-Up", "Click: Facebook Login");
  }

  showLogin = () => { this.setState({ page: "login" }); }
  showRecover = () => {
    this.setState({ page: "recover" });
    trackEvent("Sign-Up", "Click: Forgot Password");
  }
  showRecovered = () => { this.setState({ page: "recovered" }); }

  render() {
    const { session, onRegister, showRegister } = this.props;
    const { redirecting, page } = this.state;

    if (page === "recover") {
      return <RecoverPassword onReturn={this.showLogin} onReset={this.showRecovered} />;
    }

    if (page === "recovered") {
      return <RecoverPasswordSuccess />;
    }

    return (<LoginForm
      error={session.error}
      facebookLoading={session.facebookLoading}
      loading={session.loading}
      onFacebookLogin={this.handleFacebookLogin}
      onForgotPwd={this.showRecover}
      onLogin={this.handleLogin}
      redirecting={redirecting}
      onRegister={onRegister}
      showRegister={showRegister}
    />);
  }
}

LoginModule.propTypes = {
  createFacebookSession: PropTypes.func.isRequired,
  createSession: PropTypes.func.isRequired,
  onLogin: PropTypes.func,
  onRegister: PropTypes.func,
  redirect: PropTypes.string,
  session: PropTypes.object.isRequired,
  showRegister: PropTypes.bool,
  showSuccess: PropTypes.func.isRequired,
};

LoginModule.defaultProps = {
  onLogin: () => {},
  onRegister: () => {},
  redirect: null,
  showRegister: false,
};

const mapStateToProps = state => ({
  session: state.session.toJS() || {},
});

const mapDispatchToProps = dispatch => ({
  createFacebookSession: () => dispatch(createFacebookSession()),
  createSession: (username, password) => dispatch(createSessionAction(username, password)),
  showSuccess: username => dispatch(displaySuccess(`Willkommen zurück, ${username}. Du hast dich erfolgreich eingeloggt.`)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginModule);
