import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CSSTransitionGroup } from "react-transition-group";
import classNames from "classnames/bind";

import LoginSignupModule from "../session/LoginSignupModule";
import AddItemToUserlist from "../userlist/AddItemToUserlist";
import CinemaShowtime from "../cinema/showtime/CinemaShowtime";
import CloseIcon from "../icons/CloseIcon";
import TriviaOnboardingVideo from "../trivias/TriviaOnboardingVideo";
import { ADD_ITEM_TO_USERLIST, SELECTED_CINEMA_SHOWTIME, TRIVIA_ONBOARDING_VIDEO, LOGIN } from "./modalNames";
import { hideModal } from "./actions/modalActions";

import styles from "./styles/Modal.sass";

const cx = classNames.bind(styles);

const MODAL_COMPONENTS = {
  [ADD_ITEM_TO_USERLIST]: AddItemToUserlist,
  [SELECTED_CINEMA_SHOWTIME]: CinemaShowtime,
  [TRIVIA_ONBOARDING_VIDEO]: TriviaOnboardingVideo,
  [LOGIN]: LoginSignupModule,
};

export const Modal = ({ modalType, modalProps, closeModal }) => {
  let SpecificModal = null;
  if (modalType) {
    SpecificModal = MODAL_COMPONENTS[modalType];
  }

  const close = () => {
    closeModal();
  };

  const { width, headline, ...props } = modalProps;

  const darkMode = modalType === ADD_ITEM_TO_USERLIST ? "darkMode" : null;

  return (
    <CSSTransitionGroup
      transitionName={{
        enter: styles.animationEnter,
        enterActive: styles.animationEnterActive,
        leave: styles.animationLeave,
        leaveActive: styles.animationLeaveActive,
      }}
      transitionEnterTimeout={300}
      transitionLeaveTimeout={300}
      transitionEnter
      transitionLeave
    >
      {SpecificModal && <div key={modalType}>
        <div className={styles.background} onClick={close} onKeyDown={close} role="button" tabIndex={0} />
        <div className={cx({ wrapper: true }, darkMode)} style={{ maxWidth: width }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={close} onKeyDown={close} className={cx("close")} role="button" tabIndex={0}>
            <CloseIcon size={12} color={darkMode ? "#fff" : null} />
          </a>
          <div className={cx("content")}>
            {headline && headline.length && <div className={cx("header", darkMode)}>{headline}</div>}
            <SpecificModal {...props} />
          </div>
        </div>
      </div>}
    </CSSTransitionGroup>
  );
};

Modal.propTypes = {
  modalType: PropTypes.string,
  modalProps: PropTypes.object,
  closeModal: PropTypes.func,
};

Modal.defaultProps = {
  modalType: null,
  modalProps: {},
  closeModal: () => { },
};

export const mapStateToProps = state => state.modal;

export const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(hideModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
