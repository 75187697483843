import React from "react";
import PropTypes from "prop-types";

import TrackingButton from "../buttons/TrackingButton";
import scrollLock from "../helper/scrollLock";
import { toUrlParams } from "../helper/urlHelpers";
import { isDesktop } from "../layout/MediaQueries";
import SearchbarPreviewItem from "./SearchbarPreviewItem";

import {
  PreviewWrapper,
  ScrollableWrapper,
  SearchResults,
  CtaWrapper,
} from "./styles/SearchbarPreview.styles";


class SearchbarPreview extends React.Component {
  componentDidMount() {
    if (!isDesktop()) scrollLock(true);
  }

  componentWillUnmount() {
    scrollLock(false);
  }

  render() {
    const { searchResults, searchString, focusedElement } = this.props;
    return (
      <PreviewWrapper>
        <ScrollableWrapper>
          <SearchResults>
            {searchResults.map((searchItem, index) => (
              <SearchbarPreviewItem
                key={searchItem.id}
                {...searchItem}
                searchString={searchString}
                index={index}
                isFocused={focusedElement - 1 === index}
              />
            ))}
          </SearchResults>
          <CtaWrapper>
            <TrackingButton
              href={`/suche${toUrlParams({ q: searchString })}`}
              wrapper
              center
              fixedWidth
              black
              outline
              hover={focusedElement === -1}
              tracking={{ category: "Search", action: "All Search Results" }}
            >
              Alle anzeigen
            </TrackingButton>
          </CtaWrapper>
        </ScrollableWrapper>
      </PreviewWrapper>
    );
  }
}

SearchbarPreview.propTypes = {
  searchResults: PropTypes.array,
  focusedElement: PropTypes.number,
  searchString: PropTypes.string,
};

SearchbarPreview.defaultProps = {
  searchResults: [],
  focusedElement: 0,
  searchString: null,
};

export default SearchbarPreview;
