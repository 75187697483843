import styled from "styled-components";
import { lightestGray } from "../../styles/colors";
import { buttonReset } from "../../styles/mixins";
import { linkStyle, notoSansFont } from "../../styles/typography";

export const Wrapper = styled.div`
  ${notoSansFont}
  padding: 10px;
  background: ${lightestGray};
  font-size: 14px;
`;

export const Button = styled.button`
  ${buttonReset}
  ${linkStyle({ shadowColor: lightestGray })}
  margin-top: 6px;
`;

export const Title = styled.div`
  margin-bottom: 6px;
  font-weight: 700;
`;
