import React from "react";
import classNames from "classnames/bind";

import { elementLoadingBackgroundAnimation } from "../../styles/ux-effects.sass";

import styles from "./styles/CinemaShowtimePlaceholder.sass";

const cx = classNames.bind(styles);

class CinemaShowtimePlaceholder extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className={elementLoadingBackgroundAnimation}>
        <div className={cx("wrapper")}>
          <div className={styles.title1} />
          <div className={styles.title2} />
          <div className={styles.posterMetaWrapper}>
            <div className={styles.poster} />
            <div className={styles.meta}>
              <div className={styles.metaHeadline} />
              <div className={styles.metaContent} />
              <div className={styles.metaHeadline} />
              <div className={styles.metaContent} />
              <div className={styles.metaHeadline} />
              <div className={styles.metaContent} />
            </div>
          </div>
          <div className={styles.share}>
            <div className={styles.shareHeadline} />
            <div className={styles.buttons}>
              <div className={styles.button}>
                <div className={styles.buttonIcon} />
                <div className={styles.buttonText} />
              </div>
              <div className={styles.button}>
                <div className={styles.buttonIcon} />
                <div className={styles.buttonText} />
              </div>
            </div>
            <div className={styles.linkHeadline} />
            <div className={styles.linkHeadline2} />
            <div className={styles.link} />
          </div>
        </div>
      </div>
    );
  }
}

export default CinemaShowtimePlaceholder;
