import transit from "transit-immutable-js";
import omit from "lodash/omit";

const blacklistedKeys = ["modal", "tooltip", "notifications", "comments", "diggs", "cinemaCurrent", "advertising", "votes", "flags", "search"];
const storeKey = "redux-store";

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem(storeKey);
    if (serializedState === null) {
      return undefined;
    }
    return transit.fromJSON(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const filteredState = omit(state, blacklistedKeys);
    const serializedState = transit.toJSON(filteredState);
    sessionStorage.setItem(storeKey, serializedState);
  } catch (err) {
    // Ignore errors
  }
};
