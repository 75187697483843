import React from "react";
import Notifications from "react-notification-system-redux";

import Button from "../../buttons/Button";

import { buttons } from "../styles/Notifications.sass";

const defaults = {
  position: "tr",
  autoDismiss: 3,
};

export const showError = (options = {}) => {
  const { responseCode, ...params } = options;

  if (responseCode === 429) {
    params.message = params.message
      || "Da warst du etwas zu schnell für unser System. Probiere es in ein paar Minuten nochmal.";
  }

  if (responseCode === 460) {
    params.message = params.message
    /* eslint-disable max-len */
      || "Dein Account ist neu. Erst nach 24 Stunden wirst du Kommentare, Blog-Artikel und private Nachrichten veröffentlichen dürfen. Du kannst aber bereits Filme und Serien bewerten! Danke für deine Geduld!";
    params.autoDismiss = 15;
  }

  return (
    Notifications.error({
      ...defaults,
      message: "Ups! Leider ist ein Fehler aufgetreten. Versuche es später nochmal.",
      ...params,
    })
  );
};

export const showSuccess = (options = {}) => {
  const { link, linkCaption, ...rest } = options;
  const message = options.message || "Die Aktion wurde erfolgreich durchgeführt.";
  let children;

  if (link && linkCaption) {
    children = <Button href={link}>{linkCaption}</Button>;
    rest.autoDismiss = 6;
  }

  return Notifications.success(Object.assign(
    {},
    defaults,
    rest,
    { message, children },
  ));
};

export const showConfirm = ({ message, confirmText, dismissText, dispatch = () => {}, onConfirm = () => {}, onDismiss = () => {} }) => {
  let confirmUid;
  const children = (<div className={buttons}>
    <Button
      onClick={() => {
        dispatch(Notifications.hide(confirmUid));
        onDismiss();
      }}
    >{dismissText}</Button>
    <Button
      onClick={() => {
        dispatch(Notifications.hide(confirmUid));
        onConfirm();
      }}
      primary
    >{confirmText}</Button>
  </div>);
  return Notifications.info({
    position: "tr",
    dismissible: "none",
    autoDismiss: 0,
    onAdd: ({ uid }) => { confirmUid = uid; },
    message,
    children,
  });
};
