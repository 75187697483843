const hue2rgb = (p, q, t) => {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + ((q - p) * 6 * t);
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + ((q - p) * ((2 / 3) - t) * 6);
  return p;
};

const toHex = (x) => {
  const hex = Math.round(x * 255).toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const hslToHex = (h, s, l) => {
  h /= 360;
  s /= 100;
  l /= 100;

  let r;
  let g;
  let b;

  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : (l + s) - (l * s);
    const p = (2 * l) - q;
    r = hue2rgb(p, q, h + (1 / 3));
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - (1 / 3));
  }

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

export const usernameToColor = (username) => {
  const hue = (
    (username || "")
      .split("")
      .map(string => string.charCodeAt(0) % 100)
      .reduce((sum, currentValue) => sum + currentValue, 0)
  ) % 360;
  return hslToHex(hue, 100, 94);
};
