import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { List } from "immutable";
import classNames from "classnames/bind";

import Button from "../buttons/Button";
import Textarea from "../form/Textarea";
import Dropdown from "./Dropdown";

import { fetchLists, createList, addToList } from "./actions/userlistActions";
import styles from "./styles/AddItemToUserlist.sass";
import animation from "../form/styles/shakeAnimation.sass";

const cx = classNames.bind(styles);

export class AddItemToUserlist extends React.Component {
  constructor() {
    super();
    this.state = {
      errorList: false,
      isSaving: false,
      isFinished: false,
      shakeButton: false,
      loadingLists: true,
      description: "",
    };
  }

  componentDidMount() {
    this.props.fetchLists().then(() => this.setState({ loadingLists: false }));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.userId !== nextProps.userId) {
      this.setState({ loadingLists: true });
      this.props.fetchLists().then(() => this.setState({ loadingLists: false }));
    }
  }

  getDescRemaining(descriptionMaxLength) {
    const remaining = descriptionMaxLength - this.state.description.length;
    return remaining > 50 ? null : remaining;
  }

  selectList = (list) => {
    this.setState({ selectedList: list, errorList: false });
  }

  createList = () =>
    this.props.createList(this.state.selectedList.label)
      .catch((e) => {
        this.setState({ isSaving: false });
        throw e;
      })

  handleButtonClick = () => {
    if (this.state.selectedList) {
      this.setState({ isSaving: true });
      if (this.state.selectedList.isNew) {
        this.createList().then(
          ({ list }) => this.addToList(list.permalink),
        );
      } else {
        this.addToList(this.state.selectedList.value);
      }
    } else {
      this.setState({ errorList: true, shakeButton: true }, () =>
        setTimeout(() => this.setState({ shakeButton: false }), 400));
    }
  }

  addToList = listId =>
    this.props.addToList(listId, this.state.description)
      .then(() => {
        this.selectList(this.props.defaultList);
        this.setState({ isFinished: true, isSaving: false, description: "" });
        this.props.afterAddingToList();
      })
      .catch((e) => {
        this.setState({ isSaving: false });
        throw e;
      })

  render() {
    const itemForHumans = this.props.itemType === "Movie" ? "Film" : "Serie";
    const spokenItemType = this.props.itemType === "Movie" ? "der Film" : "die Serie";
    const labelContent = `Schreibe kurz, warum ${spokenItemType} auf diese Liste gehört:`;
    const descriptionMaxLength = 255;

    let button = <Button onClick={this.handleButtonClick} primary dark fullwidth>{itemForHumans} zur Liste hinzufügen</Button>;
    if (this.state.isSaving) button = <Button disabled fullwidth>Wird hinzugefügt...</Button>;
    if (this.state.isFinished) button = <div className={styles.message}>Element wurde zur Liste hinzugefügt.</div>;

    return (
      <div>
        <div className={styles.formRow}>
          <Dropdown
            autoBlur
            backspaceRemoves={false}
            clearable={false}
            deleteRemoves={false}
            hasError={this.state.errorList}
            inputProps={{ name: "list", id: "addToList-list" }}
            isLoading={this.state.loadingLists}
            loadingPlaceholder="Lade Listen ..."
            noResultsText="Du hast noch keine Listen angelegt."
            onChange={selectedList => this.selectList(selectedList)}
            options={this.props.lists}
            placeholder="Liste suchen oder erstellen ..."
            promptTextCreator={label => `Liste "${label}" erstellen`}
            value={this.state.selectedList}
          />
        </div>
        <div className={styles.formRow}>
          <Textarea
            style={{ padding: "9px 12px" }}
            className={styles.textarea}
            id="addToList-description"
            name="description"
            placeholder={labelContent}
            onChange={event => this.setState({ description: event.target.value })}
            maxLength={`${descriptionMaxLength}`}
            value={this.state.description}
          />
          <span className={cx("wordCountWarning")}>
            {this.getDescRemaining(descriptionMaxLength)}
          </span>
        </div>
        <div className={cx({ formRow: true, [animation.shake]: this.state.shakeButton })}>{button}</div>
      </div>
    );
  }
}

AddItemToUserlist.propTypes = {
  fetchLists: PropTypes.func,
  createList: PropTypes.func,
  addToList: PropTypes.func,
  afterAddingToList: PropTypes.func,
  lists: PropTypes.array,
  defaultList: PropTypes.object,
  userId: PropTypes.number,
  itemType: PropTypes.string,
};

AddItemToUserlist.defaultProps = {
  fetchLists: () => Promise.resolve(),
  createList: () => {},
  addToList: () => {},
  afterAddingToList: () => {},
  lists: [],
  defaultList: undefined,
  userId: null,
  itemType: "",
};

export const mapStateToProps = (state, { itemType, itemId, afterAddingToList }) => {
  const lists = state.userlists
    .getIn([itemType, itemId], List())
    .map(list => ({ value: list.get("id"), label: list.get("name"), disabled: list.get("item_on_list") }))
    .toJS();
  const availableLists = lists.filter(list => !list.disabled);

  return {
    defaultList: availableLists[0],
    lists,
    afterAddingToList,
    userId: state.session != null && state.session.get("id"),
  };
};

export const mapDispatchToProps = (dispatch, { itemType, itemId }) => ({
  fetchLists: () =>
    dispatch(fetchLists(itemType, itemId)),
  createList: listName =>
    dispatch(createList(itemType, itemId, listName)),
  addToList: (listId, description) =>
    dispatch(addToList(itemType, itemId, listId, description)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddItemToUserlist);
