import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import NotificationSystem from "react-notification-system-redux";
import styles from "./styles/Notifications.sass";

export class Notifications extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.notifications.length > 1) {
      nextProps.notifications.slice(0, -1).forEach(notification =>
        this.props.hideNotification(notification.uid));
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <NotificationSystem
          notifications={this.props.notifications}
          // This really needs to be a boolean:
          // eslint-disable-next-line react/style-prop-object
          style={false}
        />
      </div>
    );
  }
}

Notifications.propTypes = {
  notifications: PropTypes.array,
  hideNotification: PropTypes.func,
};

Notifications.defaultProps = {
  notifications: [],
  hideNotification: () => {},
};

export const mapStateToProps = state => (
  { notifications: state.notifications }
);

export const mapDispatchToProps = dispatch => ({
  hideNotification: (uid) => {
    dispatch(NotificationSystem.hide(uid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notifications);
