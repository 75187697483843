import styled from "styled-components";
import { error } from "../../styles/colors";

export const Error = styled.div`
  max-height: ${props => (props.active ? "50px" : "0")};
  margin-left: -12px;
  padding-left: 6px;
  overflow: hidden;
  transition: max-height .2s;
  border-left: 6px solid ${error};
  color: ${error};
  font-size: 13px;
  line-height: 19px;
`;

export const ErrorText = styled.div`
  padding: 6px 0;
`;
