import { load } from "hypernova";
import { Map } from "immutable";

import actionCreators from "../actions/cinemaCurrentActionCreators";
import cinemaReducer from "../reducers/cinemaCurrentReducer";

const { receivedItems, replacedFilters } = actionCreators;

const preloadCinemaCurrentStore = (store = {}) => {
  const instances = load("CinemaMoviesModule") || [];

  if (!instances.length) return store;
  const { items, nextPage, filters } = instances.pop().data || {};

  let cinemaCurrentStore = Map();
  cinemaCurrentStore = cinemaReducer(cinemaCurrentStore, replacedFilters(filters));
  cinemaCurrentStore = cinemaReducer(cinemaCurrentStore, receivedItems(items, nextPage));

  if (typeof store.cinema !== "object") store.cinema = {};
  store.cinema.current = (store.cinema.current || Map()).mergeDeep(cinemaCurrentStore);
  return store;
};

export default preloadCinemaCurrentStore;
