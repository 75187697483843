import styled, { css } from "styled-components";
import ButtonModule from "../../buttons/Button";
import InputModule from "../../form/Input";
import InputError from "../../form/InputError";
import { oswaldFont } from "../../styles/typography";

const marginBottom = css`
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  padding: 0 24px;
`;

export const Header = styled.div`
  ${oswaldFont({ weight: 600, spacing: 0.04 })}
  margin-bottom: 24px;
  font-size: 19px;
  line-height: 28px;
  text-transform: uppercase;
`;

export const Input = styled(InputModule)`
  ${marginBottom}
`;
export const Button = styled(ButtonModule)`
  ${marginBottom}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Error = styled(InputError)`
  margin-top: -6px;
  margin-bottom: 6px;
`;
