import styled, { css } from "styled-components";
import ButtonModule from "../../buttons/Button";
import CheckboxBig from "../../form/CheckboxBig";
import InputModule from "../../form/Input";
import InputError from "../../form/InputError";
import { linkStyle, oswaldFont } from "../../styles/typography";

export const marginBottom = css`
  margin-bottom: 12px;
`;

export const Wrapper = styled.div`
  padding: 24px;
`;

export const Error = styled(InputError)`
  margin-top: -6px;
  margin-bottom: 6px;
`;

export const Header = styled.div`
  ${oswaldFont({ weight: 600, spacing: 0.04 })}
  margin-bottom: 25px;
  font-size: 19px;
  line-height: 28px;
  text-transform: uppercase;
`;

export const Input = styled(InputModule)`
  ${marginBottom}
`;
export const Button = styled(ButtonModule)`
  ${marginBottom}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Checkbox = styled(CheckboxBig)`
  ${marginBottom};
`;

export const CheckboxLink = styled.a.attrs({ target: "_blank" })`
  ${linkStyle({})}
`;

export const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -30px 12px;
`;
