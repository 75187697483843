import { RECEIVED_VOTES, CREATED_VOTE, UPDATED_VOTE, DELETED_VOTE, CLEARED_VOTES_USER_DATA } from "../constants/votesConstants";

export const receivedVotes = votes => ({
  type: RECEIVED_VOTES,
  votes,
});

export const createdVote = (votableType, votableId, vote) => ({
  type: CREATED_VOTE,
  votableType,
  votableId,
  vote,
});

export const updatedVote = (votableType, votableId, vote) => ({
  type: UPDATED_VOTE,
  votableType,
  votableId,
  vote,
});

export const deletedVote = (votableType, votableId, vote) => ({
  type: DELETED_VOTE,
  votableType,
  votableId,
  vote,
});

export const clearedVotesUserData = () => ({
  type: CLEARED_VOTES_USER_DATA,
});
