import { Map, Set, List, fromJS } from "immutable";
import {
  CREATED_COMMENT, DELETED_COMMENT, FETCHED_COMMENTS, FETCHING_COMMENTS, RECEIVED_COMMENTS, UPDATED_COMMENT, FLAGGED_COMMENT,
  UNFLAGGED_COMMENT, FLAGGED_COMMENT_ANSWER, UNFLAGGED_COMMENT_ANSWER, CREATED_REPLY, DELETED_REPLY, UPDATED_REPLY,
  DIGGED_COMMENT, UNDIGGED_COMMENT, DIGGED_REPLY, UNDIGGED_REPLY, RECEIVED_MORE_COMMENTS, SAVING_COMMENT, SAVED_COMMENT,
  INCREMENT_COMMENTS_COUNT, DECREMENT_COMMENTS_COUNT, RESET_COMMENTS, RECEIVED_COMMENT,
} from "../constants/commentConstants";

const initialState = Map();

const commentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case FETCHING_COMMENTS:
    return state.updateIn(["fetching", action.commentableType], Set(), fetchingIds =>
      fetchingIds.add(action.commentableId),
    );

  case FETCHED_COMMENTS:
    return state.updateIn(["fetching", action.commentableType], Set(), commentableIds =>
      commentableIds.remove(action.commentableId),
    );

  case RESET_COMMENTS:
    return state.removeIn([action.commentableType, action.commentableId]);

  case SAVING_COMMENT:
    return state.updateIn(["saving"], Set(), savingIds => savingIds.add(action.commentId));

  case SAVED_COMMENT:
    return state.updateIn(["saving"], Set(), savingIds => savingIds.remove(action.commentId));

  case RECEIVED_COMMENTS:
    return state.setIn([action.commentableType, action.commentableId], fromJS(action.comments))
      .setIn(["morePages", action.commentableType, action.commentableId], !!action.morePages)
      .setIn(["commentsCount", action.commentableType, action.commentableId], parseInt(action.commentsCount, 10) || 0);

  case RECEIVED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments = List()) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      const comment = fromJS(action.comment);
      return (index !== -1 ? comments.set(index, comment) : comments.unshift(comment));
    });

  case RECEIVED_MORE_COMMENTS:
    return state.updateIn([action.commentableType, action.commentableId], List(), comments => (
      comments.concat(fromJS(action.comments)).groupBy(c => c.get("id")).map(c => c.last()).toList()
    ))
      .setIn(["morePages", action.commentableType, action.commentableId], !!action.morePages)
      .setIn(["commentsCount", action.commentableType, action.commentableId], parseInt(action.commentsCount, 10) || 0);

  case CREATED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments = List()) =>
      List(comments).unshift(fromJS(action.comment)),
    );

  case DELETED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments = List()) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      return (index !== -1 ? comments.delete(index) : comments);
    });

  case UPDATED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.comment.id));
      return (index !== -1 ? comments.set(index, fromJS(action.comment)) : comments);
    });

  case FLAGGED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      return (index !== -1 ?
        comments.update(index, comment => comment.merge({ is_flagged: action.isFlagged, flagged_id: action.flaggedId })) :
        comments
      );
    });

  case UNFLAGGED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      return (index !== -1 ?
        comments.update(index, comment => comment.delete("flagged_id").set("is_flagged", action.isFlagged)) :
        comments
      );
    });

  case FLAGGED_COMMENT_ANSWER:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.answerId));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies", answerIndex], reply => reply.merge({ is_flagged: action.isFlagged, flagged_id: action.flaggedId }));
    });

  case UNFLAGGED_COMMENT_ANSWER:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.answerId));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies", answerIndex], reply => reply.delete("flagged_id").set("is_flagged", action.isFlagged));
    });

  case CREATED_REPLY:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (index === -1) return comments;

      return comments.updateIn([index, "replies"], List(), replies => replies.push(fromJS(action.reply)));
    });

  case DELETED_REPLY:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.answerId));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies"], replies => replies.delete(answerIndex));
    });

  case UPDATED_REPLY:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.reply.id));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies"], replies => replies.set(answerIndex, fromJS(action.reply)));
    });

  case DIGGED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      return (index !== -1 ?
        comments.update(index, comment => comment.merge({
          diggs_count: comment.get("diggs_count") + 1,
          digged: action.dig,
        })) :
        comments
      );
    });

  case UNDIGGED_COMMENT:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const index = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      return (index !== -1 ?
        comments.update(index, comment => comment.merge({
          diggs_count: comment.get("diggs_count") - 1,
          digged: null,
        })) :
        comments
      );
    });

  case DIGGED_REPLY:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.answerId));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies", answerIndex], reply => reply.merge({
        diggs_count: reply.get("diggs_count") + 1,
        digged: action.dig,
      }));
    });

  case UNDIGGED_REPLY:
    return state.updateIn([action.commentableType, action.commentableId], (comments) => {
      const commentIndex = comments.findIndex(c => String(c.get("id")) === String(action.commentId));
      if (commentIndex === -1) return comments;

      const answerIndex = comments.getIn([commentIndex, "replies"]).findIndex(c => String(c.get("id")) === String(action.answerId));
      if (answerIndex === -1) return comments;

      return comments.updateIn([commentIndex, "replies", answerIndex], reply => reply.merge({
        diggs_count: reply.get("diggs_count") - 1,
        digged: null,
      }));
    });

  case INCREMENT_COMMENTS_COUNT:
    return state.updateIn(["commentsCount", action.commentableType, action.commentableId], 0, count => count + 1);

  case DECREMENT_COMMENTS_COUNT:
    return state.updateIn(["commentsCount", action.commentableType, action.commentableId], 1, count => count - 1);

  default:
    return state;
  }
};

export default commentReducer;
