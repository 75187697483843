import styled from "styled-components";
import ResponsiveImage from "../../assets/ResponsiveImage";
import { dark, darkGray, darkRgb, heroRgb } from "../../styles/colors";
import { oswaldFont } from "../../styles/typography";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 130px;
  background: ${darkGray};
`;

export const BackgroundImage = styled(ResponsiveImage)`
  width: 100%;
  height: auto;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(${heroRgb},.5) 0%, transparent 50%), linear-gradient(180deg, transparent 0%, rgba(${darkRgb},.18) 55%, ${dark} 100%);
  }
`;

export const Quote = styled.div`
  ${oswaldFont({ weight: 600, spacing: 0.04 })}
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #fff;
  font-size: 19px;
  text-transform: uppercase;
`;
