import styled, { css } from "styled-components";
import { darkGray, error, gray, hero, lightGray } from "../../styles/colors";

const size = 36;
const borderWidth = 1;

export const Wrapper = styled.div`
  display: flex;
  color: ${darkGray};
  font-size: 13px;
  line-height: 1.6;
`;

export const Label = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  min-height: ${size + (2 * borderWidth)}px;
  padding: 0 0 0 ${size + 12}px;
  cursor: pointer;
  user-select: none;

  &:before {
    top: 50%;
    left: 0;
    width: ${size}px;
    height: ${size}px;
    margin-top: ${-((size / 2) + borderWidth)}px;
    transition: .2s ease;
    transition-property: background, border-color;
    border: ${borderWidth}px solid ${lightGray};
    border-radius: 3px;
    background: #fff;
    text-align: center;
    ${props => props.error && css`
      border-color: ${error};
    `}
  }

  &:after {
    top: 50%;
    left: ${Math.round(size * 0.321)}px;
    width: ${Math.round(size * 0.357)}px;
    height: ${Math.round(size * 0.179)}px;
    margin-top: 0;
    transform: translateY(${Math.round(size * -0.179)}px) rotate(-45deg) scale(0);
    transform-origin: 50%;
    transition: transform .2s ease-out;
    border-width: 0 0 2px 2px;
    border-style: solid;
    border-color: ${lightGray};
    background: transparent;
    border-image: none;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    box-sizing: content-box;
  }
`;

export const Input = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;

  &:active + ${Label} {
    &:before {
      transition-duration: 0s;
    }
  }

  &:disabled + ${Label} {
    color: ${gray};
    cursor: default;

    &:before {
      border-color: ${gray};
    }
  }

  &:checked + ${Label} {
    &:before {
      animation: borderscale1 .2s ease-in;
      border-color: ${hero};
      background: ${hero};
    }
    &:after {
      content: "";
      transform: translateY(${Math.round(size * -0.179)}px) rotate(-45deg) scale(1);
      transition: transform .2s ease-out;
      border-color: #fff;
    }
  }

  &:disabled:focus,
  &:disabled:hover {
    & + ${Label} {
      &:before {
        border-color: ${gray};
        background: #fff;
      }
    }
  }

  &:disabled:checked + ${Label} {
    &:before {
      border-color: ${gray};
      background: ${gray};
    }
  }
`;
