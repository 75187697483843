import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 100 100">
    <path fill={color} d="M70 50l27-28c4-3 4-9 0-13l-6-6c-4-4-10-4-13 0L50 30 22 3c-3-4-9-4-13 0L3 9c-4 4-4 10 0 13l27 28L3 78c-4 3-4 9 0 13l6 6c4 4 10 4 13 0l28-27 28 27c3 4 9 4 13 0l6-6c4-4 4-10 0-13L70 50z" />
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

CloseIcon.defaultProps = {
  color: "#000",
  size: 12,
};

export default CloseIcon;
