import styled, { css } from "styled-components";
import { hero, dark } from "../../styles/colors";
import { tablet } from "../../styles/mediaQueries";
import { liReset, ulReset } from "../../styles/mixins";
import { oswaldFont, notoSansFont } from "../../styles/typography";

export const Wrapper = styled.div`
  width: 50px;
  height: 50px;
`;

export const Overlay = styled.ul`
  ${ulReset}
  display: flex;
  position: fixed;
  top: ${props => (props.visible ? "50px" : "-100%")};
  left: 0;
  flex-direction: column;
  width: 100%;
  max-height: calc(100% - 50px);
  padding: 13.5px 36px 80px;
  overflow: auto;
  transition: opacity .3s, top .3s ${props => (props.visible ? "step-start" : "step-end")};
  opacity: ${props => (props.visible ? 1 : 0)};
  background: #fff;
  -webkit-overflow-scrolling: touch;

  ${tablet(css`
    flex-direction: row;
    flex-wrap: wrap;
    box-shadow: 0 0 3px 0 rgba(20, 20, 20, .3);
  `)}
`;

export const Triangle = styled.div`
  position: fixed;
  top: 43px;
  left: 18px;
  transition: opacity .3s;
  border-width: 0 7px 7px 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export const Headline = styled.a`
  ${oswaldFont({ weight: 500 })}
  display: inline-block;
  padding: 10.5px 0;
  color: ${hero};
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const LinksWrapper = styled.ul`
  ${ulReset}
  display: flex;
  flex-wrap: wrap;
`;

export const LinkWrapper = styled.li`
  flex: 0 0 50%;
  width: 50%;
  ${liReset}
`;

export const Link = styled.a`
  ${notoSansFont}
  display: inline-block;
  padding: 7.5px 0;
  color: ${dark};
  font-size: 15px;
  line-height: 21px;
  text-decoration: none;
`;

export const Section = styled.li`
  width: 100%;
  padding-bottom: 6px;
  ${liReset}

  ${tablet(css`
    width: 50%;
  `)}
`;
