import React from "react";

import asIcon from "./asIcon";

export default asIcon(({ color }) => (
  <g fill={color}>
    <path d="M66.3 31.3v-12a6 6 0 0 0-6-6h-33a6 6 0 0 0-6 6v61.4a6 6 0 0 0 6 6h33a6 6 0 0 0 6-6v-12h-7v9a2 2 0 0 1-2 2h-27a2 2 0 0 1-2-2V22.3a2 2 0 0 1 2-2h27a2 2 0 0 1 2 2v9z" />
    <path d="M69.5 39.3v6.1a.9.9 0 0 1-.9.9H40.3a3.5 3.5 0 0 0-3.5 3.7 3.5 3.5 0 0 0 3.5 3.5h28.2a.9.9 0 0 1 .9.9v6.3a3.5 3.5 0 0 0 5.9 2.6L86 52.7a3.5 3.5 0 0 0 .1-5L75.5 36.9a3.5 3.5 0 0 0-6 2.4z" />
  </g>
));
