import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map, Set } from "immutable";
import classNames from "classnames/bind";

import PrimaryIconButton from "../../buttons/PrimaryIconButton";
import { fetchCinemaShowtime } from "./actions/cinemaShowtimeActions";
import CinemaShowtimePlaceholder from "./CinemaShowtimePlaceholder";
import Poster from "../../assets/Poster";
import CalendarIcon from "../../icons/CalendarIcon";
import WhatsappIcon from "../../icons/WhatsappIcon";
import { toUrlParams } from "../../helper/urlHelpers";
import { autotrackParams } from "../../tracking/tracking";
import ticketIcon from "../assets/ticket_icon.svg";
import styles from "./styles/CinemaShowtime.sass";

const cx = classNames.bind(styles);

export class CinemaShowtime extends React.PureComponent {
  componentDidMount() {
    if (!this.props.fetched) this.props.fetchCinemaShowtime();
  }

  whatsappShareUrl = () => {
    const { movieTitle, time, cinemaName, dateRelative, movieUrl } = this.props.showtime;
    return `whatsapp://send${toUrlParams({ text: `${dateRelative} läuft "${movieTitle}" um ${time} Uhr im ${cinemaName}.\n${movieUrl}` })}`;
  }

  render() {
    const {
      date, time, cinemaName, ticketUrl, cinemaUrl, version, movieTitle, moviePosterId, moviePosterFilename, calendarUrl, additionalFeatures,
    } = this.props.showtime;

    if (this.props.isFetching) {
      return <CinemaShowtimePlaceholder />;
    }

    return (
      <div className={cx("wrapper")}>
        <div className={cx("title")}>{`${movieTitle}, ${version}`}</div>
        <div className={cx("posterMetaWrapper")}>
          <div className={cx("poster")}>
            <Poster id={moviePosterId} filename={moviePosterFilename} widths={[116, 174]} sizes="(min-width: 768px) 174px, 116px" />
          </div>
          <div className={cx("meta")}>
            <div className={cx("metaHeadline")}>Datum:</div>
            {date}
            <div className={cx("metaHeadline")}>Uhrzeit:</div>
            {time}
            <div className={cx("metaHeadline")}>Kino:</div>
            {cinemaName}
            {additionalFeatures && [
              <div key="headline" className={cx("metaHeadline")}>Freu dich auf:</div>,
              additionalFeatures.join(", "),
            ]}
          </div>
        </div>
        <div className={cx("share")}>
          <i className={cx("shareHeadline")}>Plane deinen Kinobesuch:</i>
          <div className={cx("buttons")}>
            <a
              className={cx("button")}
              role="button"
              href={calendarUrl}
              {...autotrackParams({ category: "Cinema Programm", action: "Social Share", label: "Calendar" })}
            >
              <CalendarIcon className={cx("buttonIcon")} size={30} />
              Zum Kalender hinzufügen
            </a>
            <a
              className={cx("button", "whatsapp")}
              role="button"
              href={this.whatsappShareUrl()}
              {...autotrackParams({ category: "Cinema Programm", action: "Social Share", label: "WhatsApp" })}
            >
              <WhatsappIcon className={cx("buttonIcon")} size={30} />
              In WhatsApp teilen
            </a>
          </div>
          {(ticketUrl || cinemaUrl) && [
            <i key="linkHeadline" className={cx("linkHeadline")}>Kaufe jetzt dein Ticket direkt auf der Kinoseite:</i>,
            <PrimaryIconButton
              url={ticketUrl || cinemaUrl}
              iconSrc={ticketIcon}
              tracking={{ category: "Cinema Programm", action: "Cinema Website" }}
              rel="noopener noreferrer nofollow"
              key="linkButton"
            >
              Tickets kaufen
            </PrimaryIconButton>,
          ]}
        </div>
      </div>
    );
  }
}

CinemaShowtime.propTypes = {
  fetched: PropTypes.bool,
  showtime: PropTypes.object,
  isFetching: PropTypes.bool,
  fetchCinemaShowtime: PropTypes.func,
};

CinemaShowtime.defaultProps = {
  fetched: false,
  showtime: {},
  isFetching: false,
  fetchCinemaShowtime: () => {},
};

const mapStateToProps = (state, { showtimeId }) => ({
  fetched: state.cinema.showtime.has(String(showtimeId)),
  showtime: state.cinema.showtime.get(String(showtimeId), Map()).toJS(),
  isFetching: state.cinema.showtime.get("fetching", Set()).has(String(showtimeId)),
});

const mapDispatchToProps = (dispatch, { showtimeId }) => ({
  fetchCinemaShowtime: () => dispatch(fetchCinemaShowtime(showtimeId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CinemaShowtime);
