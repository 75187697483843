export default (vendorId, callback) => {
  window.didomiOnReady = window.didomiOnReady || [];
  window.didomiOnReady.push((Didomi) => {
    Didomi.getObservableOnUserConsentStatusForVendor(vendorId)
      .filter(status => status === true) // Filter out updates where status is not true
      .first() // Only get the first consent status update
      .subscribe(() => {
        // The user has given consent to the vendor
        callback();
      });
  });
};
