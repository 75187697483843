
import React from "react";
import { trackEvent } from "../tracking/tracking";
import SignupForm from "./SignupForm";
import SignupSuccess from "./SignupSuccess";

class SignupModule extends React.Component {
  state = { page: "signup" }

  showSuccess = () => {
    this.setState({ page: "success" });
    trackEvent("Sign-Up", "Signup Complete");
  }

  render() {
    const { page } = this.state;

    if (page === "success") { return <SignupSuccess />; }

    return (<SignupForm onSuccess={this.showSuccess} />);
  }
}

export default SignupModule;
