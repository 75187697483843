import PropTypes from "prop-types";
import React from "react";

import { LineTop, LineMiddle, LineBottom, SVG } from "./styles/HamburgerIcon.styles";

const HamburgerIcon = ({ active, color, size, ...props }) => (
  <SVG viewBox="0 0 100 100" active={active} width={size} height={size} {...props} >
    <LineTop color={color} active={active} d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
    <LineMiddle color={color} active={active} d="m 30,50 h 40" />
    <LineBottom color={color} active={active} d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
  </SVG>
);

HamburgerIcon.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.number.isRequired,
};

HamburgerIcon.defaultProps = {
  active: false,
  color: "#000",
};

export default HamburgerIcon;
