import styled, { css } from "styled-components";
import ButtonModule from "../../buttons/Button";
import PrimaryIconButton from "../../buttons/PrimaryIconButton";
import InputModule from "../../form/Input";
import InputError from "../../form/InputError";
import { notoSerifFont } from "../../styles/typography";
import DisplayOnConsent from "../../vendor/DisplayOnConsent";

export const marginBottom = css`
  margin-bottom: 12px;
`;

export const PrimaryButton = styled(PrimaryIconButton)`
  margin: 24px 0;
`;

export const LoginItems = styled.div`
  padding: 0 24px;
`;

export const Headline = styled.div`
  ${notoSerifFont}
  ${marginBottom}
  font-size: 15px;
  font-style: italic;
  line-height: 1.6;
`;

export const Input = styled(InputModule)`
  ${marginBottom}
`;

export const Button = styled(ButtonModule)`
  ${marginBottom}
`;

export const Form = styled.form`
  position: relative;
`;

export const Error = styled(InputError)`
  margin-top: -6px;
  margin-bottom: 6px;
`;

export const RedirectingMessage = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
  font-style: italic;
`;

export const StyledDisplayOnConsent = styled(DisplayOnConsent)`
  margin: 12px -24px;
`;
