import React from "react";
import PropTypes from "prop-types";

import Button from "../buttons/Button";
import { autotrackParams } from "../tracking/tracking";

const TrackingButton = ({ tracking, url, children, wrapperClassName, ...props }) => (
  <Button
    attributes={autotrackParams(tracking)}
    href={url}
    wrapper={wrapperClassName}
    {...props}
  >
    {children}
  </Button>
);


TrackingButton.propTypes = {
  children: PropTypes.any,
  wrapperClassName: PropTypes.string,
  tracking: PropTypes.object,
  url: PropTypes.string,
};

TrackingButton.defaultProps = {
  children: null,
  wrapperClassName: null,
  tracking: {},
  url: null,
};

export default TrackingButton;
