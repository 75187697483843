import styled, { css } from "styled-components";
import { hero } from "../../styles/colors";
import { desktop, tablet, mobileOrTablet } from "../../styles/mediaQueries";
import { buttonReset } from "../../styles/mixins";
import { oswaldFont } from "../../styles/typography";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  line-height: 0;
`;

export const AvatarWrapper = styled.button`
  ${buttonReset}
  width: 50px;
  height: 50px;
  padding: ${props => (props.hasAvatar ? "4px" : "12px")};
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  padding: 12px;
  overflow: auto;
  background: #fff;
  -webkit-overflow-scrolling: touch;
  line-height: 1.4;

  ${tablet(css`
    height: auto;
    max-height: calc(100% - 50px);
    box-shadow: 0 0 3px 0 rgba(20, 20, 20, .3);
  `)}

  ${desktop(css`
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    width: 350px;
    height: auto;
    max-height: none;
  `)}
`;

export const Triangle = styled.div`
  position: fixed;
  top: 43px;
  right: 18px;
  border-width: 0 7px 7px 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;

  ${desktop(css`
    position: absolute;
    top: 100%;
    transform: translateY(-100%);
  `)}
`;

export const Badge = styled.div`
  ${oswaldFont({})}
  display: flex;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transform: translate(-18px, -6px);
  border-radius: 50%;
  background: ${hero};
  color: #fff;
  font-size: 13px;

  ${mobileOrTablet(css`
    top: 8px;
  `)}
`;
