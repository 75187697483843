import React from "react";
import sample from "lodash/sample";
import CardLabel from "../shared/CardLabel";
import { BackgroundImage, ImageWrapper, Quote, Wrapper } from "./styles/LoginTeaser.styles";

const { quote, image: imageFilename } = sample([
  { quote: "Das ist unser Königreich. Alles, was das Licht berührt.", image: "login_lion_king.jpg" },
  { quote: "Ob Sommer oder Winter naht: Du bist jederzeit willkommen.", image: "login_got_winter.jpg" },
]);

const imageid = `moviepilot/session/${imageFilename}`;

const LoginTeaser = () => (
  <Wrapper>
    <ImageWrapper>
      <BackgroundImage
        aspectRatio={2.232}
        filename={imageFilename}
        id={imageid}
        sizes="(min-width: 1024px) 400px, (min-width: 768px) 300px, calc(100vw - 24px)"
        widths={[336, 351, 800]}
      />

    </ImageWrapper>
    <Quote>“{quote}”</Quote>
    <CardLabel>Anmelden</CardLabel>
  </Wrapper>
);

export default LoginTeaser;
