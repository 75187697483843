/* global ASSET_SERVER_URL */

import uniq from "lodash/uniq";
import compact from "lodash/compact";
import escapeRegExp from "lodash/escapeRegExp";

export const assetUrl = (id, filename, params = []) => {
  if (id && id.match(new RegExp(`${escapeRegExp(filename)}$`)) && !params.length) filename = null;
  return `${ASSET_SERVER_URL}/files/${compact([id, ...params, encodeURIComponent(filename || "")]).join("/")}`;
};

export const getHeight = (width, aspectRatio = 1) =>
  parseInt(Math.ceil(parseFloat(width) / parseFloat(aspectRatio)), 10);

export const getSrcSet = (widths, aspectRatio, id, filename) => {
  const retinaWidths = widths.map(w => 2 * w);
  return uniq(widths.concat(retinaWidths)).map(width =>
    `${assetUrl(id, filename, ["fill", width, getHeight(width, aspectRatio)])} ${width}w`).join(",");
};
