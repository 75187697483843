export default (props) => {
  const {
    FETCHING_ITEMS, FETCHED_ITEMS, RECEIVED_ITEMS, RECEIVED_MORE_ITEMS,
    UPDATED_FILTERS, REPLACED_FILTERS, FETCHING_MORE_ITEMS, FETCHED_MORE_ITEMS,
  } = props;

  return {
    fetchingItems: () => ({
      type: FETCHING_ITEMS,
    }),

    fetchedItems: () => ({
      type: FETCHED_ITEMS,
    }),

    receivedItems: (items, nextPage) => ({
      type: RECEIVED_ITEMS,
      items,
      nextPage,
    }),

    fetchingMoreItems: () => ({
      type: FETCHING_MORE_ITEMS,
    }),

    fetchedMoreItems: () => ({
      type: FETCHED_MORE_ITEMS,
    }),

    receivedMoreItems: (items, nextPage) => ({
      type: RECEIVED_MORE_ITEMS,
      items,
      nextPage,
    }),

    updatedFilters: filters => ({
      type: UPDATED_FILTERS,
      filters,
    }),

    replacedFilters: filters => ({
      type: REPLACED_FILTERS,
      filters,
    }),
  };
};
