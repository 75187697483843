/* eslint-disable max-len */
export const errorCodes = {
  DM001: "No video has been specified, you need to specify one.",
  DM002: "Content has been deleted.",
  DM003: "Live content is not available, i.e. it may not have started yet.",
  DM004: "Copyrighted content, access forbidden.",
  DM005: "Content rejected (this video may have been removed due to a breach of the terms of use, a copyright claim or an infringement upon third party rights).",
  DM006: "Publishing in progress...",
  DM007: "Video geo-restricted by its owner.",
  DM008: "Explicit content.",
  DM009: "Explicit content (offsite embed).",
  DM010: "Private content.",
  DM011: "An encoding error occured.",
  DM012: "Encoding in progress...",
  DM013: "This video has no preset (no video stream).",
  DM014: "This video has not been made available on your device by its owner.",
  DM015: "Kids host error.",
  DM016: "Content not available on this website, it can only be watched on Dailymotion.",
};
