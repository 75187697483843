import styled, { css } from "styled-components";
import { desktop } from "../../styles/mediaQueries";

const transitionDuration = "0.2s";
const transitionTiming = "ease-in-out";

export const Trigger = styled.button`
  position: absolute;
  z-index: ${props => (props.isHidden ? -1 : 10)};
  top: 0;
  right: 0;
  width: 50px;
  height: 100%;
  transition: opacity ${transitionDuration} ${transitionTiming};
  border: 0;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  background-color: transparent;
  cursor: pointer;

  ${desktop(css`
    display: none;
  `)}

  &:focus {
    outline: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
