import { load } from "hypernova";
import { Map } from "immutable";

import actionCreators from "../actions/cinemaUpcomingActionCreators";
import cinemaReducer from "../reducers/cinemaUpcomingReducer";

const { receivedItems, replacedFilters } = actionCreators;

const preloadCinemaUpcomingStore = (store = {}) => {
  const instances = load("CinemaUpcomingMoviesModule") || [];

  if (!instances.length) return store;
  const { items, filters, nextPage } = instances.pop().data || {};

  let cinemaUpcomingStore = Map();
  cinemaUpcomingStore = cinemaReducer(cinemaUpcomingStore, replacedFilters(filters));
  cinemaUpcomingStore = cinemaReducer(cinemaUpcomingStore, receivedItems(items, nextPage));

  if (typeof store.cinema !== "object") store.cinema = {};
  store.cinema.upcoming = (store.cinema.upcoming || Map()).mergeDeep(cinemaUpcomingStore);
  return store;
};

export default preloadCinemaUpcomingStore;
