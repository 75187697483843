import { Map, Set, fromJS } from "immutable";
import {
  FETCHING_WATCHED, FETCHED_WATCHED, RECEIVED_WATCHED, CREATED_WATCHED, DELETED_WATCHED,
} from "../constants/watchedConstants";

const initialState = Map();

const watchedReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case FETCHING_WATCHED:
    return state.updateIn(["fetching", action.fetchableType], fetchableIds =>
      Set(fetchableIds).add(action.fetchableId),
    );

  case FETCHED_WATCHED: {
    let newState = state.updateIn(["fetching", action.fetchableType], fetchableIds =>
        Set(fetchableIds).remove(action.fetchableId),
      );
    if (action.success) {
      newState = newState.setIn(["fetched", action.fetchableType, action.fetchableId], Date.now());
    }
    return newState;
  }

  case RECEIVED_WATCHED:
    return state.updateIn(["watched"], watched =>
      fromJS(action.watched).map((watchedIds, watchedType) =>
        Set(Map(watched).get(watchedType)).union(watchedIds),
      ),
    );

  case CREATED_WATCHED:
    return state.updateIn(["watched", action.watchableType], watchedIds =>
      Set(watchedIds).union(action.watchableIds),
    );

  case DELETED_WATCHED:
    return state.updateIn(["watched", action.watchableType], watchedIds =>
      Set(watchedIds).subtract(action.watchableIds),
    );

  default:
    return state;
  }
};

export default watchedReducer;
