import styled, { css } from "styled-components";
import { mobile } from "../../styles/mediaQueries";
import { dark, hero, heroUltraLight } from "../../styles/colors";
import { oswaldFont, notoSansFont } from "../../styles/typography";

export const SearchbarPreviewItemWrapper = styled.a`
  display: flex;
  flex-direction: row;
  padding: 9.5px 12px;
  ${props => props.isFocused && css`
    background-color: ${heroUltraLight};
  `}

  ${mobile(css`
    &:last-of-type {
      margin-bottom: 19px;
    }
  `)}

  &:hover,
  &:focus {
    outline: 0;
    background-color: ${heroUltraLight};
    text-decoration: none;
  }
`;

export const TextContent = styled.div`
  margin-left: 12px;
`;

export const ResultDisplayType = styled.div`
  color: ${hero};
  font-family: ${oswaldFont};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .52px;
  line-height: 12px;
  text-transform: uppercase;
`;

export const ResultTitle = styled.div`
  margin-top: 4px;
  color: ${dark};
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;

  && {
    font-family: ${oswaldFont};
  }
`;

export const ResultInfo = styled.div`
  margin-top: 3px;
  color: ${dark};
  font-family: ${notoSansFont};
  font-size: 12px;
  line-height: 17px;
`;

export const PosterWrapper = styled.div`
  flex-basis: 74px;
  flex-shrink: 0;

  > div {
    ${props => props.itemClass === "Cinema" && "box-shadow: 0 1px 3px 0 hsla(0,0%,8%,.2)"};
  }
`;
