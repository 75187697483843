import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { Lazy } from "react-lazy";

import { assetUrl } from "./assetsHelper";
import styles from "./styles/lazyImage.sass";

const cx = classNames.bind(styles);

class RetinaImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      started: false,
    };
  }

  onError = () => {
    this.setState({ loaded: true, error: true });
  }

  onLoad = () => {
    this.setState({ loaded: true, error: false });
  }

  render() {
    const { id, fromRemote, src: remoteSrc, filename, width, height, lazy, placeholder, className, ...attributes } = this.props;
    const src = fromRemote ? remoteSrc : assetUrl(id, filename, ["fill", width, height]);
    const srcset = fromRemote ? remoteSrc : `${assetUrl(id, filename, ["fill", width * 2, height * 2])} 2x`;

    const image = this.state.error ?
      placeholder
      :
      (<img
        src={src}
        srcSet={srcset}
        width={width}
        height={height}
        onError={lazy && placeholder && this.onError ? this.onError : undefined}
        onLoad={lazy && this.onLoad ? this.onLoad : undefined}
        className={cx(className, (lazy && { image: this.state.started, loaded: this.state.loaded }))}
        alt=""
        {...attributes}
      />);

    return lazy ?
      <Lazy component="span" cushion={200} onViewport={() => this.setState({ started: true })}>
        {image}
      </Lazy>
      :
      image;
  }
}

RetinaImage.propTypes = {
  id: PropTypes.string.isRequired,
  filename: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  lazy: PropTypes.bool,
  className: PropTypes.any,
  placeholder: PropTypes.any,
  fromRemote: PropTypes.bool,
  src: PropTypes.string,
};

RetinaImage.defaultProps = {
  filename: undefined,
  lazy: false,
  className: null,
  placeholder: null,
  fromRemote: false,
  src: null,
};

export default RetinaImage;
