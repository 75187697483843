import styled, { css } from "styled-components";
import { error, lightGray, gray } from "../../styles/colors";
import { notoSerifFont } from "../../styles/typography";
import { buttonReset } from "../../styles/mixins";
import SearchbarClearIcon from "../../icons/SearchbarClearIcon";
import { desktop } from "../../styles/mediaQueries";

export const Wrapper = styled.div`
  position: relative;
`;

export const Input = styled.input`
  ${notoSerifFont}
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  transition: .2s;
  transition-property: border-color, box-shadow;
  border: 1px solid ${lightGray};
  border-radius: 3px;
  background: #fff;
  box-shadow: inset 2px 2px 1px 0 rgba(${props => (
    props.error ? "196, 72, 72" : "194, 194, 194"
  )}, .27);
  font-size: 15px;
  line-height: 38px;

  ${props => props.error && css`
    border-color: ${error};
  `}

  &::placeholder {
    color: ${gray};
  }

  ${props => props.hasClearButton && css`
    padding-right: 38px;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width : 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  `}

  ${props => props.hasIcon && css`
    padding-left: 38px;
  `}
`;

export const ClearButton = styled.button`
  ${buttonReset}
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const StyledClearIcon = styled(SearchbarClearIcon)`
  ${desktop(css`
    width: 20px;
    height: 20px;
  `)}
`;
