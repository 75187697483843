import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import TrackingButton from "./TrackingButton";
import styles from "./styles/PrimaryIconButton.sass";

const cx = classNames.bind(styles);

const PrimaryIconButton = ({ className, isFacebook, children, url, tracking, icon: Icon, iconSrc, ...props }) => (
  <TrackingButton
    className={cx("button", className, { isFacebook })}
    url={url}
    target={url && "_blank"}
    rel={url && "nofollow"}
    tracking={tracking}
    primary
    {...props}
  >
    <div className={cx("title")}>
      {children}
    </div>
    <div className={cx("iconWrapper")}>
      {iconSrc && <img src={iconSrc} alt="" width="30" height="30" />}
      {Icon && <Icon size={30} color="#fff" />}
    </div>
  </TrackingButton>
);

PrimaryIconButton.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
  tracking: PropTypes.any,
  icon: PropTypes.any,
  iconSrc: PropTypes.string,
  isFacebook: PropTypes.bool,
  className: PropTypes.string,
};

PrimaryIconButton.defaultProps = {
  url: null,
  children: null,
  tracking: {},
  icon: null,
  iconSrc: null,
  isFacebook: false,
  className: null,
};

export default PrimaryIconButton;
