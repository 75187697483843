import axios from "axios";

import { showError } from "../../../notifications/actions/notificationActionCreators";

import { fetchingCinemaShowtime, fetchedCinemaShowtime, receivedCinemaShowtime } from "./cinemaShowtimeActionCreators";

export const apiCinemaShowtimeUrl = id =>
  `/api/cinema_showtimes/${id}`;

export const fetchCinemaShowtime = showtimeId => (
  (dispatch) => {
    dispatch(fetchingCinemaShowtime(showtimeId));

    return axios.get(apiCinemaShowtimeUrl(showtimeId))
      .then(({ data }) => {
        dispatch(receivedCinemaShowtime(showtimeId, data));
        dispatch(fetchedCinemaShowtime(showtimeId));
      })
      .catch((e) => {
        dispatch(fetchedCinemaShowtime(showtimeId));
        dispatch(showError());
        throw e;
      });
  }
);
