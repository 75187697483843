import { Map, List, fromJS } from "immutable";
import {
  RECEIVED_USER_LISTS, CREATED_USER_LIST, ADDED_TO_USER_LIST,
} from "../constants/userlistConstants";

const initialState = Map();

const userlistReducer = (state = initialState, action = {}) => {
  const path = [action.itemType, action.itemId];

  switch (action.type) {
  case RECEIVED_USER_LISTS:
    return state.setIn(path, fromJS(action.lists));

  case CREATED_USER_LIST:
    return state.updateIn(path, List(), lists => lists.unshift(fromJS(action.list)));

  case ADDED_TO_USER_LIST:
    return state.updateIn(path, List(), lists =>
      lists.map(list => (list.get("id") === action.listId ? list.set("item_on_list", true) : list),
    ));

  default:
    return state;
  }
};

export default userlistReducer;
