import PropTypes from "prop-types";
import React from "react";
import { Wrapper } from "./styles/FlashMessage.styles";

const FlashMessage = ({ message }) => (
  <Wrapper>{message}</Wrapper>
);

FlashMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default FlashMessage;
