import { RECEIVED_TRIVIAS, RECEIVED_MORE_TRIVIAS, FETCHING_TRIVIAS, FETCHING_MORE_TRIVIAS, CREATED_TRIVIA, UPDATED_TRIVIA, DELETED_TRIVIA, UPDATED_CATEGORIES, UPDATING_TRIVIA } from "../constants/triviasConstants";

export const receivedTrivias = (trivializableType, trivializableId, items, lastPage) => ({
  type: RECEIVED_TRIVIAS,
  trivializableType,
  trivializableId,
  items,
  lastPage,
});

export const receivedMoreTrivias = (trivializableType, trivializableId, items, lastPage) => ({
  type: RECEIVED_MORE_TRIVIAS,
  trivializableType,
  trivializableId,
  items,
  lastPage,
});

export const fetchingTrivias = (trivializableType, trivializableId) => ({
  type: FETCHING_TRIVIAS,
  trivializableType,
  trivializableId,
});

export const fetchingMoreTrivias = (trivializableType, trivializableId) => ({
  type: FETCHING_MORE_TRIVIAS,
  trivializableType,
  trivializableId,
});

export const createdTrivia = trivia => ({
  type: CREATED_TRIVIA,
  trivia,
});

export const updatedTrivia = trivia => ({
  type: UPDATED_TRIVIA,
  trivia,
});

export const deletedTrivia = (trivializableType, trivializableId, id) => ({
  type: DELETED_TRIVIA,
  trivializableType,
  trivializableId,
  id,
});

export const updatedCategories = (trivializableType, trivializableId, categories) => ({
  type: UPDATED_CATEGORIES,
  trivializableType,
  trivializableId,
  categories,
});

export const updatingTrivia = (trivializableType, trivializableId, isUpdating) => ({
  type: UPDATING_TRIVIA,
  trivializableType,
  trivializableId,
  isUpdating,
});
