import styled, { css } from "styled-components";

const transitionTime = ".4s";

const line = css`
  transition: stroke-dashoffset ${transitionTime};
  stroke-width: 5.5;
  stroke-linecap: round;
  stroke: ${props => props.color};
  fill: none;
`;

export const LineTop = styled.path`
  ${line}
  stroke-dasharray: 40 160;
  stroke-dashoffset: ${props => (props.active ? -64 : 0)}px;
`;

export const LineMiddle = styled.path`
  ${line}
  transform-origin: 50%;
  transition: transform ${transitionTime};
  stroke-dasharray: 40 142;
  ${props => props.active && css`
    transform: rotate(90deg);
  `}
`;

export const LineBottom = styled.path`
  ${line}
  transform-origin: 50%;
  stroke-dasharray: 40 85;
  stroke-dashoffset: ${props => (props.active ? -64 : 0)}px;
`;

export const SVG = styled.svg`
  transition: transform ${transitionTime};
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${props => props.active && css`
    transform: rotate(45deg);
  `}
`;
