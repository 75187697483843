import { load } from "hypernova";
import { Map } from "immutable";

import actionCreators from "../actions/dvdActionCreators";
import consumptionReducer from "../reducers/dvdReducer";

const { receivedItems, replacedFilters } = actionCreators;

const preloadDvdStore = (store = {}) => {
  const instances = load("DvdModule") || [];

  if (!instances.length) return store;
  const { items, filters, nextPage } = instances.pop().data || {};

  let dvdStore = Map();
  dvdStore = consumptionReducer(dvdStore, replacedFilters(filters));
  dvdStore = consumptionReducer(dvdStore, receivedItems(items, nextPage));

  store.dvd = (store.dvd || Map()).mergeDeep(dvdStore);
  return store;
};

export default preloadDvdStore;
