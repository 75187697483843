import { trackEvent } from "../tracking/tracking";

const getDataLayer = () => {
  if (typeof window !== "undefined") {
    return window.dataLayer[0];
  }
  return "";
};

const getId = dataLayer => dataLayer[Object.keys(dataLayer).find(item => item.includes("_id"))];

const handleDimension47 = (playerTriggerInfo) => {
  const { clickToLoad, autoplay } = playerTriggerInfo;
  if (clickToLoad || !autoplay) {
    return "manually clicked";
  } else if (!clickToLoad && autoplay) {
    return "autoplay-on-scroll";
  }
  return "manually clicked";
};

const createCustomDimensions = (dataLayer, playerTrackingParams = {}) => {
  const customParams = {
    dimension38: dataLayer.site_route,
    dimension39: dataLayer.current_url,
    dimension40: getId(dataLayer),
    dimension41: dataLayer.site_section,
  };

  if (playerTrackingParams.pageEnvironment !== undefined) {
    customParams.dimension42 = playerTrackingParams.pageEnvironment;
  }

  if (playerTrackingParams.playerTriggerInfo !== undefined) {
    customParams.dimension47 = handleDimension47(playerTrackingParams.playerTriggerInfo);
  }

  return customParams;
};

export const trackPlayerEventWithCustomDimensions = playerTrackingParams => (
  (action, label) => trackEvent(playerTrackingParams.category, action, label, "", createCustomDimensions(getDataLayer(), playerTrackingParams))
);
