import React from "react";
import { trackEvent } from "../tracking/tracking";
import LoginModule from "./LoginModule";
import SignupForm from "./SignupForm";
import SignupSuccess from "./SignupSuccess";

class LoginSignupModule extends React.Component {
  state = {
    active: "login",
  }

  showRegister = () => {
    this.setState({ active: "register" });
    trackEvent("Sign-Up", "Click: Sign Up Now");
  }
  showLogin = () => { this.setState({ active: "login" }); }
  showSignupSuccess = () => { this.setState({ active: "signupSuccess" }); }

  render() {
    const { active } = this.state;
    switch (active) {
    case "register":
      return <SignupForm onSuccess={this.showSignupSuccess} onReturn={this.showLogin} showReturn />;

    case "signupSuccess":
      return <SignupSuccess />;

    default:
      return (
        <LoginModule onRegister={this.showRegister} showRegister />
      );
    }
  }
}

export default LoginSignupModule;
