import { renderReact } from "hypernova-react";

import initStickyAd from "./advertising/initStickyAd";

import Footer from "./layout/Footer";
import ModalContainer from "./modal/ModalContainer";
import Navigation from "./navigation/Navigation";
import PageNavigation from "./navigation/PageNavigation";
import IeNotification from "./notifications/IeNotification";
import NotificationContainer from "./notifications/NotificationContainer";
import PlayerFooter from "./advertising/PlayerFooter";

const renderBasicComponents = () => {
  initStickyAd("dfp-skyscraper");

  renderReact("Navigation", Navigation);
  renderReact("PageNavigation", PageNavigation);
  renderReact("ModalContainer", ModalContainer);
  renderReact("NotificationContainer", NotificationContainer);
  renderReact("PageNavigation", PageNavigation);
  renderReact("Footer", Footer);
  renderReact("IeNotification", IeNotification);
  renderReact("PlayerFooter", PlayerFooter);
};

export default renderBasicComponents;
