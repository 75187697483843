import {
  showError, showSuccess,
} from "./notificationActionCreators";

export const displayError = (message) => {
  const params = {};
  if (message) params.message = message;
  return dispatch => dispatch(showError(params));
};

export const displaySuccess = (message) => {
  const params = {};
  if (message) params.message = message;
  return dispatch => dispatch(showSuccess(params));
};
