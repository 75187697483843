import styled from "styled-components";
import { hero, dark } from "../../styles/colors";
import { liReset, transition, ulReset } from "../../styles/mixins";
import { oswaldFont, notoSansFont } from "../../styles/typography";

export const Wrapper = styled.ul`
  ${ulReset}
  display: inline-flex;
  height: 100%;
`;

export const Overlay = styled.ul`
  ${ulReset}
  display: none;
  position: absolute;
  min-width: 230px;
  padding: 36px 0;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(20, 20, 20, .3);
`;

export const Triangle = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 7px 7px 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
`;

export const TopItemLink = styled.a`
  ${oswaldFont({ weight: 500 })}
  ${transition("color")};
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
`;


export const TopItem = styled.li`
  ${liReset}
  position: relative;
  height: 100%;

  &:hover {
    ${Overlay},
    ${Triangle} {
      display: block;
    }
  }

  &:hover ${TopItemLink} {
    color: ${hero};
  }
`;

export const OverlayItem = styled.li`
  ${liReset}
`;

export const OverlayItemLink = styled.a`
  ${notoSansFont}
  ${transition("color")}
  display: block;
  padding: 9px 36px;
  color: ${dark};
  font-size: 15px;
  line-height: 1.6;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: ${hero};
    text-decoration: none;
  }
`;
