import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import styles from "./styles/JumpingDots.sass";

const cx = classNames.bind(styles);

const Crossfade = ({ size }) => (
  <div className={cx("wrapper")} style={{ fontSize: `${size}px` }}>
    <div className={cx("dot")} />
    <div className={cx("dot")} />
    <div className={cx("dot")} />
  </div>
);

Crossfade.propTypes = {
  size: PropTypes.number,
};

Crossfade.defaultProps = {
  size: 10,
};

export default Crossfade;
