/* global __HYPERNOVA__ */

import thunkMiddleware from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { reducer as notificationReducer } from "react-notification-system-redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import throttle from "lodash/throttle";
import { enableBatching } from "redux-batched-actions";

import loadInitialState from "./initialState";
import { fetchSession, changeSession } from "../session/actions/sessionActions";
import { CHANGED_SESSION } from "../session/constants/sessionConstants";
import { loadState, saveState } from "./sessionStorage";
import ratingReducer from "../rating/reducers/ratingReducer";
import followshipReducer from "../followship/reducers/followshipReducer";
import mutingReducer from "../muting/reducers/mutingReducer";
import watchingReducer from "../watching/reducers/watchingReducer";
import userlistReducer from "../userlist/reducers/userlistReducer";
import modalReducer from "../modal/reducers/modalReducer";
import watchedReducer from "../watched/reducers/watchedReducer";
import sessionReducer from "../session/reducers/sessionReducer";
import consumptionReducer from "../consumption/reducers/consumptionReducer";
import commentReducer from "../comment/reducers/commentReducer";
import diggReducer from "../digg/reducers/diggReducer";
import friendsOpinionsReducer from "../friendsOpinions/reducers/friendsOpinionsReducer";
import searchReducer from "../search/reducers/searchReducer";
import cinemaCurrentReducer from "../cinema/current/reducers/cinemaCurrentReducer";
import cinemaUpcomingReducer from "../cinema/upcoming/reducers/cinemaUpcomingReducer";
import movieCinemaReducer from "../cinema/movie/reducers/movieCinemaReducer";
import cinemaShowtimesReducer from "../cinema/showtimes/reducers/cinemaShowtimesReducer";
import cinemaShowtimeReducer from "../cinema/showtime/reducers/cinemaShowtimeReducer";
import tooltipReducer from "../modal/reducers/tooltipReducer";
import advertisingReducer from "../advertising/reducers/advertisingReducer";
import dvdReducer from "../dvd/reducers/dvdReducer";
import movieCinemaConsumptionReducer from "../cinema/consumption/reducers/movieCinemaConsumptionReducer";
import votesReducer from "../votes/reducers/votesReducer";
import triviasReducer from "../trivias/reducers/triviasReducer";
import flagsReducer from "../flags/reducers/flagsReducer";

const reducers = combineReducers({
  ratings: ratingReducer,
  followships: followshipReducer,
  mutings: mutingReducer,
  watchings: watchingReducer,
  userlists: userlistReducer,
  modal: modalReducer,
  notifications: notificationReducer,
  watched: watchedReducer,
  session: sessionReducer,
  consumptions: consumptionReducer,
  comments: commentReducer,
  diggs: diggReducer,
  dvd: dvdReducer,
  friendsOpinions: friendsOpinionsReducer,
  search: searchReducer,
  cinema: combineReducers({
    current: cinemaCurrentReducer,
    upcoming: cinemaUpcomingReducer,
    movie: movieCinemaReducer,
    showtimes: cinemaShowtimesReducer,
    showtime: cinemaShowtimeReducer,
    consumption: movieCinemaConsumptionReducer,
  }),
  tooltip: tooltipReducer,
  advertising: advertisingReducer,
  votes: votesReducer,
  trivias: triviasReducer,
  flags: flagsReducer,
});

const wrapperReducer = (state, action) => {
  if (action.type === CHANGED_SESSION) {
    state = {
      session: sessionReducer(null, action),
      consumptions: state.consumptions,
      comments: state.comments,
      cinema: state.cinema,
      dvd: state.dvd,
      advertising: state.advertising,
      votes: state.votes,
      trivias: state.trivias,
      flags: state.flags,
      notifications: state.notifications,
    };
  }
  return reducers(state, action);
};

const initStore = () =>
  createStore(
    enableBatching(wrapperReducer),
    Object.assign(loadState() || {}, loadInitialState()),
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  );

export const configureStore = () => {
  if (__HYPERNOVA__) return initStore();

  let store = window.reduxStore;
  if (store != null) return store;

  store = initStore();

  store.subscribe(throttle(() => {
    saveState(store.getState());
  }), 2000);

  store.dispatch(fetchSession());

  if (typeof window !== "undefined" && window !== null && window.Application != null) {
    window.Application.subscribe("session:login", () => store.dispatch(changeSession()));
    window.Application.subscribe("session:logout", () => store.dispatch(changeSession()));
  }

  window.reduxStore = store;

  return store;
};
