import React from "react";

import asIcon from "./asIcon";

export default asIcon(({ color }) => (
  <g fill={color}>
    <path d="M75.8 23.7H24.2c-6.7 0-12.1 5.4-12.2 12.1v28.4c0 6.7 5.5 12.1 12.2 12.1h51.6c6.7 0 12.1-5.4 12.2-12.1V35.8c0-6.7-5.5-12.1-12.2-12.1zM80 64.2c0 2.3-1.9 4.2-4.2 4.2H24.2a4.2 4.2 0 0 1-4.2-4.2V35.8c0-2.3 1.9-4.2 4.2-4.2h51.6c2.3 0 4.2 1.9 4.2 4.2v28.4z" />
    <path d="M71.2 36.3c-1 0-1.9.4-2.6 1L50 53.3l-18.6-16a4 4 0 0 0-2.6-1c-1.4 0-2.7.7-3.4 1.9a5 5 0 0 0 .8 6.5l20.9 18a4 4 0 0 0 2.6 1l1.4-.2a4 4 0 0 0 2-.9l20.7-18a4.9 4.9 0 0 0 .8-6.4c-.7-1.2-2-1.9-3.4-1.9z" />
  </g>
));
