import styled, { css } from "styled-components";
import { desktop } from "../../styles/mediaQueries";

export const SearchbarContainer = styled.div`
  display: inline-block;
  position: relative;
  flex-grow: 1;
  line-height: 0;

  ${desktop(css`
    display: block;
    padding-right: 50px;
  `)}
`;
