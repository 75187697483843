import { Map, fromJS } from "immutable";
import {
  FETCHING_ITEMS, FETCHED_ITEMS, RECEIVED_ITEMS, UPDATED_FILTERS, REPLACED_FILTERS,
} from "../constants/movieCinemaConsumptionConstants";

const initialState = Map();

const locationProps = ["coordinates", "location", "city"];

const movieCinemaConsumptionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case UPDATED_FILTERS:
    return state.updateIn(["filters"], Map(), (filters) => {
      // Delete current location filters from store if they will be updated
      if (Object.keys(action.filters).some(key => locationProps.includes(key))) {
        locationProps.forEach((id) => { filters = filters.delete(id); });
      }

      return filters.merge(action.filters);
    });

  case REPLACED_FILTERS:
    return state.set("filters", fromJS(action.filters || {}));

  case FETCHING_ITEMS:
    return state.set("fetching", true);

  case FETCHED_ITEMS:
    return state.set("fetching", false);

  case RECEIVED_ITEMS:
    return state.set("items", action.items)
      .updateIn(["filters"], Map(), (filters) => {
        if (Object.keys(action.filters).some(key => locationProps.includes(key))) {
          locationProps.forEach((id) => { filters = filters.delete(id); });
        }
        return filters.merge(action.filters);
      });

  default:
    return state;
  }
};

export default movieCinemaConsumptionReducer;
