import styled, { css } from "styled-components";
import { brandLight, brandUltraLight, darkGray, lightGray, lighterGray } from "../../styles/colors";
import { notoSansFont } from "../../styles/typography";
import ArrowIcon from "../../icons/ArrowIcon";
import AddIcon from "../../icons/AddIcon";
import CheckIcon from "../../icons/CheckIcon";
import CloseIconSlim from "../../icons/CloseIconSlim";

export const Wrapper = styled.div`
  position: relative;
  min-height: 46px;
  z-index: 10;
`;

const textStyling = css`
  ${notoSansFont}
  padding: 13px;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;
`;

export const OpenDropdownButton = styled.div`
  ${textStyling}
  color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  background-color: ${brandLight};
`;

export const StyledArrow = styled(ArrowIcon)`
  position: absolute;
  right: 15px;
  top: 13px;
  transform: scaleY(0.72);
  pointer-events: none;

  ${props => props.down && css`
    top: 12px;
    transform: scaleY(-0.72);
  `}
`;

StyledArrow.defaultProps = {
  color: lighterGray,
  size: 21,
};

export const InputWrapper = styled.div`
  display: flex;
  border: 1px solid #fff;
  border-radius: 3px 3px 0 0;
  border-bottom: 0;
  background-color: ${brandLight};
`;

export const Input = styled.input`
  width: 100%;
  ${textStyling}
  padding-right: 5px;
  border: 0;
  color: #fff;
  background-color: transparent;

  &::placeholder {
    color: ${lighterGray};
  }

  &:focus {
    outline: 0;
  }
`;


export const InputClearButton = styled.div`
  cursor: pointer;
  position: relative;
  flex-basis: 40px;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  opacity:  ${props => (props.isVisible ? 1 : 0)};
  transition: opacity .2s ease-in-out;
`;

export const StyledClearIcon = styled(CloseIconSlim)`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

StyledClearIcon.defaultProps = {
  color: "#fff",
  size: 18,
};

export const CloseDropdownButton = styled.div`
  cursor: pointer;
  flex-basis: 62px;
`;

export const DropdownArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 185px;
`;

export const ScrollArea = styled.div`
  overflow-y: scroll;
  height: calc(100% - 26px);
  scrollbar-width: none;  // Firefox
  overflow: -moz-scrollbars-none;
  border: 1px solid #fff;
  border-radius: 0 0 3px 3px;
  border-top: 0;
  background-color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CreateButton = styled.div`
  ${textStyling}
  position: relative;
  color: ${darkGray};
  background-color: ${brandUltraLight};
  padding-left: 36px;
`;

export const StyledAddIcon = styled(AddIcon)`
  position: absolute;
  top: 50%;
  left: 9px;
  transform: translateY(-50%);
`;

StyledAddIcon.defaultProps = {
  color: darkGray,
  size: 23,
};

export const RowItem = styled.div`
  ${textStyling}
  position: relative;
  color: ${props => (props.disabled ? lightGray : darkGray)};
  background-color: #fff;
  ${props => (props.disabled && css`
    cursor: default;
  `)}
`;

export const StyledCheckIcon = styled(CheckIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

StyledCheckIcon.defaultProps = {
  color: lightGray,
  size: 28,
  strokeWidth: 5,
};
