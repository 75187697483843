export const CREATED_COMMENT = "CREATED_COMMENTS";
export const DELETED_COMMENT = "DELETED_COMMENTS";
export const FETCHED_COMMENTS = "FETCHED_COMMENTS";
export const FETCHING_COMMENTS = "FETCHING_COMMENTS";
export const RECEIVED_COMMENTS = "RECEIVED_COMMENTS";
export const UPDATED_COMMENT = "UPDATED_COMMENT";
export const FLAGGED_COMMENT = "FLAGGED_COMMENT";
export const UNFLAGGED_COMMENT = "UNFLAGGED_COMMENT";
export const FLAGGED_COMMENT_ANSWER = "FLAGGED_COMMENT_ANSWER";
export const UNFLAGGED_COMMENT_ANSWER = "UNFLAGGED_COMMENT_ANSWER";
export const CREATED_REPLY = "CREATED_REPLY";
export const DELETED_REPLY = "DELETED_REPLY";
export const UPDATED_REPLY = "UPDATED_REPLY";
export const DIGGED_COMMENT = "DIGGED_COMMENT";
export const UNDIGGED_COMMENT = "UNDIGGED_COMMENT";
export const DIGGED_REPLY = "DIGGED_REPLY";
export const UNDIGGED_REPLY = "UNDIGGED_REPLY";
export const RECEIVED_MORE_COMMENTS = "RECEIVED_MORE_COMMENTS";
export const SAVING_COMMENT = "SAVING_COMMENT";
export const SAVED_COMMENT = "SAVED_COMMENT";
export const INCREMENT_COMMENTS_COUNT = "INCREMENT_COMMENTS_COUNT";
export const DECREMENT_COMMENTS_COUNT = "DECREMENT_COMMENTS_COUNT";
export const RESET_COMMENTS = "RESET_COMMENTS";
export const RECEIVED_COMMENT = "RECEIVED_COMMENT";
