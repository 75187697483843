import styled, { css } from "styled-components";
import { Button } from "../../buttons/Button";
import { sidekick } from "../../styles/colors";
import { tablet } from "../../styles/mediaQueries";
import { notoSansFont } from "../../styles/typography";

export const Wrapper = styled.div`
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${props => (props.isVisible ? 0 : "-100%")});
  transition: ${props => (props.isVisible ? ".5s ease-out" : ".5s .5s ease-in")};
  transition-property: transform, opacity;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .27);
`;

export const Content = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 12px;
  ${tablet(css`
    display: flex;
    align-items: center;
    padding: 24px 12px;
  `)}
`;

export const Message = styled.div`
  ${notoSansFont}
  font-size: 12px;
  line-height: 18px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 12px;
  ${tablet(css`
    flex: 0 0 300px;
    margin-top: 0;
    margin-left: 24px;
  `)}
`;

export const ConfirmButton = styled(Button)`
  width: 100%;
  transition: border-color .2s, background-color .2s, color .2s;
  &&&&& {
    ${props => props.isActive && css`
      border-color: ${sidekick};
      background-color: ${sidekick};
      color: #fff;
    `}
  }
`;
