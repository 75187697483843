import axios from "axios";
import { showError, showSuccess } from "../../notifications/actions/notificationActionCreators";
import { trackEvent } from "../../tracking/tracking";
import { changedSession, erroredCreateSession, fetchedSession, startedCreateSession, startedCreateFacebookSession, finishedCreateFacebookSession } from "./sessionActionCreators";
import setCsrfMetaTags from "../../../assets/javascripts/lib/set_csrf_meta_tags";

const apiUrl = "/api/session";

const errors = {
  fbEmail: "Anmelden via Facebook nicht möglich. Stelle sicher, dass dein Profil aktiviert und deine E-Mail Adresse für uns freigegeben ist.",
  fbGeneric: "Anmelden via Facebook nicht möglich. Bitte versuche es später noch einmal.",
  fbBlock: "Anmelden via Facebook nicht möglich. Stelle sicher, dass Facebook nicht von einem AdBlocker blockiert wird.",
};

export const source = {
  fetch() {
    return typeof window !== "undefined" && window !== null && window.Application != null ? window.Application.sessionInit : Promise.resolve();
  },
};

export const fetchSession = () =>
  dispatch => source.fetch().then(response => dispatch(fetchedSession(response)));

export const changeSession = () =>
  dispatch => source.fetch().then(response => dispatch(changedSession(response)));

export const createSession = (username, password) =>
  (dispatch) => {
    dispatch(startedCreateSession());

    const request = axios.post(apiUrl, { username, password }).then(({ data }) => data);
    window.Application.sessionInit = request;
    if (window.Application && window.Application.getSession) window.Application.getSession().initialize();
    return request
      .then((session) => {
        dispatch(changedSession(session));
        trackEvent("Sign-Up", "Login Success");
        window.Application.publish("session:login", session);
        return session;
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          dispatch(erroredCreateSession(
            "Da warst du zu schnell. Warte kurz und probiere es dann noch mal.",
          ));
        } else {
          dispatch(erroredCreateSession(error.response.data.error));
        }
        throw error;
      });
  };

export const destroySession = () =>
  (dispatch) => {
    const request = axios.delete(apiUrl).then(({ data }) => data);
    window.Application.sessionInit = request;
    if (window.Application && window.Application.getSession) window.Application.getSession().initialize();
    return request
      .then((session) => {
        setCsrfMetaTags();
        dispatch(changedSession(session));
        trackEvent("Sign-Up", "Logout");
        window.Application.publish("session:logout", session);
        dispatch(showSuccess({ message: "Du hast dich erfolgreich ausgeloggt." }));
      });
  };

export const createFacebookSession = () => (
  dispatch => new Promise((resolve, reject) => {
    if (!window.FB) {
      dispatch(showError({ message: errors.fbBlock }));
      reject();
      return;
    }

    dispatch(startedCreateFacebookSession());

    window.FB.login(({ status, authResponse }) => {
      if (status === "connected" && authResponse) {
        return axios.post(apiUrl, { access_token: authResponse.accessToken })
          .then(({ data }) => {
            dispatch(changedSession(data));
            trackEvent("Sign-Up", `Facebook ${data.is_new ? "Signup" : "Login"} Complete`);
            resolve(data);
          })
          .catch((err) => {
            dispatch(showError({ message: errors.fbEmail }));
            dispatch(finishedCreateFacebookSession());
            reject(err);
          });
      }

      dispatch(showError({ message: errors.fbGeneric }));
      dispatch(finishedCreateFacebookSession());
      return reject();
    }, {
      scope: "email",
      return_scopes: true,
    });
  })
);
