export const brand = "#2d2338"; // Dark Purple
export const brandLight = "#423551"; // Outer Space
export const brandUltraLight = "#F6F2FB"; // Magnolia
export const hero = "#f4645a"; // Sunset Orange
export const heroLight = "#f78e87"; // Melon
export const heroUltraLight = "#ffe4e3"; // Misty Rose
export const sidekick = "#54e5a2"; // Medium Aquamarine
export const sidekickLight = "#86f4c1"; // Pearl Aqua
export const error = "#c44848"; // English Vermillion

export const dark = "#141414";
export const darkGray = "#6c6c6c";
export const gray = "#989898";
export const lightGray = "#c2c2c2";
export const lighterGray = "#dcdcdc";
export const lightestGray = "#eceded";
export const nearWhite = "#f7f7f7";

export const heroRgb = "244,100,90";
export const darkRgb = "20,20,20";
