import React from "react";
import PropTypes from "prop-types";
import Observer from "@researchgate/react-intersection-observer";
import { trackPlayerEventWithCustomDimensions } from "./playerTrackingHelper";

const trackPlayerReady = trackPlayerEventWithCustomDimensions("video-player");

const PlayerInViewportTrackingWrapper = ({ children }) => {
  const handleIntersection = ({ isIntersecting }, unobserve) => {
    if (!isIntersecting) return;
    trackPlayerReady("playback_ready", "");
    unobserve();
  };

  return (
    <Observer onChange={handleIntersection} rootMargin="0% 0% 350px">
      {children}
    </Observer>
  );
};

PlayerInViewportTrackingWrapper.propTypes = {
  children: PropTypes.any,
};

PlayerInViewportTrackingWrapper.defaultProps = {
  children: null,
};

export default PlayerInViewportTrackingWrapper;
