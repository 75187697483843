import React from "react";
import consentPresent from "../helper/consentPresent";
import { Button, Title, Wrapper } from "./styles/DisplayOnConsent.styles";

const DisplayOnConsent = ({ children, className, title, vendor }) => {
  const showDiDomiOverlay = () => {
    if (window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  if (!consentPresent(vendor)) {
    return (
      <Wrapper className={className}>
        {title && <Title>{title}</Title>}
        <div>Dieses Modul konnte nicht geladen werden, da die erforderlichen Cookies nicht erlaubt wurden.</div>
        <Button onClick={showDiDomiOverlay} type="button">Einstellungen anpassen</Button>
      </Wrapper>
    );
  }

  return children;
};

export default DisplayOnConsent;
