import { Map } from "immutable";

import { RECEIVED_SEARCH_RESULTS } from "../constants/searchConstants";

const initialState = Map();

const searchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case RECEIVED_SEARCH_RESULTS:
    return state.set(action.initiator, action.searchResults);
  default:
    return state;
  }
};

export default searchReducer;
