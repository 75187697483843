import React from "react";
import Cookie from "js-cookie";
import { ButtonWrapper, ConfirmButton, Content, Message, Wrapper } from "./styles/IeNotification.styles";

const cookieName = "read-ie-consent";

class IeNotification extends React.PureComponent {
  state = {
    render: typeof window !== "undefined" && !Cookie.get(cookieName),
    visible: true,
    confirmed: false,
  }

  handleClose = () => {
    this.setState({ visible: false });
    setTimeout(() => this.setState({ render: false }), 1000);
  }

  handleConfirm = () => {
    this.setState({ visible: false, confirmed: true });
    Cookie.set(cookieName, "true", { expires: 1 });
    setTimeout(() => this.setState({ render: false }), 1000);
  }

  render() {
    if (typeof window === "undefined") return null;

    const { userAgent } = window.navigator;
    const isInternetExplorer = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
    if (!this.state.render || !isInternetExplorer) return null;

    return (
      <Wrapper isVisible={this.state.visible}>
        <Content>
          <Message>
            Wir bieten für diesen Internet-Browser keine Unterstützung mehr.
            Daher kann es sein, dass nicht alle Inhalte korrekt dargestellt und Funktionen genutzt werden können.
            <br />
            Um alle Inhalte und Funktionen unserer Webseite optimal nutzen zu können,
            empfehlen wir die Nutzung der aktuellsten Version des Google Chrome oder Firefox Internet-Browsers.
          </Message>
          <ButtonWrapper>
            <ConfirmButton fullwidth outline primary onClick={this.handleConfirm} isActive={this.state.confirmed}>verstanden</ConfirmButton>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    );
  }
}

export default IeNotification;
