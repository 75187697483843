import React from "react";
import styled, { css } from "styled-components";
import { heroUltraLight, lightestGray } from "../../styles/colors";
import { transition } from "../../styles/mixins";
import { notoSansFont } from "../../styles/typography";

const chipStyles = css`
  ${notoSansFont}
  display: inline-block;
  padding: 9px 18px;
  border-radius: 18px;
  background: ${lightestGray};
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;

  ${props => props.hasShadow && css`
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .3);
  `}

  ${props => props.hasIcon && css`
    position: relative;
    padding-left: 48px;
  `}

  ${props => (props.href || props.onClick) && css`
    ${transition("background")}
    cursor: pointer;

    &:hover {
      background: ${heroUltraLight};
    }
  `}
`;

export const Chip = styled(({ tag, children, hasIcon, hasShadow, ...props }) => React.createElement(tag, props, children))`${chipStyles}`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 50%;
`;
