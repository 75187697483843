import React from "react";
import PropTypes from "prop-types";

import SearchIcon from "../icons/SearchIcon";
import { Trigger } from "./styles/SearchbarFormOpener.styles";
import { autotrackParams } from "../tracking/tracking";

const tracking = autotrackParams({ category: "Search", action: "Open Mobile" });

const SearchbarFormOpener = ({ isHidden, showForm }) => (
  <Trigger
    isHidden={isHidden}
    onClick={() => showForm()}
    {...tracking}
  >
    <SearchIcon
      color="#fff"
      size={26}
    />
  </Trigger>
);

SearchbarFormOpener.propTypes = {
  isHidden: PropTypes.bool,
  showForm: PropTypes.func,
};

SearchbarFormOpener.defaultProps = {
  isHidden: false,
  showForm: null,
};

export default SearchbarFormOpener;
