import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./styles/CardLabel.sass";

const CardLabel = ({ children, className }) => (
  <div className={classNames(styles.wrapper, className)}>
    <span>{children}</span>
    <div className={styles.corner} />
  </div>
);

CardLabel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

CardLabel.defaultProps = {
  children: null,
  className: null,
};

export default CardLabel;
