import React from "react";
import { DesktopMenu, HamburgerMenu, HeaderLogo, InnerWrapper, LogoWrapper, Spacer,
  UserSearchWrapper, Wrapper } from "./styles/Navigation.styles";
import { autotrackParams, trackEvent } from "../tracking/tracking";
import Searchbar from "../search/Searchbar";
import SessionModule from "../session/SessionModule";

class Navigation extends React.Component {
  state = {
    open: null,
  }

  toggleState = (key) => {
    this.setState(state => ({ open: state.open === key ? null : key }));
  }

  handleMenuToggle = () => {
    trackEvent("Menu", (this.state.open === "menu" ? "Close Mobile" : "Open Mobile"));
    this.toggleState("menu");
  }
  handleUserMenuToggle = () => { this.toggleState("userMenu"); }

  render() {
    const { open } = this.state;
    return (
      <Wrapper id="nav">
        <HamburgerMenu open={open === "menu"} onToggle={this.handleMenuToggle} />
        <InnerWrapper>
          <LogoWrapper>
            <a href="/" {...autotrackParams({ category: "Menu", action: "Logo" })}>
              <HeaderLogo id="navLogo" />
            </a>
          </LogoWrapper>
          <DesktopMenu />
          <Spacer />
          <UserSearchWrapper>
            <Searchbar />
            <SessionModule open={open === "userMenu"} onToggle={this.handleUserMenuToggle} />
          </UserSearchWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default Navigation;
