import React from "react";
import PropTypes from "prop-types";

import Poster from "../assets/Poster";
import Avatar from "../assets/Avatar";

import mpAnalyticsEvent from "../tracking/mpAnalyticsEvent";
import {
  SearchbarPreviewItemWrapper,
  TextContent,
  ResultDisplayType,
  ResultTitle,
  ResultInfo,
  PosterWrapper,
} from "./styles/SearchbarPreviewItem.styles";

class SearchbarPreviewItem extends React.PureComponent {
  handleClick = (event) => {
    event.preventDefault();
    const payload = {
      target: this.props.path,
      target_type: this.props.itemClass,
      target_id: this.props.id,
      query: this.props.searchString,
      position: this.props.index,
    };

    mpAnalyticsEvent("search", "suggestions", payload, () => { window.location.href = this.props.path; });
  }

  render() {
    const { itemClass, title, displayType, path, meta, poster, isFocused } = this.props;
    return (<SearchbarPreviewItemWrapper
      href={path}
      onClick={this.handleClick}
      itemClassName="itemWrapper"
      isFocused={isFocused}
    >
      <PosterWrapper
        itemClass={itemClass}
      >
        {(itemClass === "Movie" ||
            itemClass === "Series" ||
            itemClass === "Cinema")
          ?
          (<Poster
            id={poster.id}
            type={itemClass}
            width={74}
            filename={poster.filename}
            className={itemClass}
          />)
          :
          (<Avatar
            id={poster.id}
            type={itemClass}
            username={title}
            width={74}
          />)
        }
      </PosterWrapper>
      <TextContent>
        <ResultDisplayType>{displayType}</ResultDisplayType>
        <ResultTitle>{title}</ResultTitle>
        <ResultInfo>{meta}</ResultInfo>
      </TextContent>
    </SearchbarPreviewItemWrapper>);
  }
}

SearchbarPreviewItem.propTypes = {
  displayType: PropTypes.string,
  itemClass: PropTypes.string,
  title: PropTypes.string,
  meta: PropTypes.string,
  poster: PropTypes.object,
  path: PropTypes.string,
  isFocused: PropTypes.bool,
  id: PropTypes.number,
  searchString: PropTypes.string,
  index: PropTypes.number,
};

SearchbarPreviewItem.defaultProps = {
  displayType: "",
  itemClass: "",
  title: "",
  meta: "",
  poster: {},
  path: "",
  isFocused: false,
  id: undefined,
  searchString: "",
  index: undefined,
};

export default SearchbarPreviewItem;
