import React from "react";
import PropTypes from "prop-types";

import { darkGray } from "../styles/colors";
import { autotrackParams } from "../tracking/tracking";
import SearchbarCloseIcon from "../icons/SearchbarCloseIcon";
import { ClearButton, Form, Inputfield, ReturnButton, StyledSearchbarClearIcon, StyledSearchIcon,
  SubmitButton, SubmitLabel } from "./styles/SearchbarForm.styles";

class SearchbarForm extends React.Component {
  setInputRef = (element) => {
    this.inputElement = element;
  };

  handleClearInputfield = () => {
    this.inputElement.value = "";
    this.props.handleTextInputChange();
    this.focusInput();
  };

  focusInput = () => {
    this.inputElement.focus();
  };

  handleHideInput = () => {
    this.handleClearInputfield();
    this.props.hideForm();
  };

  render() {
    const { isHidden, searchString, handleTextInputChange, handleKeyDown } = this.props;
    return (
      <Form action="/suche" method="GET" isHidden={isHidden} id="navSearch">
        <ReturnButton
          type="button"
          onClick={this.handleHideInput}
          {...autotrackParams({ category: "Search", action: "Close Mobile" })}
        >
          <SearchbarCloseIcon color="#fff" size={26} />
        </ReturnButton>
        <SubmitLabel htmlFor="submit">
          <SubmitButton type="submit" />
          <StyledSearchIcon color={darkGray} size={26} id="navSearchIcon" />
        </SubmitLabel>
        <ClearButton
          type="button"
          onClick={this.handleClearInputfield}
          visible={searchString !== ""}
        >
          <StyledSearchbarClearIcon color={darkGray} size={26} />
        </ClearButton>
        <Inputfield
          id="searchbarInput"
          name="q"
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchString}
          onChange={handleTextInputChange}
          placeholder="Filme, Serien, Stars, Kinos..."
          onKeyDown={handleKeyDown}
          innerRef={this.setInputRef}
        />
      </Form>
    );
  }
}

SearchbarForm.propTypes = {
  searchString: PropTypes.string,
  handleTextInputChange: PropTypes.func,
  handleKeyDown: PropTypes.func,
  hideForm: PropTypes.func,
  isHidden: PropTypes.bool,
};

SearchbarForm.defaultProps = {
  searchString: "",
  handleTextInputChange: () => {},
  handleKeyDown: () => {},
  hideForm: () => {},
  isHidden: false,
};

export default SearchbarForm;
