import { Map, Set, fromJS } from "immutable";
import { FETCHED_DIGGS, FETCHING_DIGGS, RECEIVED_DIGGS } from "../constants/diggConstants";

const initialState = Map();

const commentReducer = (state = initialState, action = {}) => {
  switch (action.type) {

  case FETCHING_DIGGS:
    return state.updateIn(["fetching", action.diggableType], fetchingIds =>
      Set(fetchingIds).add(String(action.diggableId)),
    );

  case FETCHED_DIGGS:
    return state.updateIn(["fetching", action.diggableType], fetchingIds =>
    Set(fetchingIds).remove(String(action.diggableId)),
  );

  case RECEIVED_DIGGS:
    return state.setIn([action.diggableType, action.diggableId], fromJS(action.diggs));

  default:
    return state;
  }
};

export default commentReducer;
