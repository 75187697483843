/**
 * Source: https://www.styled-components.com/docs/advanced#media-templates
 */
import { css } from "styled-components";

const sizes = {
  desktop: 1024,
  tablet: 768,
  largeMobile: 480,
};

const generateMinMediaQuery = label => (
  (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `
);

export const desktop = generateMinMediaQuery("desktop");
export const tablet = generateMinMediaQuery("tablet");
export const largeMobile = generateMinMediaQuery("largeMobile");

const generateMaxMediaQuery = label => (
  (...args) => css`
    @media (max-width: ${sizes[label] - 1}px) {
      ${css(...args)}
    }
  `
);

export const mobileOrTablet = generateMaxMediaQuery("desktop");
export const mobile = generateMaxMediaQuery("tablet");
