import { Map, fromJS } from "immutable";
import {
  FETCHED_FOLLOWSHIPS, CREATED_FOLLOWSHIP, DELETED_FOLLOWSHIP, RECEIVED_FOLLOWSHIP_ID, RECEIVED_FOLLOWSHIPS,
} from "../constants/followshipConstants";

const initialState = Map();

const followshipsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case FETCHED_FOLLOWSHIPS:
    return state.set("fetched", Date.now());

  case RECEIVED_FOLLOWSHIPS:
    return fromJS(action.followships).set("fetched", Date.now());

  case CREATED_FOLLOWSHIP:
    return state.setIn([action.followableType, action.followableId], undefined);

  case RECEIVED_FOLLOWSHIP_ID:
    return state.setIn([action.followableType, action.followableId], action.followship.id);

  case DELETED_FOLLOWSHIP:
    return state.deleteIn([action.followableType, action.followableId]);

  default:
    return state;
  }
};

export default followshipsReducer;
