
import PropTypes from "prop-types";
import React from "react";
import axios from "axios";
import UserIcon from "../icons/UserIcon";
import JumpingDots from "../loader/JumpingDots";
import { Button, Error, Header, Input, Wrapper } from "./styles/RecoverPassword.styles";

class RecoverPassword extends React.Component {
  state = {
    loading: false,
    error: null,
    username: "",
  }

  handleUsernameInput = (username) => {
    this.setState({ username });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: null });
    axios.post("/api/request_password", { user: this.state.username })
      .then(() => this.props.onReset())
      .catch(() => this.setState({ error: "Benutzername oder E-Mail unbekannt" }));
  }

  render() {
    const { loading, error } = this.state;
    const { onReturn } = this.props;
    return (
      <Wrapper>
        <Header>Passwort vergessen?</Header>
        <form onSubmit={this.handleSubmit}>
          <Input icon={UserIcon} name="username" onInput={this.handleUsernameInput} error={!!error} disabled={loading} placeholder="Benutzername oder E-Mail Adresse" />
          <Error>{error}</Error>
          <Button primary fullwidth disabled={loading} attributes={{ type: "submit" }}>
            {loading ? <JumpingDots size={8} /> : "Passwort zurücksetzen"}
          </Button>
          <Button outline black fullwidth onClick={onReturn}>Zurück zum Login</Button>
        </form>
      </Wrapper>
    );
  }
}

RecoverPassword.propTypes = {
  onReturn: PropTypes.func,
  onReset: PropTypes.func,
};
RecoverPassword.defaultProps = {
  onReturn: () => {},
  onReset: () => {},
};

export default RecoverPassword;
