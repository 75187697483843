import { Map } from "immutable";

import actionCreators from "../actions/movieCinemaConsumptionActionCreators";
import cinemaReducer from "../reducers/movieCinemaConsumptionReducer";

const { fetchingItems } = actionCreators;

const preloadMovieCinemaConsumptionStore = (store = {}) => {
  const movieCinemaConsumptionStore = cinemaReducer(Map(), fetchingItems());

  if (typeof store.cinema !== "object") store.cinema = {};
  store.cinema.consumption = (store.cinema.consumption || Map()).mergeDeep(movieCinemaConsumptionStore);
  return store;
};

export default preloadMovieCinemaConsumptionStore;
