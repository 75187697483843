import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import Avatar from "../assets/Avatar";
import scrollLock from "../helper/scrollLock";
import UserIcon from "../icons/UserIcon";
import { isDesktop } from "../layout/MediaQueries";
import withProvider from "../store/withProvider";
import { trackEvent } from "../tracking/tracking";
import UserMenu from "./UserMenu";
import { destroySession } from "./actions/sessionActions";
import { AvatarWrapper, Badge, Overlay, Triangle, Wrapper } from "./styles/SessionModule.styles";
import LoginSignupModule from "./LoginSignupModule";

class SessionModule extends React.Component {
  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      scrollLock(!isDesktop() && this.props.open);

      if (this.props.open && this.props.session.type !== "RegisteredUser") {
        trackEvent("Sign-Up", "Open Login Overlay");
      }
    }

    if (this.props.open && this.props.session.type !== prevProps.session.type) {
      this.props.onToggle();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  setReference = (node) => {
    this.overlayRef = node;
  }

  handleClickOutside = (event) => {
    if (this.props.open && document.body.contains(event.target) && !this.overlayRef.contains(event.target)) {
      this.props.onToggle();
    }
  }

  render() {
    const { session, open, onToggle, logout } = this.props;
    const notificationCount = (session.unread_message_count || 0) + (session.pending_friend_request_count || 0);
    const isLoggedIn = session.type && session.type === "RegisteredUser";

    return (
      <Wrapper innerRef={this.setReference}>
        <AvatarWrapper onClick={onToggle} hasAvatar={isLoggedIn}>
          {notificationCount > 0 && <Badge>{notificationCount}</Badge>}
          {isLoggedIn ?
            <Avatar
              filename={session.current_user_avatar.image_filename}
              id={session.current_user_avatar.image_id}
              widths={[26, 42]}
              sizes="(min-width: 1024px) 42px, 26px"
              username={session.username}
              isUser
            />
            :
            <UserIcon color="#fff" size={26} id="navUserIcon" />
          }
        </AvatarWrapper>

        {open && [
          <Overlay key="overlay">
            {session.type && session.type === "RegisteredUser" ?
              <UserMenu session={session} onLogout={logout} />
              :
              <LoginSignupModule />
            }
          </Overlay>,
          <Triangle key="triangle" />,
        ]}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session.toJS() || {},
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(destroySession()),
});

SessionModule.propTypes = {
  session: PropTypes.object,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  logout: PropTypes.func.isRequired,
};

SessionModule.defaultProps = {
  session: {},
  open: false,
  onToggle: () => {},
};

export default withProvider(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SessionModule));
