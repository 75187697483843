import throttle from "lodash/throttle";
import { scrollY } from "../helper/scrollPosition";

const menuOffset = 74;

const initStickyAd = (id) => {
  let isFixed = false;
  const element = document.getElementById(id);
  if (element) {
    const getBreakpoint = () => (scrollY() + element.getBoundingClientRect().top) - menuOffset;

    let breakpoint = getBreakpoint();
    let originalTopStyle;
    let originalMarginStyle;
    let originalPositionStyle;

    const checkSticky = throttle(() => {
      const shouldBeFixed = scrollY() > breakpoint;
      if (isFixed === shouldBeFixed) return;
      if (element.getElementsByTagName("iframe").length === 0) return;
      if (shouldBeFixed) {
        originalTopStyle = element.style.top;
        originalMarginStyle = element.style.marginTop;
        originalPositionStyle = element.style.position;

        element.style.position = "fixed";
        element.style.top = `${menuOffset}px`;
        element.style.marginTop = 0;
      } else {
        element.style.position = originalPositionStyle;
        element.style.top = originalTopStyle;
        element.style.marginTop = originalMarginStyle;
        setTimeout(() => { breakpoint = getBreakpoint(); }, 0);
      }
      isFixed = shouldBeFixed;
    }, 50);

    window.addEventListener("scroll", checkSticky);
    checkSticky();
  }
};

export default initStickyAd;
