import { Map, fromJS } from "immutable";

import { keyIn } from "../../helper/immutableHelper";
import { RECEIVED_VOTES, CREATED_VOTE, UPDATED_VOTE, DELETED_VOTE, CLEARED_VOTES_USER_DATA } from "../constants/votesConstants";

const votesReducer = (state = Map(), action = {}) => {
  switch (action.type) {
  case RECEIVED_VOTES: {
    let nextState = state;
    action.votes.forEach((vote) => {
      nextState = nextState.updateIn([vote.votableType, vote.votableId], Map(), voting => voting.mergeDeep(fromJS(vote).filterNot(keyIn("votableType", "votableId"))));
    });
    return nextState;
  }

  case CREATED_VOTE:
  case UPDATED_VOTE:
    return state.setIn([action.votableType, action.votableId], fromJS(action.vote).filterNot(keyIn("votableType", "votableId")));

  case DELETED_VOTE:
    return state.setIn([action.votableType, action.votableId], fromJS(action.vote).filterNot(keyIn("id", "direction")));

  case CLEARED_VOTES_USER_DATA: {
    let nextState = state;
    nextState.forEach((ids, type) => {
      ids.forEach((vote, id) => {
        nextState = nextState.setIn([type, id], vote.filterNot(keyIn("id", "direction")));
      });
    });
    return nextState;
  }

  default:
    return state;
  }
};

export default votesReducer;
