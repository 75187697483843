import React from "react";
import PropTypes from "prop-types";

import scriptLoader from "../helper/scriptLoader";
import { PlayerWrapper } from "./styles/DailymotionPlayer.styles";
import { errorCodes } from "./dailymotionErrorCodes";
import { trackPlayerEventWithCustomDimensions } from "./playerTrackingHelper";
import { availableDmPlayerProfiles } from "./dailymotionPlayerProfileIds";

const sanitizeProvidedTitle = dirtyTitle => dirtyTitle.replaceAll("+", " ");
const createLabel = ({ videoTitle, videoId }) => `${sanitizeProvidedTitle(videoTitle)} | ${videoId}`;

class DailymotionPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptLoaded: false,
      uniquePlayerId: `dailymotion-player-${this.props.video.remoteId}-${new Date().getTime()}`,
    };
  }

  componentDidMount() {
    if (typeof window.dailymotion === "undefined") {
      window.dailymotionExternalScriptLoadingState = new Promise((resolve) => {
        window.dailymotion = {
          onScriptLoaded: () => {
            this.setState({ scriptLoaded: true });
            resolve();
          },
        };
      });
      scriptLoader(`https://geo.dailymotion.com/player/${availableDmPlayerProfiles[this.props.playerProfile]}.js`);
    } else {
      window.dailymotionExternalScriptLoadingState.then(() => {
        this.setState({ scriptLoaded: true });
      });
    }
  }

  createPlayerTrackingParams = (category) => {
    const { autoplay, clickToLoad, pageEnvironment } = this.props;

    return {
      playerTriggerInfo: {
        autoplay,
        clickToLoad,
      },
      pageEnvironment,
      category,
    };
  };

  render() {
    const {
      playerProfile,
      adNative,
      adsDisabled,
      className,
      video,
      mute,
    } = this.props;
    if (!playerProfile) return null;

    const trackPlayerEvent = trackPlayerEventWithCustomDimensions(this.createPlayerTrackingParams("video-player"));
    const trackPrerollEvent = trackPlayerEventWithCustomDimensions(this.createPlayerTrackingParams("video-preroll"));
    const trackVideoEvent = trackPlayerEventWithCustomDimensions(this.createPlayerTrackingParams(adNative ? "video-ad-native" : "video-content"));

    // label will be created every time currentVideo is re-set
    let label = null;

    // fire event only once
    let playerLoaded = false;

    let videoPaused = false;

    // progress tracking will be reset upon video change
    const progressTrackingStatus = {
      25: false,
      50: false,
      75: false,
    };

    // PLAYER event handlers
    const handleStartEvent = (playerState) => {
      // set local video properties of currently playing video
      const { videoTitle, videoId } = playerState;
      label = createLabel({ videoTitle, videoId });
      videoPaused = false;

      if (!playerLoaded) {
        trackPlayerEvent("starts", label);
        playerLoaded = true;
      }
    };

    const handlePauseEvent = () => {
      videoPaused = true;
      trackVideoEvent("pause", label);
    };

    const handlePlayEvent = () => {
      if (videoPaused) {
        trackVideoEvent("play", label);
        videoPaused = false;
      }
    };

    const handleVideoChangeEvent = () => {
      if (playerLoaded) {
        trackPlayerEvent("change", label);
        Object.keys(progressTrackingStatus).forEach((value) => { progressTrackingStatus[value] = false; });
      }
    };

    // PRE ROLL event handlers
    const handleAdStartEvent = () => trackPrerollEvent("ad_start", label);
    const handleAdEndEvent = () => trackPrerollEvent("ad_end", label);

    // MAIN VIDEO CONTENT / VIDEO AD FORMAT event handlers
    const handleVideoStartEvent = () => trackVideoEvent("starts", label);
    const handleEndEvent = () => trackVideoEvent("finish", label);

    const handleProgressTracking = (progress) => {
      trackVideoEvent(`play-${progress}`, label);
      progressTrackingStatus[progress] = true;
    };

    // helper for progress tracking
    const handleTimeUpdateEvent = (playerState) => {
      const { videoDuration: duration, videoTime: currentTime } = playerState;
      const progress = Math.round((currentTime / duration) * 100);
      if (Object.keys(progressTrackingStatus).includes(String(progress))
      && !progressTrackingStatus[progress]) handleProgressTracking(progress);
    };

    const handleErrorEvent = (playerState) => {
      trackPlayerEvent("error", label);
      trackPlayerEvent("error-details", errorCodes[playerState.playerError]);
    };

    const setCustomConfigAndLoadContent = (adsParams = "adsDisabled") => {
      window.dailymotion.getPlayer(this.state.uniquePlayerId).then((player) => {
        player.setCustomConfig({ customParams: adsParams });
        player.loadContent({ video: video.remoteId });
      });
    };

    if (this.state.scriptLoaded) {
      window.dailymotion.createPlayer(
        this.state.uniquePlayerId,
        {
          player: availableDmPlayerProfiles[this.props.playerProfile],
          params: { mute },
        },
      )
        .then((player) => {
          // dailymotion player event constants
          const {
            PLAYER_START,
            PLAYER_VIDEOCHANGE,
            PLAYER_ERROR,
            VIDEO_START,
            VIDEO_PAUSE,
            VIDEO_PLAY,
            VIDEO_END,
            VIDEO_TIMECHANGE,
            AD_START,
            AD_END,
          } = window.dailymotion.events;

          const ArrayOfEventHandlers = {
            [PLAYER_START]: handleStartEvent,
            [PLAYER_VIDEOCHANGE]: handleVideoChangeEvent,
            [PLAYER_ERROR]: handleErrorEvent,
            [VIDEO_START]: handleVideoStartEvent,
            [VIDEO_PAUSE]: handlePauseEvent,
            [VIDEO_PLAY]: handlePlayEvent,
            [VIDEO_END]: handleEndEvent,
            [VIDEO_TIMECHANGE]: handleTimeUpdateEvent,
            [AD_START]: handleAdStartEvent,
            [AD_END]: handleAdEndEvent,
          };

          Object.keys(ArrayOfEventHandlers).forEach(eventName => (player.on(eventName, playerState => ArrayOfEventHandlers[eventName](playerState))));

          if (adsDisabled) {
            setCustomConfigAndLoadContent();
          } else {
            window.jad.cmd.push(() => {
              const jadTarget = `preroll/${this.state.uniquePlayerId}`;
              window.jad.public.getDailymotionAdsParamsForScript(
                [jadTarget],
                adsParams => setCustomConfigAndLoadContent(adsParams[jadTarget]),
              );
            });
          }
        });
    }

    return (
      <PlayerWrapper className={className}>
        <div id={this.state.uniquePlayerId} />
      </PlayerWrapper>
    );
  }
}

DailymotionPlayer.propTypes = {
  playerProfile: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
  adNative: PropTypes.bool,
  adsDisabled: PropTypes.bool,
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  clickToLoad: PropTypes.bool,
  pageEnvironment: PropTypes.string,
  mute: PropTypes.bool,
};

DailymotionPlayer.defaultProps = {
  adNative: false,
  adsDisabled: false,
  autoplay: false,
  className: null,
  clickToLoad: false,
  pageEnvironment: "default",
  mute: false,
};

export default DailymotionPlayer;
