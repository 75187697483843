import { load } from "hypernova";
import { Map } from "immutable";

import { receivedTrivias, updatedCategories } from "../actions/triviasActionCreators";
import triviasReducer from "../reducers/triviasReducer";
import { receivedVotes } from "../../votes/actions/votesActionCreators";
import votesReducer from "../../votes/reducers/votesReducer";

const preloadTriviasStore = (store = {}) => {
  const instances = load("TriviasModule").concat(load("Trivias"));

  if (!instances.length) return store;
  const { trivializableType, trivializableId, items, lastPage, categories } = instances.pop().data || {};

  let triviasStore = Map();
  triviasStore = triviasReducer(triviasStore, receivedTrivias(trivializableType, trivializableId, items, lastPage));
  triviasStore = triviasReducer(triviasStore, updatedCategories(trivializableType, trivializableId, categories));

  let votesStore = Map();
  votesStore = votesReducer(votesStore, receivedVotes(items.map(({ id, upvotesCount, downvotesCount }) =>
    ({ votableType: "Trivia", votableId: id, upvotesCount, downvotesCount }))));

  store.trivias = (store.trivias || Map()).mergeDeep(triviasStore);
  store.votes = (store.votes || Map()).mergeDeep(votesStore);
  return store;
};

export default preloadTriviasStore;
