import axios from "axios";

import { showError, showSuccess } from "../../notifications/actions/notificationActionCreators";
import { trackEvent } from "../../tracking/tracking";
import { isLoggedIn, showLogin } from "../../session";
import { itemApiUrl } from "../../helper/urlHelpers";

import { receivedLists, createdList, addedToList } from "./userlistActionCreators";

export const listApiUrl = listId => itemApiUrl("List", listId);
export const listItemsApiUrl = listId => `${listApiUrl(listId)}/items`;
export const itemUserListsApiUrl = (itemType, itemId) => itemApiUrl(itemType, itemId, "user_lists");

let cancelToken = null;

const cancelAndUpdateToken = () => {
  if (cancelToken) cancelToken.cancel();
  cancelToken = axios.CancelToken.source();
};

export const fetchLists = (itemType, itemId) => (
  (dispatch) => {
    if (!isLoggedIn()) return Promise.resolve();

    return axios.get(itemUserListsApiUrl(itemType, itemId))
      .then(response => dispatch(receivedLists(itemType, itemId, response.data)))
      .catch((e) => {
        dispatch(showError({ message: "Beim Laden deiner Listen ist ein Fehler aufgetreten. Versuche es später nochmal." }));
        throw e;
      });
  }
);

export const createList = (itemType, itemId, listName) => (
  (dispatch) => {
    if (!isLoggedIn()) return Promise.resolve(showLogin());

    cancelAndUpdateToken();

    return axios.post(listApiUrl(), { list: { name: listName } }, { cancelToken: cancelToken.token })
      .then(({ data }) => dispatch(createdList(itemType, itemId, data)))
      .catch((e) => {
        if (axios.isCancel(e)) return;
        dispatch(showError({ responseCode: e.response.status }));
        throw e;
      });
  }
);

export const addToList = (itemType, itemId, listId, description) => (
  (dispatch) => {
    if (!isLoggedIn()) return Promise.resolve(showLogin());

    cancelAndUpdateToken();

    return axios.post(
      listItemsApiUrl(listId),
      { list_item: { source_id: itemId, source_type: itemType, description } },
      { cancelToken: cancelToken.token },
    )
      .then(({ data }) => {
        dispatch(addedToList(itemType, itemId, listId));
        dispatch(showSuccess(data.notification));
        trackEvent("Community", "Add to List", itemType);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        dispatch(showError(e));
        throw e;
      });
  }
);
