import { css } from "styled-components";
import { dark, hero } from "./colors";
import { transition } from "./mixins";

export const oswaldFont = ({ weight = 600, spacing = 0.075 }) => css`
  font-family: Oswald, sans-serif;
  font-stretch: normal;
  font-weight: ${weight};
  letter-spacing: ${spacing}em;
`;

export const notoSerifFont = css`
  font-family: "Noto Serif", serif;
  letter-spacing: normal;
`;

export const notoSansFont = css`
  font-family: "Noto Sans", sans-serif;
  letter-spacing: normal;
`;

export const linkStyle = ({ color = hero, shadowColor = "#fff" }) =>
  css`
    ${transition("color")}
    box-shadow: inset 0 -.1em ${color};
    color: ${dark};
    text-decoration: none;
    text-shadow: -.07em -.07em ${shadowColor}, -.07em .07em ${shadowColor}, .07em -.07em ${shadowColor}, .07em .07em ${shadowColor}, 0 -.1em ${shadowColor}, 0 .1em ${shadowColor}, .1em 0 ${shadowColor}, -.1em 0 ${shadowColor};

    &:hover {
      color: ${color};
      text-decoration: none;
    }
  `;
