import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import ResponsiveImage from "./ResponsiveImage";
import RetinaImage from "./RetinaImage";
import styles from "./styles/Avatar.sass";
import { usernameToColor } from "../helper/avatarBackgroundColorHelper";

const cx = classNames.bind(styles);

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false,
    };
  }

  userInitials = (username, maxLength = 2) =>
    String(username)
      .split(/(?=[A-Z])|[\s.:\-_]+/)
      .slice(0, maxLength)
      .map(s => s[0])
      .join("")
      .toUpperCase();

  render() {
    const { hover, className, username, isUser, ...props } = this.props;

    let content = null;
    const avatarImageClasses = isUser
      ? "avatarImage"
      : ["avatarImage", "hasFilter"];

    if (props.id) {
      if (props.widths) {
        content = (
          <ResponsiveImage
            aspectRatio={1}
            className={cx(avatarImageClasses)}
            alt={username}
            onError={() => this.setState({ imageError: true })}
            {...props}
          />
        );
      } else {
        content = (
          <RetinaImage
            height={props.width}
            className={cx(avatarImageClasses)}
            alt={username}
            onError={() => this.setState({ imageError: true })}
            {...props}
          />
        );
      }
    }

    return (
      <div
        className={cx("avatar", className, { hasHover: hover })}
        style={{ background: isUser && (!props.id || this.state.imageError) ? usernameToColor(username) : "#989898" }}
      >
        {props.id && !this.state.imageError ? (
          content
        ) : (
          <svg
            className={cx("avatarInitials")}
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
          >
            <text
              className={cx("avatarInitialsText")}
              fontSize="40"
              textAnchor="middle"
              x="50"
              y="50"
              dy="16.3"
            >
              {this.userInitials(username)}
            </text>
          </svg>
        )}
        {!isUser && <div className={cx("avatarGradient")} />}
      </div>
    );
  }
}

Avatar.propTypes = {
  hover: PropTypes.bool,
  id: PropTypes.string,
  lazy: PropTypes.bool,
  width: PropTypes.number,
  widths: PropTypes.array,
  username: PropTypes.string.isRequired,
  className: PropTypes.string,
  isUser: PropTypes.bool,
};

Avatar.defaultProps = {
  id: undefined,
  hover: false,
  lazy: false,
  width: undefined,
  widths: null,
  className: undefined,
  isUser: false,
};

export default Avatar;
