import * as constants from "../constants/movieCinemaConsumptionConstants";
import actionCreators from "../../../itemList/actions/tabbedMoviesActionCreators";

export default Object.assign(actionCreators(constants), {
  receivedItems: (items, nextPage, filters) => ({
    type: constants.RECEIVED_ITEMS,
    items,
    nextPage,
    filters,
  }),
});
