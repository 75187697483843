import { FETCHED_SESSION, CHANGED_SESSION, STARTED_CREATE_SESSION, ERRORED_CREATE_SESSION, STARTED_CREATE_FACEBOOK_SESSION, FINISHED_CREATE_FACEBOOK_SESSION } from "../constants/sessionConstants";

export const fetchedSession = session => ({
  type: FETCHED_SESSION,
  session,
});

export const changedSession = session => ({
  type: CHANGED_SESSION,
  session,
});

export const startedCreateSession = () => ({
  type: STARTED_CREATE_SESSION,
});

export const erroredCreateSession = error => ({
  type: ERRORED_CREATE_SESSION,
  error,
});

export const startedCreateFacebookSession = () => ({
  type: STARTED_CREATE_FACEBOOK_SESSION,
});

export const finishedCreateFacebookSession = () => ({
  type: FINISHED_CREATE_FACEBOOK_SESSION,
});
