import React from "react";
import PropTypes from "prop-types";

import PlayerInViewportTrackingWrapper from "../player/PlayerInViewportTrackingWrapper";
import { autotrackParams } from "../tracking/tracking";
import { Description, DescriptionLink, LazyWrapper, StyledPlayer, VideoWrapper,
  Wrapper } from "./styles/PlayerFooter.styles";

const PlayerFooter = ({ video, title, linkUrl, linkTitle }) => (
  <Wrapper>
    <Description>
      {title}
      <DescriptionLink href={linkUrl} {...autotrackParams({ category: "promoted", action: "player-footer", label: "click title for more info" })}>
        {linkTitle}
      </DescriptionLink>
    </Description>
    <PlayerInViewportTrackingWrapper>
      <VideoWrapper>
        <LazyWrapper cushion={500}>
          <StyledPlayer
            video={video}
            autoplay
            mute
            adsDisabled
            adNative
            pageEnvironment="player_footer"
            playerProfile="playerFooter"
          />
        </LazyWrapper>
      </VideoWrapper>
    </PlayerInViewportTrackingWrapper>
  </Wrapper>
);

PlayerFooter.propTypes = {
  video: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkTitle: PropTypes.string.isRequired,
};

export default PlayerFooter;
