import React from "react";
import { Provider } from "react-redux";

import Notifications from "./Notifications";
import { configureStore } from "../store/applicationStore";

const NotificationContainer = () => (
  <Provider store={configureStore()}>
    <Notifications />
  </Provider>
);

export default NotificationContainer;
