import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const asIcon = (Icon, options = {}) => {
  const { className } = options;

  class icon extends React.PureComponent {
    static propTypes = {
      viewBox: PropTypes.string,
      size: PropTypes.any,
      color: PropTypes.string,
      secondaryColor: PropTypes.string,
      className: PropTypes.any,
      id: PropTypes.string,
    };

    static defaultProps = {
      viewBox: "0 0 100 100",
      size: 42,
      color: "#141414",
      secondaryColor: null,
      className: null,
      id: null,
    };

    render() {
      const { viewBox, size, color, secondaryColor, id, ...props } = this.props;
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} width={size} height={size} className={classNames(className, props.className)} id={id}>
          <Icon color={color} secondaryColor={secondaryColor} {...props} />
        </svg>
      );
    }
  }

  return icon;
};

export default asIcon;
