import toParam from "jquery-param";

export default (event, source, payload, callback) => {
  const params = {
    e: event,
    d: window.location.hostname,
    t: window.location.pathname,
    s: source,
    p: payload,
  };

  const image = new Image();
  image.onload = callback;
  image.onerror = callback;
  image.src = `https://analytics.moviepilot.de/t.gif?${toParam(params)}`;
};
