import { Map } from "immutable";

import { CHANGED_SESSION, ERRORED_CREATE_SESSION, FETCHED_SESSION, FINISHED_CREATE_FACEBOOK_SESSION,
  STARTED_CREATE_FACEBOOK_SESSION, STARTED_CREATE_SESSION } from "../constants/sessionConstants";

const initialState = Map();

const sessionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case FETCHED_SESSION:
  case CHANGED_SESSION:
    return Map(action.session);

  case STARTED_CREATE_SESSION:
    return state.set("loading", true).delete("error");

  case ERRORED_CREATE_SESSION:
    return state.merge({ loading: false, error: action.error });

  case STARTED_CREATE_FACEBOOK_SESSION:
    return state.set("facebookLoading", true);

  case FINISHED_CREATE_FACEBOOK_SESSION:
    return state.set("facebookLoading", false);

  default:
    return state;
  }
};

export default sessionReducer;
