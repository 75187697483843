import PropTypes from "prop-types";
import React from "react";
import consentPresent from "../helper/consentPresent";
import execOnConsent from "../helper/execOnConsent";
import loadFacebookSdk from "../vendor/loadFacebookSdk";
import FacebookIcon from "../icons/FacebookIcon";
import PasswordIcon from "../icons/PasswordIcon";
import UserIcon from "../icons/UserIcon";
import JumpingDots from "../loader/JumpingDots";
import LoginTeaser from "./LoginTeaser";
import {
  Button, Error, Form, Headline, Input, LoginItems, PrimaryButton, RedirectingMessage,
  StyledDisplayOnConsent,
} from "./styles/LoginForm.styles";

class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    fbLoaded: false,
  }

  componentDidMount() {
    if (consentPresent("facebook")) {
      this.loadFacebookScript();
    } else {
      execOnConsent("facebook", this.loadFacebookScript);
    }
  }

  loadFacebookScript = () => {
    loadFacebookSdk().then(() => this.setState({ fbLoaded: true }));
  }

  handleUsernameInput = (username) => {
    this.setState({ username });
  }

  handlePasswordInput = (password) => {
    this.setState({ password });
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.props.onLogin(this.state.username, this.state.password);
  }

  render() {
    const { error, loading, redirecting, facebookLoading, onFacebookLogin, onForgotPwd, onRegister, showRegister } = this.props;
    const { fbLoaded } = this.state;
    return (
      <div>
        <LoginTeaser />
        <LoginItems>
          <StyledDisplayOnConsent title="Login mit Facebook" vendor="facebook">
            <PrimaryButton isFacebook icon={FacebookIcon} onClick={onFacebookLogin} disabled={!fbLoaded || facebookLoading}>
              {facebookLoading ? <JumpingDots size={8} /> : "Anmelden via Facebook"}
            </PrimaryButton>
          </StyledDisplayOnConsent>
          <Headline>Oder:</Headline>
          <Form onSubmit={this.handleLogin}>
            <Input icon={UserIcon} name="username" onInput={this.handleUsernameInput} error={!!error} disabled={loading} placeholder="Benutzername" />
            <Input icon={PasswordIcon} type="password" onInput={this.handlePasswordInput} name="password" error={!!error} disabled={loading} placeholder="Passwort" />
            <Error>{error}</Error>
            <Button primary fullwidth disabled={loading} attributes={{ type: "submit" }}>
              {loading ? <JumpingDots size={8} /> : "Anmelden"}
            </Button>
            {redirecting && <RedirectingMessage>Du wirst weitergeleitet...</RedirectingMessage>}
          </Form>
          <Button fullwidth onClick={onForgotPwd}>Passwort vergessen?</Button>
          {showRegister && [
            <Headline key="headline">Noch keinen Account?</Headline>,
            <Button key="button" outline black fullwidth onClick={onRegister}>Jetzt kostenlos registrieren</Button>,
          ]}
        </LoginItems>
      </div>
    );
  }
}

LoginForm.propTypes = {
  error: PropTypes.string,
  facebookLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onFacebookLogin: PropTypes.func.isRequired,
  onForgotPwd: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onRegister: PropTypes.func,
  redirecting: PropTypes.bool,
  showRegister: PropTypes.bool,
};

LoginForm.defaultProps = {
  error: null,
  facebookLoading: false,
  loading: false,
  onRegister: () => {},
  redirecting: false,
  showRegister: false,
};

export default LoginForm;
