/* global FB_APP_ID */
import scriptLoader from "../helper/scriptLoader";

const initProps = {
  appId: FB_APP_ID,
  version: "v4.0",
};

let loadPromise;

export default function loadFacebookSDK() {
  const id = "facebook-sdk";

  loadPromise = loadPromise || new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve(window.FB);
      return;
    }

    window.fbAsyncInit = () => {
      window.FB.init(initProps);
      resolve(window.FB);
    };

    scriptLoader("https://connect.facebook.net/de_DE/sdk.js").catch(() => {
      loadPromise = undefined;
      reject();
    });
  });

  return loadPromise;
}
