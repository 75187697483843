import PropTypes from "prop-types";
import React from "react";
import { TextareaAutoHeight, Textarea as TextareaModule } from "./styles/Textarea.styles";

const Textarea = ({ autoheight, ...props }) => (
  autoheight ?
    <TextareaAutoHeight {...props} />
    :
    <TextareaModule {...props} />
);

Textarea.propTypes = {
  autoheight: PropTypes.bool,
};

Textarea.defaultProps = {
  autoheight: false,
};

export default Textarea;
