import { showedModal } from "../modal/actions/modalActionCreators";
import { LOGIN } from "../modal/modalNames";
import { configureStore } from "../store/applicationStore";
import { trackEvent } from "../tracking/tracking";

export const isLoggedInState = state => state.session.get("type") !== "AnonymousUser";

export const isLoggedIn = () => isLoggedInState(configureStore().getState());

export const showLogin = () => {
  configureStore().dispatch(showedModal(LOGIN, { width: 400 }));
  trackEvent("Sign-Up", "Open Login Overlay");
};

const checkForCredential = credential =>
  ` ${configureStore().getState().session.get("credentials") || ""} `.includes(` ${credential} `);

export const isAdminUser = () => checkForCredential("admin_user");

export const isCommunityManagerUser = () => checkForCredential("community_manager");

export const isCriticUser = () => checkForCredential("critic_user");

if (typeof window === "object" && window.Application && window.Application.Components) {
  window.Application.Components.Session = window.Application.Components.Session || {};
  window.Application.Components.Session.openOverlay = showLogin;
}
