import axios from "axios";

import { toCamelCase } from "../../helper/objectKeyConverter";
import { receivedSearchResults } from "./searchActionCreators";
import { toUrlParams } from "../../helper/urlHelpers";

const apiUrl = (searchString) => {
  const params = {
    q: searchString,
    type: "suggest",
    v: "2",
    with_promoted_item: true,
  };
  return `/api/search${toUrlParams(params)}`;
};

const { ...CancelToken } = axios.CancelToken;
let source = null;

export const callSearchAPI = (initiator, searchString) => (
  (dispatch) => {
    if (searchString !== "") {
      source = CancelToken.source();
      axios.get(apiUrl(searchString), { cancelToken: source.token })
        .then(({ data }) => dispatch(receivedSearchResults(initiator, data.map(object => toCamelCase(object)) || [])))
        .catch((err) => {
          if (!axios.isCancel(err)) {
            throw err;
          }
        });
    } else {
      if (source) source.cancel("empty string");
      dispatch(receivedSearchResults(initiator, []));
    }
  }
);
