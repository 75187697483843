import axios from "axios";
import compact from "lodash/compact";

export const userProfileUrl = permalink =>
  `/users/${permalink}`;

export const toUrlParams = (params) => {
  params = Object.entries(params).filter(([, value]) => (value != null));
  if (!params.length) return "";

  return `?${params.map(([key, value]) => {
    key = encodeURIComponent(key);
    if (Array.isArray(value)) {
      return value.map(v => `${key}[]=${encodeURIComponent(v)}`).join("&");
    }
    return `${key}=${encodeURIComponent(value)}`;
  }).join("&")}`;
};

export const itemApiUrl = (type, ...paths) => {
  const url = ["/api"];
  type = type.toLowerCase();
  switch (type) {
  case "series":
    url.push("series");
    break;
  case "person":
    url.push("people");
    break;
  default:
    url.push(`${type}s`);
  }
  return compact(url.concat(paths)).join("/");
};

const protectedParams = ["showroom", "targeting"];

export const protectedUrlParams = () => {
  const params = {};
  if (typeof window !== "undefined") {
    window.location.search.substring(1).split("&").forEach((param) => {
      const [key, value] = param.split("=");
      if (protectedParams.indexOf(key) >= 0) {
        params[key] = decodeURIComponent(value);
      }
    });
  }
  return params;
};

export const initAxios = () => {
  axios.defaults.withCredentials = true;
  if (typeof window !== "undefined") {
    axios.defaults.baseURL = `https://${window.location.hostname}`;
  }
};

export const externalLinkAttrs = {
  target: "_blank",
  rel: "noopener noreferrer nofollow",
};

export const externalLinkAttrsFollow = {
  target: "_blank",
  rel: "noopener noreferrer",
};
