import { Map } from "immutable";
import {
  CREATED_MUTING, DELETED_MUTING, RECEIVED_MUTING, FETCHED_MUTING,
} from "../constants/mutingConstants";

const initialState = Map();

const mutingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case CREATED_MUTING:
    return state.setIn([action.mutableType, action.mutableId], undefined);

  case RECEIVED_MUTING:
    return typeof action.muting.id !== "undefined" ? state.setIn([action.mutableType, action.mutableId], action.muting.id) : state;

  case DELETED_MUTING:
    return state.deleteIn([action.mutableType, action.mutableId]);

  case FETCHED_MUTING:
    return state.setIn(["fetched", action.mutableType, action.mutableId], Date.now());

  default:
    return state;
  }
};

export default mutingReducer;
