import preloadCinemaCurrentStore from "../cinema/current/store/cinemaCurrentStore";
import preloadCinemaUpcomingStore from "../cinema/upcoming/store/cinemaUpcomingStore";
import preloadMovieCinemaShowtimesStore from "../cinema/movie/store/movieCinemaShowtimesStore";
import preloadCinemaShowtimesStore from "../cinema/showtimes/store/cinemaShowtimesStore";
import preloadDvdStore from "../dvd/store/dvdStore";
import preloadmovieCinemaConsumptionStore from "../cinema/consumption/store/movieCinemaConsumptionStore";
import preloadTriviasStore from "../trivias/store/triviasStore";

const loadInitialState = () => {
  if (typeof window === "undefined") return {};
  const store = {};
  preloadCinemaCurrentStore(store);
  preloadCinemaUpcomingStore(store);
  preloadMovieCinemaShowtimesStore(store);
  preloadCinemaShowtimesStore(store);
  preloadmovieCinemaConsumptionStore(store);
  preloadDvdStore(store);
  preloadTriviasStore(store);
  return store;
};

export default loadInitialState;
