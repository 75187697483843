import React from "react";
import PropTypes from "prop-types";
import { hero } from "../styles/colors";

const getHeight = width => Math.round(width * (50 / 268));

const Logo = ({ movieColor, pilotColor, width, height = getHeight(width), ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268 50" width={width} height={height} {...props}>
    <path fill={movieColor} d="M66.8 36.4a.8.8 0 0 1-.2-.6V14.2c0-.2 0-.4.2-.6.1-.2.4-.2.6-.2H70c.4 0 .8.2 1 .6L77.4 26 83.9 14c.1-.4.5-.6 1-.6h2.6a.8.8 0 0 1 .8.8v21.6c0 .3 0 .5-.2.6-.2.2-.4.2-.6.2h-2.9a.8.8 0 0 1-.8-.8V21.6l-4.6 8.7c-.1.5-.6.8-1 .8h-1.4c-.5 0-1-.3-1.2-.8L71 21.6v14.2c0 .2 0 .4-.2.6-.1.2-.4.2-.6.2h-2.8c-.2 0-.4 0-.6-.2zm29.6-1.7c-1.7-1.5-2.6-3.8-2.7-6.8V22.2c.1-3 1-5.2 2.8-6.8s4-2.3 7-2.4c2.9 0 5.2.8 7 2.4a9 9 0 0 1 2.7 6.8v5.6c-.1 3-1 5.3-2.7 6.8-1.7 1.6-4 2.4-7 2.4s-5.4-.8-7.1-2.3zm10.6-3c.8-.8 1.3-2.2 1.4-4v-5.4c0-1.8-.6-3.2-1.5-4-.9-1-2-1.4-3.5-1.4-1.4 0-2.6.5-3.5 1.4-.8.8-1.3 2.2-1.4 4v5.4c0 1.8.5 3.2 1.4 4s2 1.4 3.5 1.4 2.7-.4 3.6-1.3zm16.3 4l-7-21.3-.1-.3a.7.7 0 0 1 .7-.7h3c.2 0 .5 0 .6.2l.4.5 5.6 17 5.5-17 .4-.5.7-.2h3c.1 0 .3 0 .4.2.2.1.2.3.2.5v.3l-7.1 21.3c-.2.6-.7 1-1.3 1h-3.7c-.6 0-1.1-.4-1.3-1zm18.2.7a.8.8 0 0 1-.3-.6V14.2c0-.2 0-.5.3-.6.1-.2.3-.2.5-.2h3.2a.8.8 0 0 1 .8.8v21.6c0 .2 0 .4-.2.6-.2.2-.4.2-.6.2H142c-.2 0-.4 0-.5-.2zm11 0a.8.8 0 0 1-.3-.6V14.2c0-.2 0-.4.2-.6.1-.2.4-.2.6-.2h14.6a.8.8 0 0 1 .8.8v2.2c0 .3 0 .5-.2.6-.2.2-.4.2-.6.2h-10.9v5.9H167a.8.8 0 0 1 .8.8v2c0 .3 0 .5-.2.7l-.6.2h-10.2v6H168c.2 0 .4 0 .6.2l.2.6v2.3c0 .2 0 .4-.2.5l-.6.3h-15a.8.8 0 0 1-.5-.3z" />
    <path fill={pilotColor} d="M174 36.4a.8.8 0 0 1-.2-.6V14.2c0-.2 0-.4.2-.6.2-.2.4-.2.6-.2h9c2.6 0 4.7.6 6.2 1.9 1.5 1.2 2.3 3 2.3 5.5 0 2.4-.8 4.2-2.3 5.4a10 10 0 0 1-6.3 1.8h-4.9v7.8c0 .3 0 .5-.2.6-.2.2-.4.2-.6.2h-3.2c-.2 0-.4 0-.5-.2zm9.4-12c1.3 0 2.2-.4 3-1s1-1.5 1-2.7c0-1.1-.4-2-1-2.6s-1.7-1-3-1h-4.8v7.2h4.8zm13.6 12a.8.8 0 0 1-.3-.6V14.2c0-.2 0-.5.3-.6.1-.2.3-.2.5-.2h3.2a.8.8 0 0 1 .8.8v21.6c0 .2 0 .4-.2.6-.2.2-.4.2-.6.2h-3.2c-.2 0-.4 0-.5-.2zm10.9 0a.8.8 0 0 1-.3-.6V14.2c0-.2.1-.5.3-.6.1-.2.3-.2.6-.2h3a.8.8 0 0 1 .9.8v18.4h10.5l.6.3.2.5v2.4c0 .2 0 .4-.2.6-.2.2-.4.2-.6.2h-14.4c-.3 0-.5 0-.6-.2zm21.6-1.7c-1.7-1.5-2.5-3.8-2.6-6.8V22.2c0-3 1-5.2 2.7-6.8 1.7-1.5 4-2.3 7-2.3 2.9 0 5.2.8 7 2.3s2.6 3.9 2.7 6.8V27.9c-.1 3-1 5.2-2.7 6.8a10 10 0 0 1-7 2.3c-3 0-5.4-.8-7-2.3zm10.6-3c.9-.8 1.3-2.2 1.4-4v-5.4c0-1.8-.6-3.2-1.4-4-.9-1-2-1.4-3.5-1.4s-2.6.5-3.5 1.4c-.9.8-1.4 2.2-1.4 4v5.4c0 1.8.5 3.2 1.4 4 .8 1 2 1.4 3.5 1.4s2.6-.4 3.5-1.3zm16.5 4.7a.8.8 0 0 1-.3-.6V17.5h-6c-.3 0-.5 0-.6-.2a.8.8 0 0 1-.3-.6v-2.5l.2-.6c.2-.2.4-.2.6-.2h17a.8.8 0 0 1 .8.8v2.5a.8.8 0 0 1-.8.8H261v18.3l-.3.6c-.1.2-.3.2-.5.2H257c-.2 0-.4 0-.5-.2zM40.4.8a9.3 9.3 0 0 0-3.7-.8c-2 0-4 .7-5.4 2l-7.6 7.2c-.3.3-.6.4-1 .5-.6 0-1-.6-1.4-1l-1.7-1.4-2.5-2.2-2.3-2c-.8-.9-1.6-1.7-2.7-2.3a8.8 8.8 0 0 0-7.9.2A7.7 7.7 0 0 0 0 7.9v34C0 46.2 4.2 50 9 50h3.8a3 3 0 0 0 2.9-3V24h8.1c2.7 0 3.8.5 5 1.5a6.2 6.2 0 0 1-.3 9.5c-1.2 1-2.9 1.4-6 1.3-.8 0-1.8 0-2.6.8-.9.9-.9 1.4-.9 2.4v7.3c0 .8.4 1.7 1 2.2.5.6 1.2.9 2 .9H37c2.3 0 4.5-.9 6.1-2.5a7.7 7.7 0 0 0 2.3-5.4V8C45.4 4.7 43.4 2 40.4.7zm-5.7 29.1a10 10 0 0 0-10.9-9.6H15c-1.8 0-3 1.3-3 3.1v22.8h-3c-2.6 0-5.1-2.1-5.1-4.3v-34a4 4 0 0 1 2.1-3.5c1.7-.9 3.7-.8 5.2.3l8 7.2c1 1 2.3 1.5 3.7 1.5 1.2 0 2.4-.5 3.5-1.5l7.5-7 .1-.1a4 4 0 0 1 2.7-1c.8 0 1.6.1 2.3.4 1.4.6 2.8 2 2.8 3.8v34c0 1.1-.4 2.1-1.2 2.9a5 5 0 0 1-3.5 1.3H22.8v-6c3.7.2 6.2-.6 8-2a9.5 9.5 0 0 0 3.9-8.3z" />
  </svg>
);

Logo.propTypes = {
  movieColor: PropTypes.string,
  pilotColor: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Logo.defaultProps = {
  movieColor: "#fff",
  pilotColor: hero,
  width: 268,
  height: 50,
};

export default Logo;
