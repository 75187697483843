import { Map, fromJS } from "immutable";
import {
  FETCHING_ITEMS, FETCHED_ITEMS, RECEIVED_ITEMS, RECEIVED_MORE_ITEMS, UPDATED_FILTERS, REPLACED_FILTERS,
} from "../constants/cinemaShowtimesConstants";

const initialState = Map();

const cinemaShowtimesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case UPDATED_FILTERS:
    return state.mergeIn(["filters"], fromJS(action.filters || {}));

  case REPLACED_FILTERS:
    return state.set("filters", fromJS(action.filters || {}));

  case FETCHING_ITEMS:
    return state.set("fetching", true);

  case FETCHED_ITEMS:
    return state.set("fetching", false);

  case RECEIVED_ITEMS:
    return state.set("items", action.items)
      .set("nextPage", action.nextPage);

  case RECEIVED_MORE_ITEMS:
    return state.update("items", [], items => items.concat(action.items))
      .set("nextPage", action.nextPage);

  default:
    return state;
  }
};

export default cinemaShowtimesReducer;
