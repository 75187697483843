import { SHOWED_MODAL, HID_MODAL } from "../constants/modalConstants";

export const showedModal = (modalType, modalProps) => ({
  type: SHOWED_MODAL,
  modalType,
  modalProps,
});

export const hidModal = () => ({
  type: HID_MODAL,
});
