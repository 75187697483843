import { SHOWED_MODAL, HID_MODAL } from "../constants/modalConstants";

const initialState = {
  modalType: null,
  modalProps: {},
};

const modalReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case SHOWED_MODAL:
    return { modalType: action.modalType, modalProps: action.modalProps || {} };

  case HID_MODAL:
    return initialState;

  default:
    return state;
  }
};

export default modalReducer;
