import styled from "styled-components";
import { oswaldFont } from "../../styles/typography";

export const Wrapper = styled.div`
  padding: 0 24px;
`;

export const Header = styled.div`
  ${oswaldFont({ weight: 600, spacing: 0.04 })}
  margin-bottom: 24px;
  font-size: 19px;
  line-height: 28px;
  text-transform: uppercase;
`;
