import React from "react";
import { Provider } from "react-redux";

import { configureStore } from "../store/applicationStore";

const withProvider = Component =>
  props => (
    <Provider store={configureStore()}>
      <Component {...props} />
    </Provider>
  );

export default withProvider;
