import React from "react";
import styled, { css } from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { error, lightGray } from "../../styles/colors";
import { notoSerifFont } from "../../styles/typography";

const styles = css`
  ${notoSerifFont}
  display: block;
  width: 100%;
  padding: 12px;
  transition: .2s;
  transition-property: border-color, box-shadow;
  border: 1px solid ${lightGray};
  background: #fff;
  box-shadow: inset 2px 2px 1px 0 rgba(${props => (
    props.error ? "196, 72, 72" : "194, 194, 194"
  )}, .27);
  font-size: 15px;
  line-height: 24px;
  resize: none;

  ${props => props.error && css`
    border-color: ${error};
  `}
`;

export const Textarea = styled.textarea`${styles}`;

export const TextareaAutoHeight = styled(({ error: _err, ...props }) => <TextareaAutosize {...props} />)`${styles}`;
