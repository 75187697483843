import { Map, fromJS } from "immutable";

import { keyIn } from "../../helper/immutableHelper";
import { RECEIVED_FLAGS, CREATED_FLAG, DELETED_FLAG, CLEARED_FLAGS_USER_DATA } from "../constants/flagsConstants";

const flagsReducer = (state = Map(), action = {}) => {
  switch (action.type) {
  case RECEIVED_FLAGS: {
    let nextState = state;
    action.flags.forEach((flag) => {
      nextState = nextState.setIn([flag.flaggableType, flag.flaggableId], fromJS(flag).filterNot(keyIn("flaggableType", "flaggableId")));
    });
    return nextState;
  }

  case CREATED_FLAG:
    return state.setIn([action.flaggableType, action.flaggableId], fromJS(action.flag).filterNot(keyIn("flaggableType", "flaggableId")));

  case DELETED_FLAG: {
    if (action.flag && action.flag.isFlagged) {
      return state.setIn([action.flaggableType, action.flaggableId], fromJS(action.flag).filterNot(keyIn("id")));
    }
    return state.deleteIn([action.flaggableType, action.flaggableId]);
  }

  case CLEARED_FLAGS_USER_DATA: {
    let nextState = state;
    nextState.forEach((ids, type) => {
      ids.forEach((flag, id) => {
        nextState = nextState.setIn([type, id], flag.filterNot(keyIn("id")));
      });
    });
    return nextState;
  }

  default:
    return state;
  }
};

export default flagsReducer;
