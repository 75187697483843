/* global API_HOST RECAPTCHA_SITE_KEY */

import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import omit from "lodash/omit";
import pick from "lodash/pick";
import get from "lodash/get";
import qs from "qs";
import ReCaptcha from "react-google-recaptcha";
import PasswordIcon from "../icons/PasswordIcon";
import UserIcon from "../icons/UserIcon";
import JumpingDots from "../loader/JumpingDots";
import { trackEvent } from "../tracking/tracking";
import { Button, Checkbox, CheckboxLink, Error, Header, Input, Wrapper, CaptchaWrapper } from "./styles/SignupForm.styles";
import EmailIcon from "../icons/EmailIcon";

const apiUrl = `${API_HOST}/moviepilot-de/v1/users`;

class SignupForm extends React.Component {
  state = {
    loading: false,
    errors: {},
    username: "",
    password: "",
    email: "",
    age_verification: false,
    terms_of_service_and_privacy: false,
    wants_newsletter: false,
  }

  getError = name => (this.state.errors[name] && this.state.errors[name][0]) || null;

  formData = () => {
    const user = Object.assign(
      { registration_domain: "moviepilot.de", "g-recaptcha-response": this.state.captcha },
      pick(this.state, ["username", "password", "email", "age_verification", "terms_of_service_and_privacy", "wants_newsletter"]),
    );

    ["age_verification", "terms_of_service_and_privacy", "wants_newsletter"].forEach((option) => {
      user[option] = (user[option] ? 1 : 0);
    });

    return { user };
  }

  handleInputChange = (value, name) => {
    this.setState(({ errors }) => (
      { [name]: value, errors: omit(errors, name) }
    ));
  }

  handleSubmit = (event) => {
    event.preventDefault();
    trackEvent("Sign-Up", "Signup Submitted");
    this.setState({ loading: true, error: null });

    axios.post(
      apiUrl,
      qs.stringify(this.formData()),
      { headers: { "content-type": "application/x-www-form-urlencoded" } },
    )
      .then(() => this.props.onSuccess())
      .catch(error => this.setState({ errors: get(error, "response.data.errors", {}), loading: false }));
  }

  render() {
    const { loading, errors } = this.state;
    const { showReturn, onReturn } = this.props;
    return (
      <Wrapper>
        <Header>Jetzt kostenlos Registrieren</Header>
        <form onSubmit={this.handleSubmit}>
          <Input icon={UserIcon} name="username" onInput={this.handleInputChange} error={!!errors.username} disabled={loading} placeholder="Benutzername" />
          <Error>{this.getError("username")}</Error>

          <Input icon={PasswordIcon} name="password" type="password" onInput={this.handleInputChange} error={!!errors.password} disabled={loading} placeholder="Passwort" />
          <Error>{this.getError("password")}</Error>

          <Input icon={EmailIcon} name="email" type="email" onInput={this.handleInputChange} error={!!errors.email} disabled={loading} placeholder="E-Mail" />
          <Error>{this.getError("email")}</Error>

          <Checkbox name="age_verification" onChange={this.handleInputChange} error={!!errors.age_verification} disabled={loading}>
            Ich bin mindestens 16 Jahre alt
          </Checkbox>
          <Error>{this.getError("age_verification")}</Error>

          <Checkbox name="terms_of_service_and_privacy" onChange={this.handleInputChange} error={!!errors.terms_of_service_and_privacy} disabled={loading}>
            <span>
              Ich akzeptiere die
              {" "}
              <CheckboxLink href="/pages/terms">AGB</CheckboxLink>
              {" "}
              und
              {" "}
              <CheckboxLink href="/pages/privacy">Datenschutzbestimmungen</CheckboxLink>
            </span>
          </Checkbox>
          <Error>{this.getError("terms_of_service_and_privacy")}</Error>

          <Checkbox name="wants_newsletter" onChange={this.handleInputChange} disabled={loading}>
            Ich möchte persönliche Tipps &amp; News erhalten
          </Checkbox>

          <CaptchaWrapper>
            <ReCaptcha
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={value => this.handleInputChange(value, "captcha")}
              lang="de"
            />
          </CaptchaWrapper>
          <Error>{this.getError("captcha")}</Error>

          <Button primary fullwidth disabled={loading} attributes={{ type: "submit" }}>
            {loading ? <JumpingDots size={8} /> : "Registrieren"}
          </Button>

          {showReturn && <Button outline black fullwidth onClick={onReturn}>Zurück zum Login</Button>}
        </form>
      </Wrapper>
    );
  }
}

SignupForm.propTypes = {
  onReturn: PropTypes.func,
  onSuccess: PropTypes.func,
  showReturn: PropTypes.bool,
};
SignupForm.defaultProps = {
  onReturn: () => { },
  onSuccess: () => { },
  showReturn: false,
};

export default SignupForm;
