import snakeCase from "lodash/snakeCase";
import camelCase from "lodash/camelCase";
import mapKeys from "lodash/mapKeys";

export const toUnderscore = (obj = {}) =>
  mapKeys(obj, (_, key) => snakeCase(key));

export const toCamelCase = (obj = {}) =>
  mapKeys(obj, (_, key) => camelCase(key));

export const toDataParams = params =>
  mapKeys(params, (value, key) => `data-${key}`);
