import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import round from "lodash/round";

import CommentIcon from "../icons/CommentIcon";
import MovieIcon from "../icons/MovieIcon";
import SeriesIcon from "../icons/SeriesIcon";
import CinemaIcon from "../icons/CinemaIcon";
import UserlistIcon from "../icons/UserlistIcon";

import { getHeight } from "./assetsHelper";
import ResponsiveImage from "./ResponsiveImage";
import RetinaImage from "./RetinaImage";
import styles from "./styles/ImageWithFallback.sass";

const cx = classNames.bind(styles);

const ImageWithFallback = ({ className, aspectRatio, type, ...props }) => {
  const icons = {
    Movie: MovieIcon,
    Series: SeriesIcon,
    Cinema: CinemaIcon,
    ForumPost: CommentIcon,
    List: UserlistIcon,
  };

  let placeholderIcon;
  if (Object.keys(icons).indexOf(type) > -1) {
    const Icon = icons[type];
    placeholderIcon = <Icon className={cx("image", "isPlaceholder", `is${type}`)} color="#c2c2c2" />;
  }

  let content;
  if (props.id) {
    if (props.widths) {
      content = <ResponsiveImage aspectRatio={aspectRatio} className={cx("image")} placeholder={placeholderIcon} {...props} />;
    } else {
      content = <RetinaImage height={getHeight(props.width, aspectRatio)} className={cx("image")} placeholder={placeholderIcon} {...props} />;
    }
  } else {
    content = placeholderIcon;
  }

  return (
    <div className={cx("wrapper", className)} style={{ paddingBottom: `${round(100 / aspectRatio, 3)}%` }}>
      {content}
    </div>
  );
};

ImageWithFallback.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  widths: PropTypes.array,
  aspectRatio: PropTypes.number,
  type: PropTypes.string,
};

ImageWithFallback.defaultProps = {
  id: null,
  className: undefined,
  width: undefined,
  widths: null,
  aspectRatio: 1,
  type: "Movie",
};

export default ImageWithFallback;
