export default [
  [
    ["Filme", "/filme", { action: "Movies" }],
    ["Beste Filme", "/filme/beste", { action: "Movies", label: "All Movies" }],
    ["Filmtipps", "/filme/vorhersage", { action: "Movies", label: "Tips" }],
    ["Filme bewerten", "/quickratings", { action: "Movies", label: "Quickrating" }],
    ["Top Trailer", "/trailer-videos", { action: "Movies", label: "Trailers" }],
    ["Listen", "/liste", { action: "Movies", label: "Lists" }],
    ["Top Stars", "/people", { action: "Movies", label: "Stars" }],
  ],
  [
    ["Serien", "/serie", { action: "Series" }],
    ["Beste Serien", "/serien/beste", { action: "Series", label: "All Series" }],
    ["Serientipps", "/serien/vorhersage", { action: "Series", label: "Tips" }],
    ["Listen", "/liste", { action: "Series", label: "Lists" }],
    ["Top Stars", "/people", { action: "Series", label: "Stars" }],
  ],
  [
    ["News", "/news", { action: "News" }],
    ["Film News", "/thema/kino", { action: "News", label: "Movie News" }],
    ["Serien News", "/thema/serie", { action: "News", label: "Series News" }],
    ["Streaming News", "/thema/vod", { action: "News", label: "Streaming News" }],
    ["Community Blogs", "/blogs", { action: "News", label: "Blogs" }],
  ],
  [
    ["Podcast", "/podcast", { action: "Podcast" }],
  ],
  [
    ["Kino", "/kino/kinoprogramm", { action: "Cinema" }],
    ["Neu", "/kino/kinoprogramm/kinostarts", { action: "Cinema", label: "New Releases" }],
    ["Demnächst", "/kino/kinoprogramm/demnaechst-im-kino", { action: "Cinema", label: "Upcoming" }],
    ["Tipps", "/kino/kinoprogramm?order=forecast", { action: "Cinema", label: "Tips" }],
    ["Lieblingskinos", "/kino/kinoprogramm/lieblingskinos", { action: "Cinema", label: "Favorite Cinemas" }],
  ],
  [
    ["Zu Hause", "/streaming/neuerscheinungen", { action: "At Home" }],
    ["Streaming", "/streaming/neuerscheinungen", { action: "At Home", label: "Streaming" }],
    ["DVD", "/dvd/dvds-neu", { action: "At Home", label: "DVD Blu-ray" }],
    ["DVD Tipps", "/dvd/tipps", { action: "At Home", label: "DVD Blu-ray Tips" }],
    ["DVD Vorschau", "/dvd/demnaechst-auf-dvd", { action: "At Home", label: "DVD Blu-ray Upcoming" }],
    ["TV", "/tv/fernsehprogramm", { action: "At Home", label: "TV" }],
    ["Gewinnspiele", "/thema/gewinnspiel", { action: "At Home", label: "Contests" }],
  ],
];
