
import React from "react";
import { Header, Wrapper } from "./styles/RecoverPasswordSuccess.styles";

const RecoverPasswordSuccess = () => (
  <Wrapper>
    <Header>Neues Passwort</Header>
    Danke, wir haben dir zur Bestätigung eine E-Mail geschickt. Bitte klicke auf den Link in der E-Mail, um ein neues Passwort zu erhalten.
  </Wrapper>
);

export default RecoverPasswordSuccess;
