import { load } from "hypernova";
import { Map } from "immutable";

import actionCreators from "../actions/cinemaShowtimesActionCreators";
import cinemaReducer from "../reducers/cinemaShowtimesReducer";

const { receivedItems, replacedFilters } = actionCreators;

const preloadCinemaShowtimesStore = (store = {}) => {
  const instances = load("CinemaShowtimesModule") || [];

  if (!instances.length) return store;
  const { items, nextPage, filters } = instances.pop().data || {};

  let cinemaShowtimesStore = Map();
  cinemaShowtimesStore = cinemaReducer(cinemaShowtimesStore, replacedFilters(filters));
  cinemaShowtimesStore = cinemaReducer(cinemaShowtimesStore, receivedItems(items, nextPage));

  if (typeof store.cinema !== "object") store.cinema = {};
  store.cinema.showtimes = (store.cinema.showtimes || Map()).mergeDeep(cinemaShowtimesStore);
  return store;
};

export default preloadCinemaShowtimesStore;
