import { SET_ACTIVE_TOOLTIP, DELETED_ACTIVE_TOOLTIP } from "../constants/tooltipConstants";

const initialState = {
  activeId: null,
};

const tooltipReducer = (state = initialState, action = {}) => {
  switch (action.type) {
  case SET_ACTIVE_TOOLTIP:
    return { activeId: action.activeId || initialState.activeId };

  case DELETED_ACTIVE_TOOLTIP:
    return initialState;

  default:
    return state;
  }
};

export default tooltipReducer;
