import PropTypes from "prop-types";
import React from "react";
import { darkGray, lightGray } from "../styles/colors";
import { ClearButton, StyledClearIcon, IconWrapper, Input as StyledInput, Wrapper } from "./styles/Input.styles";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.textInput = null;
  }

  setTextInputRef = (element) => {
    this.textInput = element;
  }

  handleClear = (e) => {
    e.preventDefault();
    const { name } = e.target;
    const value = "";
    this.setState({ value });
    this.props.onInput(value, name);
  }

  handleInput = (e) => {
    const { value, name } = e.target;
    this.setState({ value });
    this.props.onInput(value, name);
  }

  render() {
    const { hasClearButton, value, className, icon, onInput, ...props } = this.props;
    const Icon = icon;
    return (
      <Wrapper className={className}>
        {Icon &&
          <IconWrapper>
            <Icon size={20} color={darkGray} />
          </IconWrapper>
        }
        <StyledInput
          type="text"
          value={this.state.value}
          hasClearButton={hasClearButton}
          hasIcon={!!Icon}
          onChange={this.handleInput}
          innerRef={this.setTextInputRef}
          {...props}
        />
        {hasClearButton &&
          <ClearButton type="button" onTouchStart={this.handleClear} onMouseDown={this.handleClear}>
            <StyledClearIcon color={lightGray} size={26} />
          </ClearButton>
        }
      </Wrapper>
    );
  }
}

Input.propTypes = {
  hasClearButton: PropTypes.bool,
  value: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.any,
  onInput: PropTypes.func,
};

Input.defaultProps = {
  hasClearButton: false,
  value: "",
  className: undefined,
  icon: null,
  onInput: () => {},
};

export default Input;
