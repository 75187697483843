const loadScript = url =>
  new Promise((resolve, reject) => {
    try {
      let didRun = false;
      const tags = document.getElementsByTagName("script")[0] || document.head.lastElementChild;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.async = true;

      script.onload = () => {
        if (!didRun && (!script.readyState || script.readyState === "complete")) {
          didRun = true;
          resolve(script);
        }
      };
      script.onreadystatechange = script.onload;

      script.onerror = reject;
      script.onabort = reject;

      tags.parentNode.insertBefore(script, tags);
    } catch (e) {
      reject(e);
    }
  });

export default loadScript;
